import React, {Component} from "react";
import axios from "axios";
import Logo from "../../assets/icons/svg_icons/logo_web.svg";
import {G_API_LEARN_URL, G_API_URL, G_F_API_URL, G_URL} from "../../constants/constants";
import {__getEmail, __getToken, __getUID, __getUserName} from "../../utils/user-details.util";
import queryString from "query-string";
import {__getCookie} from "../../utils/cookie.util";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import {Button, Form, Input, Modal, Spin} from "antd";

const slugify = require('slugify');

class Checkout extends Component {
    constructor(props) {
        super(props);
        // create a ref to store the textInput DOM element
        this.btn = React.createRef();
    }

    state = {
        checked: false,
        validatingCoupon: false,
        payment_amount: 0,
        amount: 0,
        discount: 0,
        couponValidTxt: '',
        couponClass: 'invalid-coupon',
        pleasewait: false,
        coursePrice: 0,
        aff_id: "",
        coupon: "",
        slug: "",
        visible: false,
        clicked_on: Math.floor(Date.now() / 1000),
        autoModalOpen:true
    };

    componentDidMount() {
        if (this.props.courseName !== undefined && this.props.coursePrice !== undefined && this.props.courseName.trim() !== '' && this.props.coursePrice !== '') {
            if (this.props.calledBy === "individual" || this.props.calledBy === "subscription")
                this.setState({slug: slugify(this.props.courseName)})
            else if (this.props.courseID === 1337 || this.props.courseID === 1347 || localStorage.getItem("fromPage") === "live-placement-training") {
                this.setState({slug: window.location.pathname.split("/")[1]});

            } else
                this.setState({slug: window.location.pathname.split("/")[2]});
        }
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
        let aff_id_api_params = {};
        const boot_camp_page = localStorage.getItem("fromPage");
        if (boot_camp_page === "tcs" || boot_camp_page === "live-placement-training") {
            aff_id_api_params = {
                params: {
                    uid: __getUID(),
                    slug: this.props.slug
                }
            };
        } else {
            aff_id_api_params = {
                params: {
                    uid: __getUID(),
                    slug: this.state.slug
                }
            };
        }
        axios.get(G_API_URL + 'affiliates/', aff_id_api_params)
            .then((res) => {
                if (res.data.status === 1) {
                    this.setState({
                        aff_id: res.data.aff_id,
                        clicked_on: res.data.clicked_on
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    confirmPurchase = e => {
        e.preventDefault();
        this.fetchCourseFee();
    };

    handleClose = () => {
        this.setState({
                visible: false,
                coupon: "",
                couponValidTxt: "",
                validatingCoupon: false,
                pleasewait: false
            }
        );
    };

    handleCoupon = e => {
        this.setState({coupon: e.target.value.toUpperCase()});
    };

    fetchCourseFee = e => {
        this.setState({pleasewait: true , autoModalOpen : false});
        const auth_token = this.props.cookie;
        axios
            .post(G_API_LEARN_URL + "subscription/course-subscription/", {
                mode: 'SUBSCRIPTION_FEE',
                course_id: this.props.courseID
            }, {
                headers: {
                    Authorization: auth_token
                }
            })
            .then(res => {
                this.setState({visible: true, pleasewait: false});
                if (res.data !== undefined) {
                    let course_fee = res.data.course_fee;
                    if (course_fee !== undefined && !isNaN(course_fee))
                        this.setState({coursePrice: course_fee, payment_amount: course_fee});
                    else {
                        course_fee = parseInt(this.props.coursePrice.toString().replace(/,/g, ''));

                        if (this.props.courseID === 1337 || this.props.courseID === 1347 || this.props.calledBy === "Hero" || localStorage.getItem("fromPage") === "live-placement-training")
                            this.setState({coursePrice: course_fee, payment_amount: course_fee});
                        else
                            this.setState({
                                coursePrice: course_fee,
                                payment_amount: course_fee + (0.18 * this.props.coursePrice)
                            });
                    }
                } else {
                    let course_fee = parseInt(this.props.coursePrice.toString().replace(/,/g, ''));

                    if (this.props.courseID === 1337 || this.props.courseID === 1347 || this.props.calledBy === "Hero" || localStorage.getItem("fromPage") === "live-placement-training")
                        this.setState({coursePrice: course_fee, payment_amount: course_fee});
                    else
                        this.setState({
                            coursePrice: course_fee,
                            payment_amount: course_fee + (0.18 * this.props.coursePrice)
                        });
                }
            });
    };

    validateCoupon = e => {
        e.preventDefault();
        this.setState({validatingCoupon: true});
        const auth_token = this.props.cookie;
        if (this.state.coupon.trim() !== '') {
            axios
                .post(G_API_LEARN_URL + "subscription/course-subscription/", {
                    mode: 'VALIDATE_COUPON',
                    coupon: this.state.coupon,
                    course_id: this.props.courseID
                }, {
                    headers: {
                        Authorization: auth_token
                    }
                })
                .then(res => {
                    if (res.data.status === 1) {
                        this.setState(
                            {
                                couponValidTxt: "Applied Successfully!",
                                couponClass: "valid-coupon",
                                payment_amount: res.data.final_price,
                                discount: res.data.discount,
                                validatingCoupon: false
                            }
                        )
                    } else {
                        this.setState(
                            {
                                coupon: "",
                                couponClass: "invalid-coupon",
                                payment_amount: this.state.coursePrice,
                                discount: 0,
                                couponValidTxt: "Sorry, this coupon code is not applicable to this course!",
                                validatingCoupon: false
                            }
                        )
                    }
                }).catch((err) => {
                this.setState(
                    {
                        coupon: "",
                        payment_amount: this.state.coursePrice,
                        discount: 0,
                        couponClass: "invalid-coupon",
                        couponValidTxt: "Sorry, this coupon code is not applicable to this course!",
                        validatingCoupon: false
                    }
                )
            });
        } else {
            this.setState(
                {
                    payment_amount: this.state.coursePrice,
                    discount: 0,
                    couponValidTxt: "",
                    validatingCoupon: false
                }
            )
        }
    }

    paymentHandler = e => {
        e.preventDefault();
        const {slug} = this.state;
        const {courseID, international_status} = this.props;
        this.setState({pleasewait: !this.state.pleasewait, visible: false});

        const boot_camp_page = localStorage.getItem("fromPage");

        let formData = new FormData();
        if (boot_camp_page === "tcs" || boot_camp_page === "live-placement-training") {
            const urlParams = new URLSearchParams(window.location.search);
            const aff_id = urlParams.get('aff_id');
            if (aff_id !== null && aff_id !== undefined && aff_id !== "")
                formData.append('affid', aff_id);
            else if (this.state.aff_id !== "" && (Math.floor(Date.now() / 1000) - this.state.clicked_on) <= 1209600)
                formData.append('affid', this.state.aff_id);
        } else {
            formData.append('affid', this.state.aff_id);
        }
        formData.append("course_id", courseID);
        formData.append("mode", "SUBSCRIBE_COURSE_RP");
        if (this.props.international_status) formData.append("country", "US");
        formData.append("access_mode", "web");
        if (this.state.discount !== 0)
            formData.append("coupon_code", this.state.coupon);

        const auth_token = this.props.cookie;

        // Course Names
        const cName = this.props.courseName;

        axios
            .post(G_API_LEARN_URL + "subscription/course-subscription/", formData, {
                headers: {
                    Authorization: auth_token
                }
            })
            .then(res => {
                if (res.data.status !== 0) {
                    const {order_id, razorpay_api_key, razorpay_order_id, final_price} = res.data;
                    const {mobile, name, email} = res.data.user !== undefined ? res.data.user : {};
                    const {course_name} = res.data.course !== undefined ? res.data.course : {};

                    // Send Payment Initiation Event to GA
                    ReactGA.event({
                        category: 'ProGrad',
                        action: 'payment_initiated',
                        label: window.location.pathname + window.location.search,
                        value: final_price * 1
                    })

                    let options = {
                        order_id: razorpay_order_id,
                        key: razorpay_api_key,
                        name: "FACE Prep",
                        description: course_name,
                        image: Logo,
                        handler: function (response) {
                            const data = {
                                order_id: order_id,
                                paymentID: response.razorpay_payment_id,
                                signature: response.razorpay_signature,
                                orderID: response.razorpay_order_id,
                                final_price: final_price,
                                mode: "RP",
                                uid: "",
                                international_status: international_status
                            };
                            var element = document.getElementById("main");
                            element.parentNode.removeChild(element);

                            var p = document.getElementById("root");
                            var newElement = document.createElement("div");
                            newElement.setAttribute("id", "payment-process");
                            newElement.setAttribute("class", "f-d f-h-c");
                            newElement.innerHTML = `<h1>Please Wait while your transaction is being processed</h1>`;

                            p.appendChild(newElement);
                            axios
                                .post(G_API_LEARN_URL + "subscription/response/", data, {
                                    headers: {
                                        Authorization: auth_token
                                    }
                                })
                                .then(res => {
                                    if (res.data.status === 2) {

                                        // Send Payment Success Event to GA
                                        ReactGA.event({
                                            category: 'Course',
                                            action: 'payment_success',
                                            label: window.location.pathname + window.location.search,
                                            value: final_price * 1
                                        });

                                        // Send Payment Success Event to Pixel
                                        ReactPixel.track('Purchase', {
                                            currency: "INR",
                                            value: parseInt(final_price * 1),
                                            content_category: "Course",
                                            content_type: cName,
                                        });

                                        if ((boot_camp_page === "bootcamp") || (boot_camp_page === "live-placement-training")){
                                            const course_slug = localStorage.getItem("slug");
                                            const batch_id = localStorage.getItem(
                                                "active_batch_id"
                                            );
                                            if (course_slug && batch_id) {
                                                let values = {
                                                    username: __getUserName(),
                                                    prefix: "91",
                                                    mobile_no: "NULL",
                                                    email: __getEmail(),
                                                    college: boot_camp_page === "bootcamp" ? "PROGRAD-BOOTCAMP" :"PROGRAD-LPT",
                                                    degree: "NULL",
                                                    yop: "NULL",
                                                    gender: "NULL",
                                                    location: "NULL",
                                                    review_status: 1,
                                                    specs: "Yes",
                                                    net_speed: "Yes",
                                                    profile1: null,
                                                    profile2: null,
                                                    profile3: null,
                                                    resume: null,
                                                    course: course_slug,
                                                    batch_id: batch_id,
                                                    course_id: courseID,
                                                    uid: __getUID(),
                                                    source: __getCookie("source").cookieValue
                                                };
                                                let api_prograd = G_API_URL + "prograd/register/";
                                                const config = {
                                                    headers: {
                                                        "Content-Type":
                                                            "application/x-www-form-urlencoded",
                                                        Authorization: `Bearer ${__getToken()}`
                                                    }
                                                };
                                                axios.post(
                                                    api_prograd,
                                                    queryString.stringify(values),
                                                    config
                                                ).then((res) => {
                                                    if (res.data.status === 1) {
                                                        window.location = "/prograd/payment/success/" + slug + "/";
                                                    }
                                                });
                                            } else {
                                                window.location = "/prograd/payment/success/" + slug + "/";
                                            }
                                        } else if (boot_camp_page === "tcs") {
                                            let fetch_api = G_F_API_URL + "auth/mock-test/";
                                            let data = {
                                                email: __getEmail(),
                                                TCS_group: 14
                                            };
                                            //Fetch freshgrad token
                                            axios
                                                .post(fetch_api, data)
                                                .then((res) => {
                                                    //Check if token is generated successfully from freshgrad
                                                    if (res.data.status === 1) {
                                                        console.log('Purchase successful, added to learn sub-domain');
                                                        window.location = "/prograd/payment/success/" + slug + "/";
                                                    }
                                                })
                                                .catch((err) => {
                                                    console.error("Fetch freshgrad token err: ", err);
                                                });
                                        } else {
                                            window.location = "/prograd/payment/success/" + slug + "/";
                                        }
                                    }
                                });
                        },
                        prefill: {
                            name: name,
                            email: email,
                            contact: mobile
                        },
                        notes: {
                            address: "Hello World"
                        },
                        theme: {
                            color: "#f05136"
                        }
                    };

                    if (this.state.payment_amount >= 1) {
                        let rzp = new window.Razorpay(options);
                        rzp.open();
                        this.setState({pleasewait: !this.state.pleasewait});
                    } else if (res.data.status === 2 && this.state.payment_amount <= 0) {
                        if ((boot_camp_page === "bootcamp")|| (boot_camp_page === "live-placement-training")) {
                            const course_slug = localStorage.getItem("slug");
                            const batch_id = localStorage.getItem(
                                "active_batch_id"
                            );
                            if (course_slug && batch_id) {
                                let values = {
                                    username: __getUserName(),
                                    prefix: "91",
                                    mobile_no: "NULL",
                                    email: __getEmail(),
                                    college: boot_camp_page === "bootcamp" ? "PROGRAD-BOOTCAMP" : "PROGRAD-LPT",
                                    degree: "NULL",
                                    yop: "NULL",
                                    gender: "NULL",
                                    location: "NULL",
                                    review_status: 1,
                                    specs: "Yes",
                                    net_speed: "Yes",
                                    profile1: null,
                                    profile2: null,
                                    profile3: null,
                                    resume: null,
                                    course: course_slug,
                                    batch_id: batch_id,
                                    course_id: this.props.courseID,
                                    uid: __getUID(),
                                    source: __getCookie("source").cookieValue
                                };
                                let api_prograd = G_API_URL + "prograd/register/";
                                const config = {
                                    headers: {
                                        "Content-Type":
                                            "application/x-www-form-urlencoded",
                                        Authorization: `Bearer ${__getToken()}`
                                    }
                                };
                                axios.post(
                                    api_prograd,
                                    queryString.stringify(values),
                                    config
                                ).then((res) => {
                                    if (res.data.status === 1) {
                                        window.location = "/prograd/payment/success/" + slug + "/";
                                    }
                                });
                            } else {
                                window.location = "/prograd/payment/success/" + slug + "/";
                            }
                        } else if (boot_camp_page === "tcs") {
                            let fetch_api = G_F_API_URL + "auth/mock-test/";
                            let data = {
                                email: __getEmail(),
                                TCS_group: 14
                            };
                            //Fetch freshgrad token
                            axios
                                .post(fetch_api, data)
                                .then((res) => {
                                    //Check if token is generated successfully from freshgrad
                                    if (res.data.status === 1) {
                                        console.log('Purchase successful, added to learn sub-domain');
                                        window.location = "/prograd/payment/success/" + slug + "/";
                                    }
                                })
                                .catch((err) => {
                                    console.error("Fetch freshgrad token err: ", err);
                                });
                        } else {
                            window.location = "/prograd/payment/success/" + slug + "/";
                        }
                    }
                }
            });
    };

    render() {
        let style = {
            border: "none"
        };

        let classes = "apply-now";
        let pay_text = "Pay Now";

        if (this.props.calledBy === "Hero") {
            style = {
                width: "200px",
                height: "50px",
                border: "none",
            };
        }

        if (this.props.calledBy === "ProHero") {
            classes = "enroll-btn";
            pay_text = "ENROLL NOW";
        } else if ((this.props.calledBy === "subscription") && (this.props.courseExpired.includes(parseInt(this.props.courseID)))) {
            pay_text = "Extend";
        }else if (this.props.calledBy === "subscription") {
            pay_text = "Subscribe";
        } else if (this.props.calledBy === "individual") {
            pay_text = "Subscribe";
            style = {
                width: "150px",
                height: "40px",
                border: "none"
            }
        }

        return (
            <>
                {(this.props.checkoutModalOpen  && this.state.autoModalOpen) &&
                    (
                        this.fetchCourseFee()
                    )
                }

                {(this.props.courseName !== undefined && this.state.coursePrice !== undefined && this.props.courseName.trim() !== '' && this.state.coursePrice !== '') ?
                    (
                        <Button className={classes} type="primary" ref={this.btn} style={style}
                                onClick={this.confirmPurchase} loading={this.state.pleasewait}>
                            {this.state.pleasewait ? <>Please Wait...</> : <>{pay_text}</>}{" "}
                        </Button>
                    )
                    :
                    (
                        <Button className={classes} type="primary" ref={this.btn} style={style}
                                onClick={this.paymentHandler} loading={this.state.pleasewait}>
                            {this.state.pleasewait ? <>Please Wait...</> : <>{pay_text}</>}{" "}
                        </Button>
                    )}

                <Modal
                    className="confirm-payment-modal"
                    footer={null}
                    centered
                    destroyOnClose={true}
                    visible={this.state.visible}
                    title='Confirm Purchase'
                    onCancel={() => {
                        this.handleClose();
                    }}
                >
                    <Spin spinning={this.state.validatingCoupon}>
                        <div className="confirm-modal-body">
                            <Form.Item>
                                <div className="course-details">
                                    <span className='name'>
                                        {this.props.courseName}
                                    </span>
                                    <span className='price'>
                                        {!this.props.international_status ? "INR" : "USD"} {this.state.coursePrice}
                                    </span>
                                </div>
                            </Form.Item>

                            <Form.Item
                                label="Enter coupon code if any"
                            >
                                <div className="apply-coupon">
                                    <Input className='coupon-input' value={this.state.coupon} placeholder="Coupon Code"
                                           onChange={this.handleCoupon}/>
                                    <Button className='apply-btn' type="text"
                                            onClick={this.validateCoupon}>APPLY</Button>
                                </div>
                                <span className={this.state.couponClass}>{this.state.couponValidTxt}</span>
                            </Form.Item>
                            <Form.Item>
                                <div className='discount-details'>
                                    <span className='discount'>Coupon Discount</span>
                                    {
                                        this.state.discount === 0 ?
                                            <span className='price'>-</span>
                                            :
                                            <span
                                                className='price'>{!this.props.international_status ? "INR" : "USD"} {this.state.discount} ({parseInt(this.state.discount * 100 / (parseInt(this.state.coursePrice.toString().replace(/,/g, ''))))}%)</span>
                                    }
                                </div>
                            </Form.Item>
                            <Form.Item>
                                <div className='payable-amount'>
                                    <span className='total'>Total Payable</span>
                                    <span
                                        className="price">{!this.props.international_status ? "INR" : "USD"} {this.state.payment_amount}</span>
                                </div>
                            </Form.Item>
                            <Form.Item>

                            </Form.Item>
                            <div className='pay-amount'>
                                <Button className='pay-amount-btn' type="primary" onClick={this.paymentHandler}>Pay{" "}
                                    {!this.props.international_status ? "INR" : "USD"} {this.state.payment_amount}</Button>
                            </div>
                            <div className='extra-desc'>You will be redirected to the payment page to complete the
                                payment. By clicking 'Pay', You agree to these <a
                                    href={G_URL + 'info/terms-and-conditions'}>Terms and Conditions</a>
                            </div>
                        </div>
                    </Spin>
                </Modal>
                <style jsx={"true"}>
                    {`
                    .ant-modal-mask{
                       z-index: 1011;
                    }
                    .ant-modal-wrap{
                       z-index: 1012
                    }
                    .confirm-payment-modal .ant-modal-title{
                        font-size:24px;
                        text-align: left;
                        margin: 1rem;
                    }
                     .confirm-payment-modal .ant-modal-close{
                      margin: 1rem;
                     }
                    .confirm-payment-modal .confirm-modal-body{
                        padding: 30px;
                        display: grid;
                    }
                    .course-details,.discount-details,.payable-amount{
                        display: flex;
                        font-size: 16px;
                    }
                    .course-details .name,.payable-amount .total,.discount-details .discount{
                        width: 70%;
                        font-weight: 300;
                    }
                    .course-details .price,.payable-amount .price,.discount-details .price{
                        width: 30%;
                        text-align: end;
                    }
                    .apply-coupon,.pay-amount{
                        display:flex;
                        height: 50px;
                    }
                    .apply-coupon .coupon-input{
                        height: 50px;
                    }
                    .apply-coupon .apply-btn{
                        height: 50px;
                        border: none;
                        color: var(--facered);
                        font-size; 18px;
                    }
                    .pay-amount-btn{
                        height: 50px;
                        border: none;
                        font-size; 18px;
                        width: 100%;
                        margin-bottom: 10px;
                    }
                    .valid-coupon{
                        font-size: 16px;
                        font-weight: 300;
                        color: var(--go) 
                    }
                    .invalid-coupon{
                        font-size: 16px;
                        font-weight: 300;
                        color: var(--facered) 
                    }
                    .extra-desc{
                        margin-top:10px;
                        text-align: center;
                    }                           
                    `}
                </style>
            </>
        );
    }
}

export default Checkout;
