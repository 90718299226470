import React, { useState } from "react";
import { __getToken } from "../../utils/user-details.util";
import Checkout from "../../pages/RazorPay/Checkout";
import { Button } from "antd";
import { check_login } from "../../utils/login.util";
import { initiateTrial } from "../../utils/Premium/premium_login";
import { G_HOME_URL } from "../../constants/constants";

const Module = (props) => {
    const {
        idx,
        name,
        description,
        image,
        price,
        subscribedCourses,
        onSubscribe,
        packId,
        is_available,
        courseId,
        handleTrailBtn,
        isTrialAvailable,
        mVerified,
        btnText,
    } = props;

    const [MobileVerified, setMobileVerified] = useState(0);
    const [CheckoutModalOpen, setCheckoutModalOpen] = useState(false);

    let pay_click_handler = () => {
        handleTrailBtn(name);
        setMobileVerified(1);
    }

    if ((mVerified) &&(MobileVerified === 1) && (isTrialAvailable)) {
        initiateTrial(courseId);
    }

    if ((mVerified) &&(MobileVerified === 1) && (!isTrialAvailable) && (!CheckoutModalOpen)){
        setCheckoutModalOpen(true);
    }

    let pay_click_jsx = (
        <Button type="primary" onClick={pay_click_handler}>
            Subscribe
        </Button>
    );

    if (mVerified) {
        pay_click_jsx = (
            <Checkout
                courseID={parseInt(courseId)}
                courseName={name}
                coursePrice={price}
                calledBy="individual"
                cookie={__getToken()}
            />
        );
    }
    
    const isLogIn = check_login();

    const renderModule = () => {
        if(isLogIn){
            if(subscribedCourses.includes(parseInt(courseId))){
                return (
                    <>
                        <div className="f-d f-vt">
                            <span className="module-inr h4-heading">
                                INR {price}
                            </span>
                            <span className="module-description">
                                {description}
                            </span>
                        </div>
                        <Button
                            className="subscribed-btn"
                            type="primary"
                            disabled={true}
                        >
                            Subscribed
                        </Button>
                    </>
                )
            }
            else if((is_available === 1) &&  (!isTrialAvailable) && (!subscribedCourses.includes(parseInt(courseId)))){
                return (
                    <>
                        <div className="f-d f-vt">
                            <span className="module-inr h4-heading">
                                INR {price}
                            </span>
                            <span className="module-description">
                                {description}
                            </span>
                        </div>
                        {CheckoutModalOpen ? 
                            <>
                                <Checkout
                                    courseID={parseInt(courseId)}
                                    courseName={name}
                                    coursePrice={price}
                                    calledBy="individual"
                                    checkoutModalOpen = {CheckoutModalOpen}
                                    cookie={__getToken()}
                                />
                            </> : <> {pay_click_jsx} </>
                        }
                    </>
                )
            }
            else if((is_available === 1) && (isTrialAvailable)){
                return (
                    <>
                        <div className="f-d f-vt">
                            <span className="module-inr h4-heading">
                                INR {price}
                            </span>
                            <span className="module-description">
                                {description}
                            </span>
                        </div>
                        <Button
                            className="try-it not-logged-in"
                            type="primary"
                            onClick={pay_click_handler }
                        >
                            {btnText}
                        </Button>
                    </>
                )
            }
            else{
                return(
                    <span className="body-regular coming-soon-btn">
                        Coming Soon!
                    </span>
                )
            }
        }
        else if(is_available === 1){
            return(
                <Button
                    className="apply-now not-signed-in"
                    type="primary"
                    onClick={() => {
                        window.location.href = G_HOME_URL + "login/?rurl=premium";
                    }}
                >
                    Try for free
                </Button>
            )
        }
        else{
            return (
                <span className="body-regular coming-soon-btn">Coming Soon!</span>
            )
        }
    }

    
    
    
    const style = {
        backgroundImage: `url(${image})`,
    };
    return (
        <>
            <div
                className={`g-d subscription-module-wrapper${
                    idx === 1 ? " g-d-s" : idx === 2 ? " g-h-c" : " g-h-e"
                }`}
            >
                <div className="subscription-module">
                    <div className="g-d g-v-e module-title-wrapper" style={style}>
                        <span className="topic">{name}</span>
                    </div>
                    <div className="module-content">
                        <div className="f-d f-ht price-container">
                            {renderModule()}
                        </div>
                    </div>
                </div>
            </div>
            <style jsx={"true"}>{`
                .module-content .subscribed-btn {
                    color: white;
                    width: 150px;
                    height: 40px;
                    border: none;
                    opacity: 0.7;
                }
                .module-content .coming-soon-btn {
                    color: var(--facered);
                }
                .price-container {
                    justify-content: space-between;
                    margin-top: 20px;
                }
                .subscription-module-wrapper {
                    width: 100%;
                }

                .subscription-module {
                    min-height: 40vh;
                    width: 90%;
                    margin: 0 0 8%;
                    border: 1px solid var(--snowfall);
                    background: var(--dove);
                }

                .subscription-module-wrapper .module-title-wrapper {
                    height: 10rem;
                    padding: 1rem;
                }

                .subscription-module-wrapper .module-title-wrapper .topic {
                    color: var(--dove);
                    font-weight: 450;
                    font-size: 16px;
                }

                .subscription-module-wrapper .module-content {
                    padding: 1rem;
                }

                .subscription-module-wrapper .module-description {
                    display: block;
                }

                .subscription-module-wrapper .module-inr {
                    display: block;
                    font-color: var(--batman);
                }

                .subscription-module-wrapper .buy-now {
                    color: var(--dove);
                    background-color: var(--facered);
                    padding: 0.5rem 0;
                    width: 50%;
                    font-size: 12px;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                }

                .subscription-module-wrapper .please-wait-btn {
                    opacity: 0.8;
                    padding: 0.4rem 0;
                }

                .subscription-module-wrapper .view-details-btn {
                    border: none;
                    background-color: transparent;
                    // width:50%;
                    color: var(--facered);
                    cursor: pointer;
                    text-align: end;
                }
                .subscription-module-wrapper .not-signed-in {
                    color: white;
                    width: 150px;
                    height: 40px;
                    border: none;
                }
            `}</style>
        </>
    );
};

export default Module;
