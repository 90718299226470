import { __getCookie } from "./cookie.util";
import keys from "../config/keys";
import { G_CF_URL } from "../constants/constants";

const __getDP = () => {
    const cookie = __getCookie(keys.cookiePrefix + "upic");
    if (cookie !== undefined && cookie.cookieValue !== null && cookie.cookieValue !== undefined) {
        const img_url = decodeURIComponent(cookie.cookieValue);
        return img_url.includes(G_CF_URL) ? img_url : G_CF_URL + "users/picture/" + img_url;
    }
    return G_CF_URL + "placeholder_profile_pic.png";
};

const __getUserName = () => {
    const cookie = __getCookie(keys.cookiePrefix + "user_name");
    if (cookie !== undefined && cookie.cookieValue !== null && cookie.cookieValue !== undefined) {
        return cookie.cookieValue.replace(/\+/g, " ");
    }
    return "";
};

const __getEmail = () => {
    const cookie = __getCookie(keys.cookiePrefix + "email");
    if (cookie !== undefined && cookie.cookieValue !== null && cookie.cookieValue !== undefined) {
        return decodeURIComponent(cookie.cookieValue);
    }
    return "";
};

const __getToken = () => {
    const cookie = __getCookie(keys.cookiePrefix + "ut");
    if (cookie !== undefined && cookie.cookieValue !== null && cookie.cookieValue !== undefined) {
        return cookie.cookieValue;
    }
    return "";
};

const __getUID = () => {
    const cookie = __getCookie(keys.cookiePrefix + "uid");
    if (cookie !== undefined && cookie.cookieValue !== null && cookie.cookieValue !== undefined) {
        return cookie.cookieValue;
    }
    return "0";
};

const __getFreshgradToken = () => {
    const cookie = __getCookie(keys.cookiePrefix + "fg_ut");
    if (cookie !== undefined && cookie.cookieValue !== null && cookie.cookieValue !== undefined) {
        return cookie.cookieValue;
    }
    return undefined;
};

export { __getDP, __getUserName, __getEmail, __getToken, __getUID, __getFreshgradToken };
