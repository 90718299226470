import React, { Component } from "react";
import CourseHero from "../../components/Course/CourseHero";
import CourseOverview from "../../components/Course/CourseOverView";
import CourseLearnContent from "../../components/Course/CourseLearnContent";
import Layout from "../../components/Layout";
import { G_API_URL } from "../../constants/constants";
import { check_login } from "../../utils/login.util";
import { getOrderStatus, getProgramContent } from "./CourseUtils";
import CourseFeatures from "../../components/Course/CourseFeatures";
import Faq from "../../components/Course/Faq";
import { __getToken, __getUID } from "../../utils/user-details.util";
import Checkout from "../RazorPay/Checkout";
import { message } from "antd";
import {
    CourseFeaturesSkelton,
    CourseHeroSkelton,
    CourseOverviewSkelton,
    CourseLearnSkelton,
} from "../../components/Course/CourseSkelton";

class CourseDetail extends Component {
    state = {
        data: {},
        active_batch_id: null,
        slug: null,
        pageloaded: false,
        courseIds: [],
        courseId: "",
        paymentStatus: false, // Payment Status if true => Course Purchased, default => false
        course_slug: "",
        isLogin: false,
    };

    getInitialContent = async (api, landing_slug) => {
        let fetch_order_status_api = G_API_URL + "orders/";
        let program_data = await getProgramContent(api, landing_slug);
        if (program_data && program_data !== -1) {
            let { active_batch_id, course_slug } = program_data;

            localStorage.setItem("slug", landing_slug);
            localStorage.setItem("active_batch_id", active_batch_id);
            localStorage.setItem("fromPage", "live-placement-training");

            let courseIds = [];
            if (program_data.paymentIds) {
                courseIds = program_data.paymentIds;
            }
            let courseId = courseIds[0];

            if (check_login()) {
                const uid = __getUID();
                const cookie = __getToken();

                let order_status = await getOrderStatus(fetch_order_status_api, uid, courseIds);
                let paymentStatus = false;
                if (order_status && order_status["data"] && order_status["data"]["data"]) {
                    let data_length = order_status["data"]["data"].length;

                    if (data_length !== 0) paymentStatus = true;
                    if (data_length === 0) paymentStatus = false;
                }

                this.setState({
                    isLogin: true,
                    data: program_data,
                    active_batch_id,
                    slug: landing_slug,
                    course_slug,
                    courseIds,
                    paymentStatus,
                    courseId,
                    cookie,
                    pageloaded: true,
                });
            } else {
                this.setState({
                    isLogin: false,
                    data: program_data,
                    active_batch_id,
                    slug: landing_slug,
                    pageloaded: true,
                });
            }
        }
    };

    componentDidMount = async () => {
        let fetch_program_data_api = G_API_URL + "lpt/";
        let landing_slug = this.props.match.params.course;
        await this.getInitialContent(fetch_program_data_api, landing_slug);
    };

    /*

         ******** @@@@ PaymentHandler function() @@@@ ********
        variables: api => cookies, courseID => Payment fee depends on it, called by
        Functionalities: =>
                        Processs <Checkout> component in Razorpay Checkout.jsx
        Used in =>
                        Passed as a prop to <Hero> component

    */

    PaymentHandler = (calledBy, international_status) => {
        const { cookie, courseId } = this.state;

        if (courseId !== "") {
            let courseid = parseInt(courseId);
            return (
                <Checkout
                    courseID={courseid}
                    courseName={this.state.data.landing_name}
                    coursePrice={
                        this.state.data.program_content.heroContent.heroImpDetails.details_2.data.split(
                            "_"
                        )[1]
                    }
                    cookie={cookie}
                    calledBy={calledBy}
                    international_status={international_status}
                    slug={this.props.slug}
                />
            );
        } else return message.error("Invalid Course Id");
    };

    RedirectToLogin = () => {
        const redirectUrl =
            "/login/?rurl=" + this.props.location.pathname + this.props.location.search;
        window.location.href = redirectUrl;
    };

    render() {
        const { pageloaded, data, isLogin, paymentStatus, active_batch_id } = this.state;

        let displayEnrollProps = {
            isLogin,
            RedirectToLogin: this.RedirectToLogin,
            paymentStatus,
            PaymentHandler: this.PaymentHandler,
            internationalUser: false,
            active_batch_id: active_batch_id,
        };

        return (
            <div id={"main"}>
                <Layout>
                    {pageloaded ? (
                        <CourseHero
                            heroContent={data["program_content"]["heroContent"]}
                            testimonialsContent={data["program_content"]["testimonialsContent"]}
                            {...displayEnrollProps}
                        />
                    ) : (
                        <CourseHeroSkelton />
                    )}

                    {pageloaded ? (
                        <CourseOverview
                            overviewContent={data["program_content"]["overviewContent"]}
                            overviewCarousel={
                                data["program_content"]["overviewCarousel"]
                                    ? data["program_content"]["overviewCarousel"]
                                    : {}
                            }
                        />
                    ) : (
                        <CourseOverviewSkelton />
                    )}

                    {pageloaded ? (
                        <CourseFeatures content={data["program_content"]["featuresContent"]} />
                    ) : (
                        <CourseFeaturesSkelton />
                    )}

                    {pageloaded ? (
                        <CourseLearnContent
                            learnContent={data["program_content"]["learnContent"]}
                        />
                    ) : (
                        <CourseLearnSkelton />
                    )}

                    {pageloaded ? <Faq faqContent={data["program_content"]["faqContent"]} /> : null}
                </Layout>
            </div>
        );
    }
}

export default CourseDetail;
