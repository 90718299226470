import React, { useEffect } from "react";
import styled from "styled-components";
import FaceproMockIconSvg from "./../../../assets/icons/svg_icons/facepro_mock_icon.svg";
import { Spin, Tabs } from "antd";
import axios from "axios";
import TestCard from "../../Cards/TestCard";
import { G_API_URL, G_QZ_API_URL } from "../../../constants/constants";
import { __getToken } from "../../../utils/user-details.util";
import { useState } from "react";
import { find as _find } from "lodash";

const { TabPane } = Tabs;

const StyledTestsJSX = styled.div`
    .tests-header {
    }

    .tests-header .tests-left-container {
        gap: 24px;
        .icon-box {
            width: 54px;
            height: 54px;
            ::before {
                content: unset;
            }
        }

        .green-icon-box {
            width: 8px;
            height: 8px;
            background: var(--go);
            border-radius: 2px;
        }

        h2 {
            margin-bottom: unset;
        }

        p.test-desc {
            margin-bottom: unset;
            margin-left: 8px;
            color: var(--grey);
        }
    }

    .test-body-contaniner {
        margin-top: 48px;
    }

    .test-tabs {
        .ant-tabs-nav .ant-tabs-tab:hover,
        .ant-tabs-nav .ant-tabs-tab-active {
            color: var(--carbon);
        }

        .ant-tabs-ink-bar {
            background-color: var(--facered);
        }
    }

    .test-tabs-pane {
        width: 84%;
        gap: 24px;
    }

    @media only screen and (max-device-width: 1240px) {
        .test-tabs-pane {
            width: 100%;
            gap: 24px;
            grid-template-columns: repeat(2, 314px);
        }
    }

    @media only screen and (max-device-width: 768px) {
        .test-tabs-pane {
            width: 100%;
            gap: 24px;
            grid-template-columns: repeat(1, 1fr);
        }
    }
`;

const Tests = () => {
    const [tests, setTests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentTab, setCurrentTab] = useState("1");

    useEffect(() => {
        axios
            .get(`${G_API_URL}facepro/tests/`, { headers: { Authorization: __getToken() } })
            .then((res) => {
                if (res.data.data && res.data.status) {
                    setTests(res.data.data);
                    setLoading(false);
                }
            })
            .catch((err) => {});
    }, []);

    const checkForTestContentAndLoadTestData = async (subject_id) => {
        setLoading(true);
        setCurrentTab(subject_id);

        const testsData = _find(tests, { subject_id: subject_id });
        if (testsData.tests && testsData.tests.length > 0) {
            setLoading(false);
            return;
        }

        const config = {
            params: {
                start: 0,
                length: 100,
                from: "facepro",
                filter_subjects: subject_id,
            },
            headers: {
                Authorization: __getToken(),
            },
        };
        const test_response = await axios.get(`${G_QZ_API_URL}integration/fetch-mock-tests/`, config);

        if (test_response.data && test_response.data.status === 1) {
            let clone_test_state = [...tests];
            clone_test_state = clone_test_state.map((test_subjects) => {
                if (test_subjects.subject_id === subject_id) {
                    test_subjects.tests = test_response.data.instances;
                }
                return test_subjects;
            });
            setTests(clone_test_state);
        }
        setLoading(false);
    };

    const renderTestCards = () => {
        const testsData = _find(tests, { subject_id: currentTab });

        if (testsData.tests && testsData.tests.length > 0) {
            return testsData.tests.map((test) => {
                return <TestCard {...test} key={test.test_id} />;
            });
        }

        return <div className="f-d f-h-c">No Test found</div>;
    };

    const renderTestTabsPane = () => {
        return tests.map((test_subjects) => {
            return <TabPane tab={test_subjects.name} key={test_subjects.subject_id} className={``}></TabPane>;
        });
    };

    return (
        <>
            <StyledTestsJSX>
                <div className="tests-header">
                    <div className="tests-left-container f-d f-v-c">
                        <div
                            className="icon-box bg-image-full"
                            style={{ backgroundImage: "url(" + FaceproMockIconSvg + ")" }}
                        ></div>
                        <div>
                            <h2 className="h2-heading">Mock Test</h2>
                            <div className="desc f-d f-v-c">
                                <div className="green-icon-box"></div>
                                <p className="test-desc">FACE Prep Test</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Test body */}
                <div className="test-body-contaniner">
                    <Tabs defaultActiveKey="1" onChange={checkForTestContentAndLoadTestData} className="test-tabs">
                        {renderTestTabsPane()}
                    </Tabs>
                    {loading ? (
                        <div className="f-d f-h-c f-v-c">
                            <Spin />
                        </div>
                    ) : (
                        <div className={`g-d g-col-3 test-tabs-pane`}> {renderTestCards()}</div>
                    )}
                </div>
            </StyledTestsJSX>
        </>
    );
};

export default Tests;
