import * as React from "react";

function SvgCertificate(props) {
    return (
        <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M34.397 19.194l3.449-3.375c1.028-.974.548-2.703-.8-3.026l-4.7-1.199 1.326-4.651c.374-1.338-.888-2.6-2.225-2.225l-4.65 1.325-1.198-4.7c-.318-1.328-2.067-1.814-3.025-.8L19.2 4.017 15.826.542c-.947-1.001-2.704-.542-3.025.8l-1.198 4.7-4.65-1.324c-1.338-.375-2.599.887-2.225 2.225l1.325 4.651-4.699 1.2c-1.349.322-1.827 2.052-.8 3.025l3.45 3.375-3.45 3.375c-1.028.974-.548 2.703.8 3.026l4.699 1.199-1.325 4.651c-.374 1.338.888 2.6 2.225 2.225l4.65-1.325 1.198 4.7c.333 1.394 2.078 1.802 3.025.8l3.374-3.45 3.374 3.45c.938 1.012 2.7.562 3.024-.8l1.2-4.7 4.649 1.325c1.338.375 2.599-.887 2.224-2.225l-1.324-4.651 4.699-1.2c1.349-.322 1.827-2.052.8-3.025l-3.45-3.375z"
                fill={props.color}
            />
            <path
                d="M16.284 22.424L12.49 18.63l-1.292 1.283 5.085 5.085 10.915-10.916-1.282-1.282-9.633 9.623z"
                fill="#fff"
                stroke="#fff"
            />
        </svg>
    );
}

export default SvgCertificate;
