import React from "react";
import styled from "styled-components";
import FaceProTestIconSvg from "./../../assets/imgs/facepro-test-icon.svg";
import { Link } from "react-router-dom";

const StyledTestCard = styled.div`
    position: relative;
    width: 312px;
    padding: 24px 16px;
    height: 160px;
    background-color: var(--dove);
    border: var(--peaky-border);
    border-radius: 24px;

    .test-header {
        gap: 8px;
        .test-icon-box {
            width: 32px;
            height: 32px;
            .test-icon {
                width: 32px;
                height: 100%;
                background-image: url(${(props) => props.image});
            }
        }
    }

    .test-bottom {
        position: absolute;
        bottom: 24px;
        right: 8px;

        gap: 40px;

        .no-of-question {
            color: #606060;
            margin-bottom: unset;
        }

        .proceed-btn {
            background: #e94057;
            border-radius: 4px;
            height: 28px;
            padding: 0 22px;
            color: var(--dove);
        }
    }

    @media only screen and (max-device-width: 768px) {
        width: 100%;
    }
`;

const TestCard = ({ instance_id, questions_count, test_name }) => {
    return (
        <StyledTestCard image={FaceProTestIconSvg}>
            <div className="test-header f-d">
                <div className="test-icon-box">
                    <div className="test-icon bg-image-full"></div>
                </div>
                <div className="test-name">
                    <p className="body-medium-text font-nunito">{test_name}</p>
                </div>
            </div>
            <div className="test-bottom f-d f-h-sb f-v-c">
                <p className="no-of-question">Total questions: {questions_count}</p>
                <Link to={`/test-landing/${instance_id}`}>
                    <div className="proceed-btn body-small-text f-d f-v-c f-h-c c-pointer">Proceed</div>
                </Link>
            </div>
        </StyledTestCard>
    );
};

export default TestCard;
