import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import jwtReducer from "./jwt.reducer";
import skillZoneReducer from "./skillZone.reducer";
import skillZoneAssessReducer from "./skillZoneAssess.reducer";
import skillZoneCategoryStatsReducer from "./skillZoneCategoryStats.reducer";
import skillPracticeReducer from "./skillPractice.reducer";
import editorConfigReducer from "./editor.reducer";
import comprehensionReducer from "./comprehensionQuestion.reducer";
import skillPlansReducer from "./skillPlans.reducer";

const persistConfig = {
    key: "root",
    storage,
    version: 3,
    blacklist: [
        "skillZoneAssessData",
        "skillZoneCategoryStats",
        "skillPracticeData",
        "editorConfig",
        "comprehension",
        "skillPlans",
    ],
};

const appReducer = combineReducers({
    jwtData: jwtReducer,
    skillZoneData: skillZoneReducer,
    skillZoneAssessData: skillZoneAssessReducer,
    skillZoneCategoryStats: skillZoneCategoryStatsReducer,
    skillPracticeData: skillPracticeReducer,
    skillPlans: skillPlansReducer,
    editorConfig: editorConfigReducer,
    comprehension: comprehensionReducer,
});

const rootReducer = (state, action) => {
    if (action.type === "USER_LOGOUT") {
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

const allReducers = persistReducer(persistConfig, rootReducer);

export default allReducers;
