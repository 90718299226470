import { SET_COMP_QUESTION_OPTION, SET_COMP_GUESS_STATUS, RESET_COMP_DATA } from "../constants/constants";

const initialState = {
    options: {},
    guess: {},
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_COMP_QUESTION_OPTION:
            return { ...state, options: { ...state.options, [payload.question_id]: payload.option } };
        case SET_COMP_GUESS_STATUS:
            return { ...state, guess: { ...state.guess, [payload.question_id]: payload.guess } };
        case RESET_COMP_DATA:
            return initialState;
        default:
            return state;
    }
};
