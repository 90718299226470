import * as React from "react";

function SvgQuoteIcon(props) {
    return (
        <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M11.216 6.456c-1.28 0-2.24.427-2.88 1.28-.597.853-.896 2.219-.896 4.096h3.968v8.64H2v-6.848c0-3.67.704-6.357 2.112-8.064C5.562 3.853 7.931 3 11.216 3v3.456zM22.216 6.456c-1.28 0-2.24.427-2.88 1.28-.597.853-.896 2.219-.896 4.096h3.968v8.64H13v-6.848c0-3.67.704-6.357 2.112-8.064C16.562 3.853 18.931 3 22.216 3v3.456z"
                fill="#DFC884"
                className="fill"
            />
        </svg>
    );
}

export default SvgQuoteIcon;
