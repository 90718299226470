import React from "react";

const FolderIcon = props => (
  <svg width={86} height={64} {...props}>
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="Folder_svg__a">
        <stop stopColor="#753329" offset="0%" />
        <stop stopColor="#F05136" offset="100%" />
      </linearGradient>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="98.799%" id="Folder_svg__d">
        <stop stopColor="#F05136" offset="0%" />
        <stop stopColor="#B2422F" offset="52.427%" />
        <stop stopColor="#9E3D2D" offset="82.241%" />
        <stop stopColor="#9E3D2D" offset="100%" />
      </linearGradient>
      <filter
        x="-10.6%"
        y="-17.7%"
        width="121.1%"
        height="131.5%"
        filterUnits="objectBoundingBox"
        id="Folder_svg__b"
      >
        <feOffset dy={-1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={2.5}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect
        id="Folder_svg__c"
        x={0}
        y={9.13}
        width={75.652}
        height={50.87}
        rx={2.609}
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M2.609 60A2.609 2.609 0 010 57.391V2.61A2.609 2.609 0 012.609 0h19.565l5.217 5.217h45.652a2.609 2.609 0 012.61 2.61V57.39A2.609 2.609 0 0173.042 60H2.61z"
        fill="url(#Folder_svg__a)"
        transform="translate(5)"
      />
      <g transform="translate(5)">
        <use
          fill="#000"
          filter="url(#Folder_svg__b)"
          xlinkHref="#Folder_svg__c"
        />
        <use fill="url(#Folder_svg__d)" xlinkHref="#Folder_svg__c" />
      </g>
    </g>
  </svg>
);

export default FolderIcon;
