import React, { Component } from "react";
import down_icon from "../../assets/icons/svg_icons/download.svg";
import CourseLearnCard from "./CourseLearnCard";

export class CourseLearnContent extends Component {
    renderCourseLearnCard = () => {
        const content = this.props.learnContent;
        let card_list = Object.values(content.learnSprints);
        return card_list.map((card_obj, idx) => (
            <CourseLearnCard {...card_obj} key={`LEARN-CARD-${idx}`} />
        ));
    };

    render() {
        const content = this.props.learnContent;

        return (
            <>
                <div
                    id="curriculum"
                    className="learn-container pro-section lr-pad-d lr-pad-m bg-image"
                >
                    <div className="learn-top f-d f-h-sb">
                        <h2 className="h2-heading-v2">{content.header}</h2>
                        {content.curriculumDownload && (
                            <div className="down-btn-container f-d f-v-c hide-m">
                                <div
                                    className="default-icon bg-image-full"
                                    style={{ backgroundImage: "url(" + down_icon + ")" }}
                                ></div>
                                <div className="button-text down-btn">Download curriculum</div>
                            </div>
                        )}
                    </div>
                    <div className="learn-header f-d f-v-c">
                        <div className="redborder"></div>
                        <div className="learn-description body-large-text">
                            {content.description}
                        </div>
                    </div>

                    <div className="learn-path-container">{this.renderCourseLearnCard()}</div>
                </div>

                <style jsx>
                    {`
                        .redborder {
                            width: 5px;
                            height: 50px;
                            background: var(--facered);
                            margin: 2rem 0.5rem 4rem 0;
                            cursor: default;
                        }
                        .learn-container {
                            background-color: var(--bg-black);
                            padding-top: 4rem;
                            padding-bottom: 6rem;
                        }
                        .sprint-title {
                            color: var(--carbon);
                        }

                        .learn-header {
                            // width:60%;
                        }

                        .learn-container,
                        .learn-top > h2,
                        .learn-container > p {
                            color: var(--dove);
                        }

                        .learn-container .learn-description {
                            margin-top: 2rem;
                            margin-bottom: 4rem;
                            color: var(--dove);
                        }

                        .sprint-container .open-icon,
                        .sprint-dpl-main .type-icon,
                        .down-btn-container .default-icon {
                            width: 16px;
                            height: 16px;
                        }

                        .sprint-dpl-main .type-icon {
                            margin-right: 0.6rem;
                        }

                        .sprint-dpl-main > div {
                            margin-right: 4rem;
                        }

                        .learn-path-container {
                            width: 60%;
                        }

                        .sprint-container {
                            background: var(--dove);
                            color: var(--carbon);
                            margin-bottom: 4rem;
                            padding: 24px 32px;
                            border-radius: var(--peaky-br-4);
                            box-shadow: var(--peaky-shadow-high);
                            position: relative;
                            transition: all 0.4s;
                        }

                        .sprint-container.active-parent {
                            height: auto;
                        }

                        .learn-path-container .sprint-container:last-child::after {
                            display: none;
                        }

                        .learn-path-container .sprint-container:last-child {
                            margin-bottom: 0;
                        }

                        .sprint-dpl-extra {
                            display: none;
                        }

                        .sprint-dpl-extra.active {
                            display: block;
                            margin-top: 2rem;
                        }

                        .sprint-dpl-extra.active .schedule-container {
                            margin-bottom: 2rem;
                        }

                        .sprint-container .open-icon {
                            position: absolute;
                            right: 6%;
                            top: 36px;
                            transform: rotate(0deg);
                            transition: all 0.4s;
                        }

                        .sprint-container.active-parent .open-icon {
                            transform: rotate(180deg);
                        }

                        .mobile-down {
                            display: none;
                        }
                        .s-topic {
                            white-space: pre-wrap;
                        }

                        @media only screen and (max-device-width: 760px) {
                            .type-value {
                                font-size: 13px;
                            }
                            .mobile-down {
                                display: flex;
                                margin: 4rem auto 0 auto;
                                width: max-content;
                            }

                            .learn-path-container {
                                width: 100%;
                            }

                            .sprint-dpl-main > div {
                                margin-right: 2rem;
                            }
                        }

                        @media screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
                            .learn-path-container {
                                width: 70%;
                            }
                        }
                    `}
                </style>
            </>
        );
    }
}

export default CourseLearnContent;
