const { notification, message } = require("antd");

/*
    ******** @@@@ openSuccessNotification @@@@ ********
    Maintainer: RG
    Functionality:
                Green color success notification on top
*/

const openSuccessNotification = (msg, desc = "") => {
    const args = {
        message: msg,
        description: desc,
        duration: 2,
        className: "success-notification-top",
    };
    notification.open(args);
};

/*
    ******** @@@@ openFailureNotification @@@@ ********
    Maintainer: RG
    Functionality:
                Red color failed notification on top
*/

const openFailureNotification = (msg, duration = 2) => {
    const args = {
        message: msg,
        description: "",
        duration: duration,
        top: 0,
        className: "failure-notification-top",
    };
    notification.open(args);
};

const openNotification = ({type, msg, desc = "", time = 3, pos = 64, customClass = ""}) => {
    notification.destroy();
    const args = {
        message: msg,
        description: desc,
        duration: time,
        top: pos,
        className: type === "success" ? customClass + " success-notification-top" : customClass + " failure-notification-top",
    };
    notification.open(args);
};

const showMessage = (type, msg) => {
    switch (type) {
        case "success":
            return message.success(msg);
        case "error":
            return message.error(msg);
        case "warning":
            return message.warning(msg);
        case "info":
            return message.info(msg);
        default:
            return message.open(msg);
    }
}

module.exports = {
    openSuccessNotification,
    openFailureNotification,
    openNotification,
    showMessage,
};
