import React, {Component} from 'react';
import face360Dashboard from "../../assets/imgs/hero_images/hero_image_face360dashboard.png";
import {check_login } from "../../utils/login.util";
import { premium_login } from "./../../utils/Premium/premium_login";
import {Button} from "antd";

class HeroContent extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }


    componentDidMount() {
    }


    render() {
        const {isTrialAvailable,isPremiumUser,dashboardAccess} = this.props;
        const is_logged_in = check_login();
        return (
            <>
                <div className="hero-content-wrapper f-d f-ht">
                    <div className="f-d f-vt content-holder">
                        <h1 className="h1-heading title">Give that little extra
                            to your Prep</h1>
                        <span className="body-small">Get FACE Prep Premium.</span>
                        { ((!isPremiumUser && isTrialAvailable) || !is_logged_in ) ?
                            <a href="#subscribe"><Button type="primary" className="subscribe-btn">Start 7 days trial</Button></a>:
                            (
                                <div className="subscribe f-d f-v-c f-vt-m">
                                    {dashboardAccess === 1 &&
                                        (
                                            <a href="#" onClick={() => premium_login()}>
                                                <Button type="primary" className="subscribe-btn">Go to dashboard</Button>
                                            </a>
                                        )
                                    }
                                    <a href="#subscribe"><Button type="primary" className="subscribe-btn">Subscribe</Button></a>
                                </div> 
                            )
                        }
                    </div>
                    <div className="img-holder f-d">
                        <img className="hero-img" src = {face360Dashboard} alt="" />

                    </div>
                </div>

                <style jsx>
                    {`
                    .hero-content-wrapper{
                        justify-content: space-between;
                    }
                    .content-holder{
                        align-self: center;
                    }
                    .hero-content-wrapper .subscribe-btn{
                        width: 150px;
                        height: 50px;
                        margin-top: 20px;
                        margin-right: 32px;
                        border: none;
                    }
                    .hero-content-wrapper .content-holder{
                        position: relative;
                        top: 20px;
                        width: 40%
                    }
                    .hero-content-wrapper .hero-img{
                        height: auto;
                        width: 500px;
                    }
                    `}
                </style>
            </>
        );

    }
}

export default HeroContent;