import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Courses from "../../../components/FacePrepPro/dashboard/Courses";
import Sidebar from "../../../components/FacePrepPro/dashboard/Sidebar";
import Layout from "../../../components/LayoutV2";
import { check_login } from "../../../utils/login.util";
import * as Styled from "./ProDashboard.styled";
import Favorites from "../../../components/FacePrepPro/dashboard/Favorites";
import Tests from "../../../components/FacePrepPro/dashboard/Test";

const ProDashboard = () => {
    const { slug } = useParams();
    const history = useHistory();
    let jsx_component = <></>;

    switch (slug) {
        case "courses":
            jsx_component = <Courses />;
            break;
        case "tests":
            jsx_component = <Tests />;
            break;
        case "favorites":
            jsx_component = <Favorites />;
            break;
        case "resources":
            jsx_component = <></>;
        default:
            break;
    }

    useEffect(() => {
        if (check_login()) {
        } else {
            history.replace("/");
        }
    }, []);

    return (
        <>
            <Layout footer={false} from={"pro-dashboard"}>
                <Styled.ProDashboardView className="f-d">
                    <div className="pro-sidebar hide-m">
                        <Sidebar />
                    </div>
                    <div className="pro-content">{jsx_component}</div>
                </Styled.ProDashboardView>
            </Layout>
        </>
    );
};

export default ProDashboard;
