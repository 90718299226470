import { SET_JWT_DATA, SET_PROFILE_DATA, SET_QUIZR_TOKEN } from "../constants/constants";
const initialState = {
    quizr_token: {
        token: "",
        expireAt: null,
    },
    uid: 0,
    usr: "",
    email: "",
    uid: "",
    email: "",
    usr_type: "",
    college_name: "",
    yop: "",
    deg: "",
    picture: "",
};

const jwtReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_JWT_DATA:
            return { ...state, ...payload };
        case SET_QUIZR_TOKEN:
            return { ...state, ...payload };
        case SET_PROFILE_DATA:
            return { ...state, ...payload };
        default:
            return state;
    }
};

export default jwtReducer;
