import React from 'react';

const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <i className="icon icon-arrow-left"></i>
        </div>
    );
}

export default CustomPrevArrow;
