import styled from "styled-components";

const ArticleWrapper = styled.div`
    width: 100%;

    @media only screen and (max-device-width: 760px) {
        width: 100%;
    }

    .article-publish-date {
        color: var(--sandstone);
        font-size: 16px;
        margin-top: 1.6rem;
        margin-bottom: 1.6rem;
    }

    h2 {
        font-size: 28px;
    }

    h2 strong {
        font-weight: 500;
        font-size: 28px;
        font-family: "Nunito sans", sans-serif !important;
    }

    h3 {
        font-size: 24px;
    }

    h3 strong {
        font-weight: 500;
        font-family: "Nunito sans", sans-serif !important;
    }

    p {
        font-size: 20px;
        font-family: "OpenSans";
        line-height: 28px;
        font-weight: 300;
        color: var(--carbon);
    }

    ol,
    ul {
        font-size: 18px;
    }

    img {
        height: unset;
        max-width: 100%;
        padding: 0 10px;
    }

    pre {
        font-family: "OpenSans";
        display: block;
        padding: 9.5px;
        margin: 0 0 10px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #333;
        word-break: break-all;
        word-wrap: break-word;
        background-color: #f5f5f5;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .table-output {
        border-collapse: collapse;
        width: 100%;
    }

    .table-output td,
    .table-output th {
        border: 1px solid #ddd;
        padding: 8px;
    }

    .table-output tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    .table-output tr:hover {
        background-color: #ddd;
    }

    .table-output th {
        padding-top: 12px;
        padding-bottom: 12px;
        background-color: var(--facered);
        color: white;
        text-align: left;
    }
`;

const ArticleHeader = styled.div`
    .article-title {
    }

    .tags-container .ant-tag {
        font-size: 14px;
        color: var(--carbon);
        padding: 8px 14px;
        cursor: pointer;
        margin-bottom: 32px;
        background-color: #f2f2f2;
        border: none;
        font-family: "OpenSans";
    }

    @media only screen and (max-device-width: 760px) {
        .article-title {
            font-size: 32px !important;
            line-height: 40.8px;
        }
    }
`;

const SocailShareDiv = styled.div`
    display: ${(props) => (props.type === "COMPANY" ? "none" : "block")};

    .social-video-share {
        height: 36px;
        width: 36px;
    }

    .mr-8 {
        margin-right: 8px;
        margin-bottom: 8px;
    }

    .share-title {
        color: var(--granite);
        font-weight: 500;
        margin-bottom: 8px;
        font-size: 16px;
    }

    margin-bottom: 32px;
`;

const ArticleBody = styled.div`
    /* width: ${(props) => (props.type === "COMPANY" ? "100%" : "90%")}; */

    @media only screen and (max-device-width: 760px) {
        margin-left: unset;
        width: 100%;
    }
`;

const ArticleBodyContainer = styled.div`
    width: 100%;
`;

const BreadCrumbsContainer = styled.div`
    cursor: pointer;
    .body-medium-text {
        color: var(--granite) !important;
        font-family: "OpenSans";
        font-weight: 400;
    }

    .last-item {
        color: var(--carbon) !important;
        font-weight: 500;
    }

    .crumb-item {
        :hover {
            text-decoration: underline;
        }
    }
`;

const VideoListContainer = styled.div`
    .h4-heading-v2 {
        color: var(--carbon);
        margin-bottom: 32px;
    }

    margin-bottom: 32px;
`;

const CourseListContainer = styled.div`
    margin-bottom: 96px;

    .h4-heading-v2 {
        color: var(--carbon);
        margin-bottom: 32px;
    }

    .course-flexbox {
        display: flex;
        margin-top: 32px;
        margin-bottom: 64px;
        flex-wrap: wrap;
    }
`;

const MeteredShadow = styled.div`
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1));
    height: 250px;
    margin-top: -240px;
    position: relative;
    width: 100%;
    display: block;
`;

const MeteredContentLogin = styled.div`
    background-color: rgba(255, 255, 255, 1);
    display: block;
    width: 100%;

    .social-container {
        margin-top: 32px;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    .color-red {
        color: var(--facered);
    }
`;

export {
    ArticleHeader,
    ArticleWrapper,
    SocailShareDiv,
    ArticleBody,
    ArticleBodyContainer,
    BreadCrumbsContainer,
    VideoListContainer,
    CourseListContainer,
    MeteredShadow,
    MeteredContentLogin,
};
