import React from "react";
import brand_logo from "../../assets/imgs/logo_web.svg";
import FooterData from "./footerData.json";
import { message } from "antd";

const Footer = () => {
    return (
        <>
            <footer className="footer-cont lr-pad-d lr-pad-m">
                <div className="footer-top-cont hide-d hide-m">
                    <div className="footer-top-text">
                        <h2 className="font-bold font-nunito">Join our newsletter</h2>
                        <p className="font-nunito font-normal">We'll keep you updated for everything</p>
                    </div>

                    <div className="footer-search-cont">
                        <div className="footer-search">
                            <input className="footer-search-box" type="text" placeholder="Enter your email" />
                            <button
                                className="footer-btn"
                                onClick={() => {
                                    message.success("Subscribed to newsletter");
                                }}
                            >
                                Join now
                            </button>
                        </div>
                    </div>
                </div>

                <div className="footer-mid-cont f-d">
                    <div className="footer-img-cont">
                        <img src={brand_logo} className="footer-img" />
                        <h3 className="font-nunito font-normal desc">
                            FACE Prep is one of the most trusted placement-prep brands that had helped over millions of
                            students to get placed.
                        </h3>
                    </div>

                    <div className="cols-cont f-d f-h-sb">
                        {Object.values(FooterData).map((col, indx) => {
                            return <FooterCol values={col} key={"FooterCol" + indx} />;
                        })}
                    </div>
                </div>

                <div className="footer-btm-cont f-d f-h-c">
                    <h3 className="copyright-font text-c-d">
                        &copy; Focus 4D Career Education Pvt. Ltd. All rights reserved
                    </h3>
                </div>
            </footer>
            <style jsx={"true"}>
                {`
                    .footer-cont {
                        padding-top: 80px;
                        padding-bottom: 80px;
                    }

                    .footer-heading {
                        margin-bottom: 24px;
                    }

                    .footer-btm-cont {
                        display: flex;
                        flex-wrap: wrap-reverse;
                    }

                    .footer-top-cont {
                        display: flex;
                        flex-wrap: wrap;
                    }

                    .footer-mid-cont {
                        // margin-top: 7rem;
                    }

                    .footer-img-cont .desc {
                        margin-top: 2rem;
                        width: 70%;
                    }

                    .links-container a.link-anchor {
                        color: var(--carbon);
                        font-size: 18px;
                        font-weight: 200;
                        font-family: "OpenSans", sans-serif;
                        font-weight: 300;
                        padding: 8px 0;
                        opacity: 0.6;
                        transition: all 0.4s;
                        user-select: none;
                    }

                    .links-container a.link-anchor:hover {
                        color: var(--facered);
                        opacity: 1;
                    }

                    .footer-top-text {
                        flex-basis: 40%;
                    }

                    .copyright-cont {
                        flex-basis: 60%;
                    }

                    .footer-search-cont {
                        padding-top: 20px;
                        flex-basis: 60%;
                    }

                    .footer-search {
                        width: 314px;
                        position: relative;
                        margin-left: auto;
                    }

                    .footer-search-box {
                        background-color: rgba(64, 83, 145, 0.1);
                        border: 0px;
                        border-top-left-radius: 16px;
                        border-bottom-left-radius: 16px;
                        color: #405391;
                        font-family: "Nunito Sans";
                        font-size: 16px;
                        font-weight: 500;
                        width: 230px;
                        outline: none;
                        padding: 13px 20px 13px 32px;
                    }

                    ::placeholder {
                        color: #405391;
                    }

                    .footer-btn {
                        background-color: #405391;
                        border: 0px;
                        border-radius: 16px;
                        color: var(--dove);
                        font-family: "Nunito Sans";
                        font-weight: 400;
                        font-size: 12px;
                        padding: 16px 20px;
                        position: absolute;
                        outline: none;
                        left: 215px;
                    }

                    .footer-btn:hover {
                        cursor: pointer;
                    }

                    .footer-img-cont {
                        width: 40%;
                    }

                    .footer-img {
                        width: 230px;
                        height: 29px;
                    }

                    .cols-cont {
                        width: 60%;
                        gap: 4rem;
                    }

                    .footer-col {
                        width: 25%;
                    }

                    .copyright-font {
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 21px;
                    }

                    .footer-btm-cont {
                        margin-top: 70px;
                    }

                    @media screen and (max-width: 991px) {
                        .footer-img-cont {
                            text-align: center;
                        }

                        .cols-cont {
                        }

                        .footer-img-txt {
                            padding: 0;
                            width: 100%;
                            margin-bottom: 60px;
                        }
                    }

                    @media screen and (max-width: 767px) {
                        .footer-mid-cont {
                            flex-direction: column;
                        }

                        .footer-img-cont {
                            width: 100%;
                        }

                        .footer-search-box {
                            font-size: 16px;
                            font-weight: 400;
                        }

                        .footer-top-cont {
                            border-bottom: 1px solid rgba(197, 197, 197, 1);
                            padding: 0px 0px 40px 0px;
                        }

                        .col-heading {
                            font-size: 18px;
                        }

                        .cols-cont {
                            width: 100%;
                            flex-wrap: wrap;
                            gap: 0px;
                            row-gap: 2rem;
                        }

                        .footer-col {
                            width: 50%;
                        }

                        .footer-img-cont .desc {
                            width: 100%;
                            margin-bottom: 4rem;
                        }

                        .footer-search-cont {
                            flex-basis: 100%;
                            display: flex;
                            justify-content: center;
                        }

                        .footer-search {
                            margin-left: 0;
                        }

                        .footer-top-text {
                            flex-basis: 100%;
                            text-align: center;
                        }
                    }

                    @media screen and (max-width: 400px) {
                    }
                `}
            </style>
        </>
    );
};

const FooterCol = (props) => {
    const { title, list } = props.values;
    return (
        <div className="footer-col">
            <h2 className="font-nunito font-bold footer-heading">{title}</h2>
            <div className="links-container f-d f-vt">
                {list.map((element, idx) => {
                    return (
                        <a
                            href={element.url}
                            className="link-anchor font-nunito font-normal"
                            key={`footer-headings-${idx}`}
                        >
                            {element.title}
                        </a>
                    );
                })}
            </div>
        </div>
    );
};

export default Footer;
