const { __getEmail, __getToken, __getUID, __getDP } = require("../user-details.util");
const { G_F360_URL, G_FC_API_URL, G_URL } = require("../../constants/constants");
const axios = require("axios");
const { __getCookie } = require("../cookie.util");

/*
        ******** @@@@ premium_login @@@@ ********
        Maintainer: RG
        Functionalities:
                1. Dynamically generate form element
                2. Submit the dynamically generate form
                3. Used for SSO Login from faceprep to premium.faceprep.in
*/

function premium_login(method = "post") {
    let path = G_F360_URL + "f360-auth/";

    const form = document.createElement("form");
    form.method = method;
    form.action = path;

    let ocid = null;
    if(__getCookie("fp_ocid")['cookieExists']) ocid = __getCookie("fp_ocid")['cookieValue']

    let params = {
        email: __getEmail(),
        fp_token: __getToken(),
        target: "/dashboard/student/face360/",
        fp_upic: __getDP(),
    };

    if(ocid) params = {...params, ocid: ocid}

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement("input");
            hiddenField.type = "hidden";
            hiddenField.name = key;
            hiddenField.value = params[key];

            form.appendChild(hiddenField);
        }
    }

    document.body.appendChild(form);
    form.submit();
}

/*
        ******** @@@@ initate trial for premium user @@@@ ********
        Maintainer: RG
        Functionalities:
                1. hit the trial api
                2. call the premium login function
              
*/

function initiateTrial(courseId = 1355) {
    axios
        .post(G_FC_API_URL + "subscription/init/trial/", {
            course_id: courseId,
            email: __getEmail(),
            uid: __getUID(),
        })
        .then((res) => {
            const { status } = res.data;
            if (status) premium_login();
        });
}

/*
        ******** @@@@ Redirect to premium page @@@@ ********
        Maintainer: RG
        Functionalities:
                1. Rediredt user to premium page
              
*/

function redirectToPage() {
    window.location.href = G_URL + "premium";
}

function checkPremiumDashboard() {
    const check_cookie = __getCookie("premium_dashboard");
    if (check_cookie["cookieExists"] && check_cookie["cookieValue"] === "true") return true;
    return false;
}

export { premium_login, initiateTrial, redirectToPage, checkPremiumDashboard };
