import axios from "axios";
import keys from "../../config/keys";
import { __getToken } from "../../utils/user-details.util";
import { __getCookie, __deleteCookie } from "../../utils/cookie.util";
import { G_API_URL } from "../../constants/constants";
import { openNotification } from "../../utils/notifications.util";
import { getSearchParam, getTimestamp } from "../../utils/common.util";
import queryString from "query-string";

const config = {
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${__getToken()}`,
    },
};

const startTrail = (emailConsent) => {
    const refID = getSearchParam("ref");
    const data = {
        rid: refID,
        emailConsent: emailConsent !== undefined ? emailConsent : true,
    }

    // Check for source
    let szsCookie = __getCookie(keys.cookiePrefix + "szs").cookieValue;
    if (szsCookie) {
        let szt = __getCookie(keys.cookiePrefix + "szt").cookieValue;
        data["source"] = szsCookie;
        data["recordedAt"] = szt;
        data["mode"] = "Trial";
    } else {
        data["source"] = "Organic";
        data["recordedAt"] = getTimestamp();
        data["mode"] = "Trial";
    }
    
    axios
        .post(G_API_URL + "payment/trail", queryString.stringify(data), config)
        .then((response) => {
            const { status } = response.data;
            if (status === 1) {
                openNotification({
                    type: "success",
                    msg: "Success",
                    customClass: "no-desc",
                });
                // Remove source cookie
                __deleteCookie(keys.cookiePrefix + "szs");
                __deleteCookie(keys.cookiePrefix + "szt");
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }
        })
        .catch((err) => {
            console.log(err);
        });
};

export { startTrail };
