import styled from "styled-components";

const PassportContainer = styled.div`
    margin-top: 4rem;

    .page {
        background-color: var(--dove);
    }

    .page-number-footer {
        padding-top: 2rem;
    }

    div.page:nth-child(even) {
        border-radius: 20px 0px 0px 20px;
        box-shadow: inset -8px 0px 24px rgba(0, 0, 0, 0.1);
    }

    div.page:nth-child(odd) {
        border-radius: 0px 20px 20px 0px;
        box-shadow: inset 6px 0px 18px rgba(0, 0, 0, 0.08);
    }

    .page-0 {
        background-color: #ddf6fd;
    }

    .page-1 {
        background-color: var(--carbon);
        font-family: "OpenSans", sans-serif;
        font-weight: 400;

        .page-content {
            position: relative;
            height: 640px;
        }

        .title {
            color: var(--dove);
            font-size: 40px;
            margin-top: 4rem;
        }
        .desc {
            color: rgba(255, 255, 255, 0.54);
            font-size: 14px;
            line-height: 25px;
        }

        .pass-logo-box {
            position: absolute;
            top: 40%;
            left: 0;
            right: 0;
        }

        .pass-logo {
            width: 180px;
            height: 180px;
            border-radius: 50%;
            background-color: var(--dove);
            box-shadow: inset 4px -4px 12px rgba(0, 0, 0, 0.3);

            .face-img {
                width: 60px;
                height: 60px;
            }
        }

        .footer {
            color: var(--dove);
            position: absolute;
            bottom: 16px;
            left: 0px;
            right: 0px;
        }
    }

    /* Page 1 CSS Ends */

    .page-2 {
        padding: 32px;
        font-family: "OpenSans", sans-serif;

        .info {
            min-height: 136px;
            height: auto;
            border-bottom: 1px solid #c4c4c4;
            border-right: 1px solid #c4c4c4;
            padding: 16px;

            .title {
                color: rgba(0, 0, 0, 0.54);
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 400;
                margin-bottom: 8px;
            }

            .value {
                font-size: 18px;
                text-transform: uppercase;
                font-weight: 400;
                color: var(--carbon);

                /* Line clamp */
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }

        .user-info-left {
            .info {
                border-left: 1px solid #c4c4c4;
            }
            div.info:first-child {
                border-top: 1px solid #c4c4c4;
            }
        }

        .user-info-right {
            .user-image-box {
                height: 272px;
                border-top: 1px solid #c4c4c4;
                border-right: 1px solid #c4c4c4;
                border-bottom: 1px solid #c4c4c4;
                padding: 40px 16px;

                .user-image {
                    width: 100%;
                    height: 180px;
                }
            }

            .info {
            }
        }
    }

    .page-header {
        font-family: "OpenSans", sans-serif;
        .desc {
            margin-top: 8px;
            margin-bottom: 32px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.54);
        }
    }

    /* Page 2 CSS Ends */

    .page-3 {
        padding: 32px;
        font-family: "OpenSans", sans-serif;

        .verified-skills-grid .skill-box {
            height: 192px;
            border-bottom: 1px solid #c4c4c4;
            border-left: 1px solid #c4c4c4;

            .course-title {
                margin-top: 8px;
            }
        }

        .verified-skills-grid {
            .left-pane div.skill-box:first-child,
            .right-pane div.skill-box:first-child {
                border-top: 1px solid #c4c4c4;
            }

            .right-pane .skill-box {
                border-right: 1px solid #c4c4c4;
            }
        }
    }

    /* Cerificate Page CSS */
    .certificate-page {
        padding: 32px;

        div.desc {
            margin-bottom: 32px;
        }

        .certificate-block {
            padding: 32px;
            border: 1px solid #c4c4c4;
            border-radius: 4px;
            margin-bottom: 16px;
        }

        .certificate-block .award-icon {
            height: 50px;
            width: 50px;

            .icon {
                height: 100%;
                width: 100%;
            }
        }

        .certificate-block .content {
            margin-left: 24px;
            .heading {
                font-weight: 500;
            }

            .heading,
            .course-name {
                margin-bottom: 8px;
            }

            .cert-link {
                text-decoration: underline;
                color: var(--bluelagoon);
            }
        }
    }

    /* Profiency Page CSS */
    .proficiency-overall-page {
        padding: 32px;
        font-family: "OpenSans", sans-serif;

        .ctag-proficiency-container {
            border: 1px solid #c4c4c4;
            border-radius: 4px;
            padding: 32px;
            margin-bottom: 16px;

            .title {
                margin-bottom: 16px;
                font-weight: 500;
                color: var(--carbon);
            }

            .prof-stats {
                display: flex !important;
                align-items: center;
                color: var(--carbon);
                .star-svg {
                    width: 14px;
                    height: 14px;
                    margin-right: 8px;
                }
                .score {
                    font-size: 18px;
                    font-weight: 500;
                }
            }

            .prof-progress {
                flex: 1;
                margin-left: 20px;
                margin-right: 20px;
            }

            .prof-level {
                display: flex !important;
                justify-content: flex-end;
                text-transform: uppercase;
                font-weight: 300;
                color: var(--carbon);
            }
        }
    }

    .proficiency-l1-tag {
        padding: 32px;
        font-family: "OpenSans", sans-serif;
        color: var(--carbon);

        .child-proficiency {
            .details {
                width: 50%;
                .star-svg {
                    width: 14px;
                    height: 14px;
                    margin-right: 8px;
                }
                .score {
                    font-size: 18px;
                    font-weight: 500;
                }
            }

            .prof-progress {
                width: 30%;
            }

            .prof-level {
                width: 20%;
            }
        }
    }
`;

export { PassportContainer };
