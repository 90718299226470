import React, { useRef } from "react";
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import CoursesCard from "../../Cards/CoursesCard";
import { Arrow } from "@egjs/flicking-plugins";
import IconScprites from "../../../assets/imgs/icons-sprites.png";

const CoursesCarousel = ({ course }) => {
    const _plugins = [new Arrow()];

    return (
        <>
            <Flicking
                key={`course-carousel-${Date.now()}`}
                plugins={_plugins}
                useFindDOMNode={true}
                align={"prev"}
                circular={true}
            >
                {course.child.map((child) => (
                    <CoursesCard {...child} key={`COURSE-CARD-${child.courseId}`} />
                ))}
                <ViewportSlot key={`arrow-keys-${Date.now()}`}>
                    <span
                        className="flicking-arrow-prev is-thin hide-m"
                        style={{ backgroundImage: "url(" + IconScprites + ")" }}
                    ></span>
                    <span
                        className="flicking-arrow-next is-thin"
                        style={{ backgroundImage: "url(" + IconScprites + ")" }}
                    ></span>
                </ViewportSlot>
            </Flicking>
        </>
    );
};

export default CoursesCarousel;
