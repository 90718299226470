import * as React from "react";
const SvgHeart = (props) => (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M14.75 5.75c0 1.912-1.106 3.875-3.287 5.838-.991.89-2.07 1.677-3.22 2.35a.507.507 0 0 1-.487 0c-.269-.15-6.506-3.694-6.506-8.188A3.75 3.75 0 0 1 8 3.5a3.75 3.75 0 0 1 6.75 2.25Z"
            fill={props.color ? props.color : "#8C8C8C"}
        />
    </svg>
);
export default SvgHeart;
