import { RESET_CODE, RESET_EDITOR, SWITCH_LANGUAGE, UPDATE_CODE } from "../constants/constants";

const languages = {
    "1": {
        name: "C",
        value:
            '#include <stdio.h>\nint main()\n{\n   // Try out your code here\n   printf("Hello, World!");\n   return 0;\n}',
        id: 1,
        namespace: "c",
    },
    "2": {
        name: "C++",
        value:
            '#include <iostream>\nusing namespace std;\n\nint main() \n{\n   // Try out your code here\n    cout << "Hello, World!";\n    return 0;\n}',
        id: 2,
        namespace: "cpp",
    },
    "3": {
        name: "Java 8",
        value:
            'class Main\n{\n  public static void main(String args[])\n  {\n    //Try out your code here\n    System.out.println("Hello World!");\n  }\n}',
        id: 3,
        namespace: "java",
    },
    "5": {
        name: "Python 3",
        value: "",
        id: 5,
        namespace: "python",
    },
};

const initialState = {
    languages: languages,
    currentLanguage: 1,
    value: languages["1"]["value"],
    userValues: {},
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SWITCH_LANGUAGE: {
            let payloadString = payload.toString();
            let value = state.languages[payloadString]["value"];
            if (state.userValues[payloadString]) value = state.userValues[payloadString];
            return { ...state, value, currentLanguage: payload };
        }
        case UPDATE_CODE:
            return { ...state, userValues: { ...state.userValues, [state.currentLanguage]: payload }, value: payload };
        case RESET_CODE: {
            let currentLanguage = state.currentLanguage;
            let value = state.languages[currentLanguage]["value"];
            return { ...state, userValues: { ...state.userValues, [currentLanguage]: value }, value };
        }
        case RESET_EDITOR:
            return initialState;
        default:
            return state;
    }
};
