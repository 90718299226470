import React from "react";

function SvgFb(props) {
    return (
        <svg width={8} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M6.552 2.656H8V.112C7.3.036 6.595-.001 5.89 0 3.796 0 2.364 1.328 2.364 3.76v2.096H0v2.848h2.364V16h2.833V8.704h2.356l.355-2.848h-2.71V4.04c0-.84.215-1.384 1.354-1.384z"
                fill="#000"
                className="fill"
            />
        </svg>
    );
}

export default SvgFb;
