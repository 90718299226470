import React, { Component } from "react";
import Layout from "../../components/Layout";
import { MetaTags } from "react-meta-tags";
import { check_login } from "../../utils/login.util";
import { __getUID, __getEmail, __getToken } from "../../utils/user-details.util";
import axios from "axios";
import { G_API_URL, G_HOME_URL } from "../../constants/constants";
import HeroContent from "../../components/PremiumLandingPage/HeroContent";
import PromoContent from "../../components/PremiumLandingPage/PromoContent";
import FeaturesContent from "../../components/PremiumLandingPage/FeaturesContent";
import face360analytics from "../../assets/imgs/hero_images/f360analytics.png";
import face360certificate from "../../assets/imgs/hero_images/f360ceritficate.png";
import face360gamified from "../../assets/imgs/hero_images/f360gamified.png";
import face360interactive from "../../assets/imgs/hero_images/f360interactive.png";
import winner from "../../assets/icons/svg_icons/winner.svg";
import recommendation from "../../assets/icons/svg_icons/recommendation.svg";
import goals from "../../assets/icons/svg_icons/goals.svg";
import { Button } from "antd";
import SubscriptionContainer from "../../components/PremiumLandingPage/SubscriptionContainer";
import Testimonials from "../../components/PremiumLandingPage/Testimonials";
import { openFailureNotification, openSuccessNotification } from "../../utils/notifications.util";

class Profile extends Component {
    state = {
        pageloaded: true,
        isPremiumUser: false,
        isTrialAvailable: false,
        mVerified: false,
        dashboardAccess:false,
        courseActive:[],
        courseExpired:[]
    };

    getPremiumStatus = async () => {
        let premium_status_api = G_API_URL + "orders/premium";
        if (check_login()) {
            await axios
                .post(premium_status_api, {
                    uid: __getUID(),
                    email: __getEmail(),
                })
                .then((response) => {
                    if (response.data.status === 1) {
                        let activeCourseIds = response.data.courseActive.map(c => c.course_id)
                        let expiredCourseIds = response.data.courseExpired.map(c => c.course_id)
                        this.setState({
                            isPremiumUser: true,
                            isTrialAvailable: response.data.free_trail_available,
                            dashboardAccess:response.data.dashboard_access,
                            courseActive:activeCourseIds,
                            courseExpired:expiredCourseIds

                        });
                    }
                })
                .catch((err) => {});
        }
    };

    /*
        ******** @@@@ checkUIDStatus @@@@ ********
        Functionalities:
            1. To Check whether the uid and mobile number is verified or not
            2. Api call to auth/mobile-no
            3. State set for "mVerified"
    */

    checkUIDStatus = async () => {
        let config = {
            headers: {
                Authorization: __getToken(),
            },
        };

        let mobile_response = await axios.get(G_API_URL + "auth/mobile-no", config);
        let mobile_status = mobile_response["data"];

        if (mobile_status.status === 0) this.setState({ mVerified: false });
        else if (mobile_status.status === 1) this.setState({ mVerified: true });
    };

    /* |||||||| End of checkUIDStatus ||||||| */

    /*
        ******** @@@@ checkPoisonLink @@@@ ********
        Functionalities:
            1. Extract the cname from props.match.params
            2. Api call to premium/ocid to get ocid value
            3. Set the OCID value in cookie
    */

    checkPoisonLink = async () => {
        if (
            this.props &&
            this.props.match &&
            this.props.match.params &&
            this.props.match.params.cname
        ) {
            let cname = this.props.match.params.cname;
            let config = {
                params: {
                    cname: cname,
                },
                headers: {
                    Authorization: __getToken(),
                },
            };

            try {
                let api_response = await axios.get(G_API_URL + "premium/ocid", config);
                if (api_response.data.status === 1) {
                    let data = api_response.data.data;
                    if (data.slug) document.cookie = `fp_ocid=${data.ocid}; path=/`;
                    openSuccessNotification("Link Verified");
                } else {
                    openFailureNotification("Link Not Valid, Red. to Premium page");
                    setTimeout(() => {
                        window.location.href = G_HOME_URL + "premium/";
                    }, 2000);
                }
            } catch (error) {
                console.log(error);
                window.location.href = G_HOME_URL + "premium/";
            }
        }
    };

    /* |||||||| End of checkPoisonLink ||||||| */

    componentDidMount = async () => {
        document.cookie = "premium_dashboard=true; path=/";
        this.getPremiumStatus();

        // Check for poison Link
        await this.checkPoisonLink();

        await this.checkUIDStatus();
    };

    promoContent = [
        {
            key: 1,
            text: "Interactive lessons on topics of your choice",
            img: face360interactive,
        },
        {
            key: 2,
            text: "Gamified practice to stay on top of your game",
            img: face360gamified,
        },
        {
            key: 3,
            text: "Analytics to fill up gaps in your prep",
            img: face360analytics,
        },
        {
            key: 4,
            text: "Certificates to flaunt your skills",
            img: face360certificate,
        },
    ];

    featureContent = [
        {
            key: 1,
            head: "Choose based on your goals",
            desc:
                "Whether you’re in it for wholesome prep or you have a specific goal, our plans put ‘You’ in the centre of everything.",
            icon: goals,
        },
        {
            key: 2,
            head: "Leaderboard and scores",
            desc: "Improve your skills and compete with learners from across the country.",
            icon: winner,
        },
        {
            key: 3,
            head: "A Top-class recommendation engine",
            desc:
                "Based on your goals and your learning patterns, our smart engine will recommened the most important lessons to you.",
            icon: recommendation,
        },
    ];

    testimonialsContent = [
        {
            key: 1,
            title:
                "It was simple and progressive. Topics where divided into comprehensible sections and the tasks helped to practice the theory we learned",
            desc:
                "The platform was interactive. Invigilators also helped a lot. They gave special tricky questions which improved the knowledge about the computer language we studied. This course helped me crack the recruitment entrance exams of ZS, Infosys, TCS Digital and ninja, Cognizant and Schneider electric",
            name: "Srijan Bhattacharjee",
            user_type: "CSE Dept, SRM Ramapuram",
            img:
                "https://i1.faceprep.in/PIP+Demo/company_logos/candidates_photos/srijan_premium.jpg",
        },
        {
            key: 2,
            title:
                "For the non-IT student like me, the FACE Prep premium course helped to land on an IT job. They made sure to make us learn coding from scratch.",
            desc:
                "The different course provided by FACE helped me to clear many placement online tests like TCS Digital, TCS Ninja, Wipro and also helped me to get a good score in Amcat which made me land a job in DXC Technology",
            name: "Safi Qadir Z",
            user_type: "SRM Institute of Science and Technology (KTR)",
            img: "https://i1.faceprep.in/PIP+Demo/company_logos/candidates_photos/safi_premium.png",
        },
        {
            key: 3,
            title:
                "Irrespective of the department, FACE Prep Premium will improve your Programming, Aptitude & English skills which are essential for your Placements",
            desc:
                "Rather it is a normal video lecture that is done by other course platforms. I was impressed with the interactive AI method of teaching which made me take up this course with more intent and excitement. The Aptitude & Verbal section cleared all my concepts and doubts, along with the shortcuts of solving a problem helps us to solve questions a lot faster",
            name: "Somya Gupta",
            user_type: "ECE Dept, SRM Ramapuram",
            img:
                "https://i1.faceprep.in/PIP+Demo/company_logos/candidates_photos/somya_premium.png",
        },
        {
            key: 4,
            title:
                "FACE Prep Premium was a great tutorial for beginners in C++, Also gave good guidance for cracking aptitude and interviews",
            desc:
                "As a CS student, the content was relatable in introducing the various basics of programming. All the statements required to build various logics were introduced in a simple manner and problems to test the same were presented with seamless integration. mostly various tutorials fail to introduce the need for some of the constructs which were overcome in this course in a very natural and simple manner",
            name: "Abdul Aziz Barkat",
            user_type: "CSE Dept, M.H.Saboo Siddik College of Engineering",
            img:
                "https://i1.faceprep.in/PIP+Demo/company_logos/candidates_photos/no_dp_premium.png",
        },
    ];

    render() {
        const { isPremiumUser, isTrialAvailable, mVerified, dashboardAccess, courseExpired, courseActive } = this.state;
        return (
            <>
                <MetaTags>
                    <title>Face Prep premium</title>
                    {/*Adding noindex property temporarily*/}
                    <meta name="robots" content="noindex" />
                </MetaTags>
                <div id="main">
                    <Layout>
                        <div className="premium-page-wrapper">
                            <div className="lr-pad-d tb-pad-d lr-pad-m">
                                <HeroContent
                                    isPremiumUser={isPremiumUser}
                                    isTrialAvailable={isTrialAvailable} 
                                    dashboardAccess = {dashboardAccess}
                                />
                            </div>
                            <div className="promo-container ">
                                <div className="lr-pad-d tb-pad-d lr-pad-m">
                                    <PromoContent promoContent={this.promoContent} />
                                </div>
                            </div>
                            <div className="content-wrapper">
                                <div className="lr-pad-d tb-pad-d lr-pad-m">
                                    <div className="content f-d f-ht">
                                        <h1 className="h1-heading text">
                                            Start your prep for as low as INR 199 per month.
                                        </h1>
                                        <a href={"#subscribe"}>
                                            <Button className="view-plan-btn">View Plans</Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="feature-block-container ">
                                <div className="lr-pad-d tb-pad-d lr-pad-m">
                                    <FeaturesContent featureContent={this.featureContent} />
                                </div>
                            </div>

                            <div className="courses-container" id="subscribe">
                                <SubscriptionContainer
                                    isPremiumUser={isPremiumUser}
                                    isTrialAvailable={isTrialAvailable}
                                    mVerified={mVerified}
                                    handleUIDStatus={this.checkUIDStatus}
                                    courseActive = {courseActive}
                                    courseExpired = {courseExpired}
                                />
                            </div>

                            <div className="testimonials-container lr-pad-d tb-pad-d lr-pad-m">
                                <Testimonials testimonialContent={this.testimonialsContent} />
                            </div>
                        </div>
                    </Layout>
                </div>
                <style jsx={"true"}>
                    {`
                        .promo-container,
                        .feature-block-container {
                            background: var(--smoke);
                        }
                        .individual-wrapper {
                            width: 100%;
                        }
                        .content-wrapper .content {
                            background: var(--facered);
                            justify-content: space-between;
                            padding: 40px;
                            align-items: center;
                        }
                        .content-wrapper .content .text {
                            color: var(--dove);
                            width: 70%;
                        }
                        .content-wrapper .content .view-plan-btn {
                            color: var(--facered);
                            width: 150px;
                            height: 50px;
                            align-self: center;
                        }
                        @media only screen and (max-device-width: 760px) {
                            .premium-page-wrapper .h1-heading {
                                font-size: 24px;
                            }
                            .premium-page-wrapper .feature-content-wrapper,
                            .premium-page-wrapper .hero-content-wrapper,
                            .premium-page-wrapper .promo-content-wrapper,
                            .premium-page-wrapper .content {
                                flex-direction: column !important;
                            }
                            .hero-content-wrapper .content-holder {
                                width: 100%;
                                margin-bottom: 40px;
                            }
                            .promo-card-wrapper .img-holder {
                                height: 300px;
                                width: auto;
                            }
                            .card-container .card {
                                width: 100%;
                            }
                            .premium-page-wrapper .card-wrapper {
                                width: 100%;
                            }
                            .premium-page-wrapper .promo-card {
                                display: none;
                            }
                            .premium-page-wrapper .promo-active-card {
                                display: block;
                            }
                            .premium-page-wrapper .content {
                                padding: 20px;
                            }
                            .premium-page-wrapper .content .text {
                                width: 100%;
                            }
                            .premium-page-wrapper .feature-content-wrapper .card-container {
                                width: 100%;
                            }
                            .premium-page-wrapper .package-variant {
                                display: block;
                            }
                            .premium-page-wrapper .modules-container {
                                grid-template-columns: repeat(1, auto) !important;
                            }
                            .premium-page-wrapper .subscription-module {
                                width: 100%;
                            }
                            .premium-page-wrapper .hero-img {
                                width: 100%;
                            }
                            .testimonials-card-wrapper .card-container {
                                height: auto;
                            }
                            .testimonials-card-wrapper .user-details {
                                line-height: 1.2;
                            }
                            .testimonial-heading {
                                font-size: 18px;
                            }
                            .module-accessible {
                                height: 20px;
                            }
                            .price-card-container .access-txt {
                                align-items: center;
                            }
                            .testimonials-card-wrapper .crousel-container {
                                padding: 0px;
                            }
                        }
                    `}
                </style>
            </>
        );
    }
}

export default Profile;
