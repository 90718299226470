import React from "react";
import ReactGA from "react-ga";
import {
    EmailIcon,
    EmailShareButton,
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    LinkedinIcon,
} from "react-share";
import telegram from "../../assets/imgs/social_images/telegram.svg";
import link from "../../assets/imgs/social_images/link.svg";
import { notification } from "antd";
import styled from "styled-components";
import WhatsAppSVG from "./svg/whatsapp";
import TwitterSVG from "./svg/twitter";
import FacebookSVG from "./svg/fb";
import LinkedinSVG from "./svg/linkedin";

const IconContainer = styled.div`
    height: 36px;
    width: 36px;
    background-color: var(--smoke);
    border-radius: 50%;
    background-image: url(${(props) => props.icon});
    background-size: inherit;
    border: 1px solid var(--snowfall);
    cursor: pointer;

    :hover {
        background-color: ${(props) => props.hoverBgColor};
        border: 1px solid ${(props) => props.hoverIconColor};
        svg .fill {
            fill: ${(props) => props.hoverIconColor};
        }
    }
`;

let handleMediaShare = (content, platform) => {
    // Send Video Share Event to GA
    ReactGA.event({
        category: "VideoLibrary",
        action: "video_shared",
        label: content.url + `?platform=${platform}`,
    });
};

const LinkedinShare = (props) => {
    return (
        <LinkedinShareButton
            url={props.url}
            onShareWindowClose={() => handleMediaShare(props, "email")}
        >
            <IconContainer
                hoverBgColor={"#e5f1f8"}
                hoverIconColor={"#0077b5"}
                className="f-d f-v-c f-h-c"
            >
                <LinkedinSVG />
            </IconContainer>
        </LinkedinShareButton>
    );
};

const LinkShare = (props) => {
    return (
        <div
            className="bg-image social-video-share"
            style={{ backgroundImage: "url('" + link + "')" }}
            onClick={() => {
                // message.success("Link copied to clipboard. Share away!");
                openSuccessNotification("Link copied to clipboard. Share away!");
                navigator.clipboard.writeText(props.url);
                handleMediaShare(props, "direct");
            }}
        />
    );
};

const EmailShare = (props) => {
    return (
        <EmailShareButton
            url={props.url}
            onShareWindowClose={() => handleMediaShare(props, "email")}
        >
            <EmailIcon round={true} size={36} />
        </EmailShareButton>
    );
};

const WhatsAppShare = (props) => {
    return (
        <WhatsappShareButton
            url={props.url}
            onShareWindowClose={() => handleMediaShare(props, "whatsapp")}
        >
            <IconContainer
                hoverBgColor={"#e9fbf0"}
                hoverIconColor={"#25d366"}
                className="f-d f-v-c f-h-c"
            >
                <WhatsAppSVG />
            </IconContainer>

            {/* <div
                className="bg-image social-video-share"
                style={{ backgroundImage: "url('" + whatsapp + "')" }}
            /> */}
        </WhatsappShareButton>
    );
};

const FacebookShare = (props) => {
    return (
        <FacebookShareButton
            url={props.url}
            onShareWindowClose={() => handleMediaShare(props, "facebook")}
        >
            <IconContainer
                hoverBgColor={"#e8f1fe"}
                hoverIconColor={"#1877F2"}
                className="f-d f-v-c f-h-c"
            >
                <FacebookSVG />
            </IconContainer>
            {/* <FacebookIcon round={true} size={36} /> */}
        </FacebookShareButton>
    );
};

const TwitterShare = (props) => {
    return (
        <TwitterShareButton
            url={props.url}
            onShareWindowClose={() => handleMediaShare(props, "twitter")}
        >
            <IconContainer
                hoverBgColor={"#e8f6fe"}
                hoverIconColor={"#1da1f2"}
                className="f-d f-v-c f-h-c"
            >
                <TwitterSVG />
            </IconContainer>
            {/* <TwitterIcon round={true} size={36} /> */}
        </TwitterShareButton>
    );
};

const TelegramShare = (props) => {
    return (
        <TelegramShareButton
            url={props.url}
            onShareWindowClose={() => handleMediaShare(props, "telegram")}
        >
            <div
                className="bg-image social-video-share"
                style={{ backgroundImage: "url('" + telegram + "')" }}
            />
        </TelegramShareButton>
    );
};

let openSuccessNotification = (msg) => {
    const args = {
        message: msg,
        description: "",
        duration: 6,
        top: 54,
        className: "success-notification-top",
    };
    notification.open(args);
};

export {
    EmailShare,
    WhatsAppShare,
    FacebookShare,
    TwitterShare,
    TelegramShare,
    LinkShare,
    LinkedinShare,
};
