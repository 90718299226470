import React from "react";
import {__getEmail, __getToken} from "../user-details.util";
import {G_HOME_URL, G_LiveClass_URL, G_PRO_URL} from "../../constants/constants";

const displayEnrollBtn = (
    isLogin,
    RedirectToLogin,
    paymentStatus,
    PaymentHandler,
    internationalUser,
    active_batch_id
) => {
    if (active_batch_id === "none" && paymentStatus === false) {
        return (
            <div className="enroll-btn" style={{opacity: "33%"}}>
                Enrollment closed
            </div>
        );
    }

    if (!isLogin) {
        return (
            <div className="enroll-btn" onClick={RedirectToLogin}>
                Enroll Now
            </div>
        );
    }
    if (isLogin && !paymentStatus) {
        return PaymentHandler("ProHero", internationalUser);
    }
    if (isLogin && paymentStatus) {
        return (
            <>
                <div
                    onClick={() => {
                        if (window.location.href.includes('tcs-codevita') && !window.location.href.includes('tcs-codevita-cracker')) {
                            window.location.href = G_HOME_URL + "prograd/tcscodevita2020/"
                        } else if (window.location.href.includes('prograd-bootcamp') || window.location.href.includes('full-stack-developer') || window.location.href.includes('front-end-web-development-bootcamp') || window.location.href.includes('full-stack-developer-mern')) {
                            document.getElementsByClassName("progradLogin")[0].submit();
                        } else {
                            document.getElementsByClassName("placementLogin")[0].submit();
                        }
                    }
                    }
                    className="enroll-btn"
                >
                    Start Learning
                </div>
                <form className="progradLogin hide" method="post" action={G_PRO_URL + "auth/"}>
                    <input name="u_t" value={__getToken()}/>
                </form>
                <form className="placementLogin hide" method="post" action={G_LiveClass_URL + "live-classroom-auth/"}>
                    <input name="u_t" value={__getToken()}/>
                </form>
            </>
        );
    } else return <div className="enroll-btn">Enroll Now</div>;
};

export default displayEnrollBtn;
