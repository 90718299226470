import React, {Component} from 'react';


class FeaturesContent extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }


    componentDidMount() {
    }


    renderCard(content) {
        return Object.values(content).map((item, i) => {
            return (
                <div className="card-container f-d" key={"FP" + i}>
                    <div className="card f-d f-vt">
                        <img className="img-holder" src={item.icon} alt="" />
                        <h2 className="h2-heading">{item.head}</h2>
                        <span className="body-small">{item.desc}</span>
                    </div>
                </div>
            )
        })
    }

    render() {
        const {featureContent} = this.props;
        return (
            <>
                <div className="feature-card-wrapper f-d f-vt">
                    <h2 className="h2-heading">Check out some of our popular features.</h2>
                    <div className="feature-content-wrapper f-d f-ht">
                        {
                            this.renderCard(featureContent)
                        }
                    </div>
                </div>

                <style jsx>
                    {`
                    .feature-content-wrapper{
                        justify-content: space-between;
                        overflow: auto;
                        width: 100%
                    }
              
                    .card-container .card{
                        width: 300px;
                        padding: 40px;
                    }
                    .card .img-holder{
                        align-self: start;
                        height: 80px;
                        margin-bottom: 20px;
                    }
                    `}
                </style>
            </>
        );

    }
}

export default FeaturesContent;