import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { check_login } from "../../utils/login.util";
import axios from "axios";
import { G_API_URL, SET_QUIZR_TOKEN, UPDATE_SKILLZONE } from "../../constants/constants";
import { __getToken } from "../../utils/user-details.util";
import jwtDecode from "jwt-decode";
import moment from "moment";
import Spinner from "../Spinner/spinner";
import Layout from "../Layout";

const WithSkillzoneHoc = ({ component: Component, ...rest }) => {
    const dispatch = useDispatch();
    const [loading, setloading] = useState(true);
    const [checkconditions, setCheckconditions] = useState({
        quizr_token: 0,
        register_stats: 0,
        paymentValidity: 0,
    });

    const QuizrTokenDetails = useSelector((state) => state.jwtData.quizr_token);

    const checkLoading = () => {
        let checkConditionValues = Object.values(checkLoading);
        if (!checkConditionValues.includes(0)) setloading(false);
    };

    // call the Quizr generate Token api
    const generateQuizrTokenApi = async () => {
        await axios
            .get(`${G_API_URL}skills/generate/quizr-token/`, {
                headers: {
                    Authorization: __getToken(),
                },
            })
            .then((res) => {
                if (res.data && res.data.status === 1) {
                    let token = res.data.token;
                    const qTokenData = {
                        token,
                        expireAt: jwtDecode(token)["exp"]
                    }
                    dispatch({ type: SET_QUIZR_TOKEN, payload: { quizr_token: qTokenData } });
                    setCheckconditions({ ...checkconditions, quizr_token: 1 });
                    // setloading(false);
                }
            });
        checkLoading();
    };

    const registerStatsApi = async () => {
        await axios
            .get(`${G_API_URL}skills/register/skill-stats`, {
                headers: {
                    Authorization: __getToken(),
                },
            })
            .then((res) => {
                if (res.data && res.data.status === 1) {
                    setCheckconditions({ ...checkconditions, register_stats: 1 });
                }
            });
        checkLoading();
    };

    const checkPaymentValidity = async () => {
        let config = {};
        let loginStatus = check_login();

        if (loginStatus)
            config = {
                headers: {
                    Authorization: __getToken(),
                },
            };

        await axios.get(`${G_API_URL}payment/validity`, config).then((res) => {
            if (res.data) {
                dispatch({ type: UPDATE_SKILLZONE, payload: { subscription: res.data } });
                setCheckconditions({ ...checkconditions, paymentValidity: 1 });
            }
        });

        if (!loginStatus || (rest && rest["type"] === "skillboard")) setloading(false);
        else checkLoading();
    };

    useEffect(() => {
        if (check_login() && rest && rest["type"] === "skillboard") {
            checkPaymentValidity();
        } else if (check_login() && rest && rest["type"] !== "skillboard") {
            let QzTkApiToCall = true;
            // Check condtions to call Quizr Api
            let QzTkExpireAt = QuizrTokenDetails ? QuizrTokenDetails.expireAt : null;

            let today = moment();
            if (QzTkExpireAt) {
                let expireAt = moment.unix(QzTkExpireAt);
                if (expireAt.diff(today) > 1) {
                    QzTkApiToCall = false;
                    setCheckconditions({ ...checkconditions, quizr_token: 1 });
                    // setloading(false);
                }
            }
            // Conditon to check whether api is needed to be called or not
            if (QzTkApiToCall) generateQuizrTokenApi();
            // Call the register Stats Api
            registerStatsApi();
            // Call to Payment validity
            checkPaymentValidity();
        } else {
            checkPaymentValidity();
        }
    }, []);

    return (
        <Route
            {...rest}
            render={(props) => (
                <>
                    {loading ? (
                        <Layout>
                            <Spinner />
                        </Layout>
                    ) : (
                        <Component {...props} />
                    )}
                </>
            )}
        />
    );
};

export default WithSkillzoneHoc;
