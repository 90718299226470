import React, { useEffect, useState } from "react";
import axios from "axios";
import { check_login } from "../../utils/login.util";
import { Route, useHistory } from "react-router-dom";
import Spinner from "../Spinner/spinner";
import Layout from "../LayoutV2";
import { G_API_URL } from "../../constants/constants";
import { __getToken } from "../../utils/user-details.util";
import { message } from "antd";

const WithFaceproHoc = ({ component: Component, ...rest }) => {
    const [loading, setloading] = useState(true);
    const history = useHistory();

    const checkValidity = () => {
        axios.get(`${G_API_URL}orders/faceprep-pro`, { headers: { Authorization: __getToken() } }).then((res) => {
            if (res.data && res.data.status === 1) {
                setloading(false);
            } else {
                const notification = message.warn("Not subscribed user");
                setTimeout(() => {
                    notification();
                    history.replace("/facepro");
                }, 2000);
            }
        });
    };

    useEffect(() => {
        if (check_login()) {
            checkValidity();
        } else {
            history.replace(`/login?rurl=/facepro/dashboard/courses`);
        }
    }, [checkValidity]);

    return (
        <Route
            {...rest}
            render={(props) => (
                <>
                    {loading ? (
                        <Layout>
                            <Spinner />
                        </Layout>
                    ) : (
                        <Component {...props} />
                    )}
                </>
            )}
        />
    );
};

export default WithFaceproHoc;
