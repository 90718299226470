import React from "react";
import styled from "styled-components";
import SvgQuoteIcon from "./svg/quote-icon";

let card_colors = ["#FBE196", "#ffdada", "#dcf7e8"];
let quote_colors = ["#e7c662", "#ffb3b3", "#acecc9"];

const TestimonialCardContainer = styled.div`
    width: 100%;

    .card-content {
        background-color: ${(props) => props.bgColor};
        height: auto;
        padding: 32px;
        min-height: 280px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        position: relative;

        .card-desc {
            margin-top: 12px;
        }

        svg .fill {
            fill: ${(props) => props.quoteColor};
        }
    }

    .card-author {
        background-color: var(--dove);
        height: auto;
        min-height: 120px;
        padding: 32px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .card-author .author-image {
        background-image: url(${(props) => props.user_pic});
        border-radius: 50%;
        width: 64px;
        height: 64px;
        margin-right: 16px;
    }

    .card-author .author-name {
        font-family: "Nunito sans", sans-serif !important;
        color: var(--carbon);
    }
`;

const TestimonialCard = (props) => {
    let bgColor = card_colors[props.idx % 3];
    let quoteColor = quote_colors[props.idx % 3];

    return (
        <TestimonialCardContainer
            bgColor={bgColor}
            quoteColor={quoteColor}
            user_pic={props.data.user_image}
        >
            <div className="card-content">
                <SvgQuoteIcon />
                <div className="card-desc body-small">{props.data.msg}</div>
            </div>
            <div className="card-author f-d">
                <div className="author-image bg-image-full"></div>
                <div className="author-details f-d f-vt f-h-c">
                    <div className="author-name h6-heading-v2 ">{props.data.user_name}</div>
                    <div className="author-desg">{props.data.user_batch}</div>
                </div>
            </div>
        </TestimonialCardContainer>
    );
};

export default TestimonialCard;
