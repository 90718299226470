import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import axios from "axios";
import Layout from "../../components/Layout";
import { Input, Row } from "antd";
import Grid from "../../components/Grid/Grid";
import Spinner from "./../../components/Spinner/spinner";
import "./courses.css";
import { G_API_LEARN_URL } from "../../constants/constants";

class Courses extends Component {
    state = {
        courses: [],
        search: [],
        count: [],
        searched: false
    };

    componentDidMount() {
        // Making Object Function
        function courseObj(id, topic) {
            this.id = id;
            this.topic = topic;
        }

        // Initializing Temp array to destructure the element and add id along with them
        let temp = [];

        let videos_topic_api = G_API_LEARN_URL + "videos/topics/";
        axios
            .get(videos_topic_api)
            .then(response => {
                let id = 0;
                // eslint-disable-next-line array-callback-return
                response.data.map(topic => {
                    temp.push(new courseObj(id, topic));
                    id++;
                });
                this.setState({ courses: temp });
                this.setState({ search: temp });
                // console.log("temp", temp.length);
            })
            .catch(error => {
                // handle error
                console.log(error);
            });
    }

    handleSearch = value => {
        function courseObj(id, topic) {
            this.id = id;
            this.topic = topic;
        }

        if (value === "") {
            const { search } = this.state;
            let count = [];
            this.setState({ courses: search, count });
        } else {
            let temp = [];
            let video_search_api = G_API_LEARN_URL + `videos/tosearch/?search=${value}`;
            axios
                .get(video_search_api)
                .then(response => {
                    const { topics_name, count } = response.data;

                    let id = 0;
                    // eslint-disable-next-line array-callback-return
                    topics_name.map(topic => {
                        temp.push(new courseObj(id, topic));
                        id++;
                    });

                    this.setState({ courses: temp, count, searched: true });
                    // console.log(count);
                })
                .catch(error => {
                    // handle error
                    console.log(error);
                });
        }
    };

    render() {
        const { Search } = Input;
        const { courses } = this.state;

        return (
            <>
                <MetaTags>
                    <title>Free Aptitude & Programming Tutorials for Beginners</title>
                    <meta
                        name="description"
                        content="Learn to code in C, C++, Java, Python and other programming languages using our simple tutorials for beginners. Our free Aptitude & programming tutorials along with sample questions will help you master them all."
                    />
                </MetaTags>
                <Layout>
                    <div className="videos-main-container lr-pad-d lr-pad-m">
                        <Row
                            type="flex"
                            justify="space-around"
                            align="middle"
                            style={{ marginBottom: 40, marginTop: 80 }}
                        >
                            {/* <img alt="" src={brand_logo} className="img-logo" /> */}
                        </Row>

                        <Row type="flex" justify="center" align="middle" style={{ marginTop: 40 }}>
                            <p id="sub-1">Video Project</p>
                        </Row>

                        <p id="sub-2" style={{ textAlign: "center" }}>
                            A free repository of more than 1000 videos
                        </p>
                        <p id="sub-2" style={{ textAlign: "center" }}>
                            curated from FACE Prep's video library.
                        </p>

                        <Row
                            type="flex"
                            justify="center"
                            align="middle"
                            style={{ marginTop: 40, marginBottom: 40 }}
                        >
                            <Search
                                placeholder="Search for a title"
                                enterButton="Go"
                                className="search-bar"
                                onSearch={value => this.handleSearch(value)}
                            />
                        </Row>

                        {courses.length === 0 && this.state.searched === false ? (
                            <Spinner />
                        ) : (
                            <Grid
                                courses={courses}
                                count={this.state.count}
                                searched={this.state.searched}
                            />
                        )}
                    </div>
                </Layout>
            </>
        );
    }
}

export default Courses;
