export const ICONS = {
    BRAND_ICON: "../assets/icons/svg_icons/logo_web.svg",
};

/***REDUX CONSTANTS***/
export const CLEAR_STORE = "CLEAR_STORE";
export const SET_JWT_DATA = "SET_JWT_DATA";
export const SET_SKILLZONE = "SET_SKILLZONE";
export const UPDATE_SKILLZONE = "UPDATE_SKILLZONE";
export const SET_ASSESS_DRAWER = "SET_ASSESS_DRAWER";
export const SET_ASSESS_DATA = "SET_ASSESS_DATA";
export const SET_ASSESS_TITLE = "SET_ASSESS_TITLE";
export const SET_ASSESS_CAT_TYPE = "SET_ASSESS_CAT_TYPE";
export const SET_STATS_LOADING = "SET_STATS_LOADING";
export const SET_STATS_DATA = "SET_STATS_DATA";
export const SET_PRACTICE_DATA = "SET_PRACTICE_DATA";
export const UPDATE_PRACTICE_DATA = "UPDATE_PRACTICE_DATA";
export const SWITCH_LANGUAGE = "SWITCH_LANGUAGE";
export const UPDATE_CODE = "UPDATE_CODE";
export const RESET_CODE = "RESET_CODE";
export const RESET_EDITOR = "RESET_EDITOR";
export const SET_QUIZR_TOKEN = "SET_QUIZR_TOKEN";
export const SET_COMP_QUESTION_OPTION = "SET_COMP_QUESTION_OPTION";
export const SET_COMP_GUESS_STATUS = "SET_COMP_GUESS_STATUS";
export const RESET_COMP_DATA = "RESET_COMP_DATA";
export const TOGGLE_SHOW_REPORTS_DATA = "TOGGLE_SHOW_REPORTS_DATA";
export const SET_REPORTS_DATA = "SET_REPORTS_DATA";
export const CUR_REPORT_INSTANCEID = "CUR_REPORT_INSTANCEID";
export const SET_REPORT_LOADING = "SET_REPORT_LOADING";
export const SET_SKILL_PLANS = "SET_SKILL_PLANS";
export const SKILL_PLANS_LOADING = "SKILL_PLANS_LOADING";
export const SHOW_ASSESS_REPORTS = "SHOW_ASSESS_REPORTS";
export const SET_PROFILE_DATA = "SET_PROFILE_DATA";
export const SET_SKILLBOARD_CID = "SET_SKILLBOARD_CID";

/***REDUX CONSTANTS***/

export const G_HOME_URL = process.env.REACT_APP_G_HOME_URL;
export const G_URL = process.env.REACT_APP_G_URL;
export const G_API_LEARN_URL = process.env.REACT_APP_G_API_LEARN_URL;
export const G_API_FEED_URL = process.env.REACT_APP_G_API_FEED_URL;
export const G_COOKIE_PREFIX = process.env.REACT_APP_G_COOKIE_PREFIX;
export const G_COOKIE_DOMAIN = process.env.REACT_APP_G_COOKIE_DOMAIN;
export const G_API_URL = process.env.REACT_APP_G_API_URL;
export const G_FC_API_URL = process.env.REACT_APP_G_FC_MERN_API;
export const G_CF_URL = process.env.REACT_APP_G_CF_URL;
export const G_F_API_URL = process.env.REACT_APP_G_F_API_URL;
export const G_QZ_API_URL = process.env.REACT_APP_G_QZ_API_URL;
export const IP_STACK_KEY = process.env.REACT_APP_IP_STACK_KEY;
export const IP_GEO_KEY = process.env.REACT_APP_IP_GEO_KEY;
export const IP_GEO_API = process.env.REACT_APP_IP_GEO_API;
export const FB_APP_ID = process.env.REACT_APP_FB_APP_ID;
export const GOOGLE_APP_ID = process.env.REACT_APP_GOOGLE_APP_ID;
export const G_PRO_URL = process.env.REACT_APP_G_PRO_URL;
export const GA_TRACK_ID = process.env.REACT_APP_GA_TRACK_ID;
export const GTM_TRACK_ID = process.env.REACT_APP_GTM_TRACK_ID;
export const G_LiveClass_URL = process.env.REACT_APP_G_LiveClass_URL;
export const G_F360_URL = process.env.REACT_APP_G_F360_URL;
export const G_PG_MERN_URL = process.env.REACT_APP_G_PG_MERN_URL;
export const G_SKILLZONE_QUIZR_URL = process.env.REACT_APP_SKILLZONE_QUIZR_URL;
export const G_QZ_URL = process.env.REACT_APP_G_QZ_URL;
export const RAZORPAY_KEY_ID = process.env.REACT_APP_RAZORPAY_KEY_ID;
export const FP_COOKIE_DOMAIN = "faceprep.in";
export const FP_COOKIE_PREFIX = "_fpg_";
export const APP_ENV = process.env.REACT_APP_ENV;

// Used in utils crypto.util.js
export const ENCRYPT_KEY = process.env.REACT_APP_ENCRYPT_KEY;
export const ENCRYPT_SECRET = process.env.REACT_APP_ENCRYPT_SECRET;
