import React from "react";
import styled from "styled-components";

const ArticleImageContainer = styled.div`
    background-color: ${(props) => props["bg-color"]};
    color: ${(props) => props["text-color"]};
    min-height: 250px;
    height: auto;
    width: 100%;
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    .ad-left-wrap {
        width: 80%;
        padding: 32px;
    }

    .ad-left-wrap .ad-title {
        font-family: "Nunito sans", sans-serif !important;
        width: 100%;
        margin-bottom: 16px;
    }

    .ad-left-wrap .ad-desc {
        width: 100%;
        color: ${(props) => props["text-color"]};
    }

    .ad-right-wrap {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        img {
            height: 200px;
            padding: unset;
        }
    }

    @media only screen and (max-device-width: 760px) {
        height: auto;
        min-height: 494px;
        flex-direction: column;
        justify-content: space-between;

        .ad-left-wrap {
            width: 100%;
        }

        .ad-left-wrap .ad-title {
            width: 100%;
            font-size: 32px !important;
            line-height: 35.2px !important;
        }

        .ad-left-wrap .ad-desc {
            width: 100%;
        }

        .ad-right-wrap {
            margin-top: 16px;
            width: 100%;
            img {
                height: 200px;
            }
        }
    }

    @media all and (max-width: 1260px) {
        height: max-content;
    }
`;

const ArticleAdBtn = styled.div`
    width: 180px;
    height: 50px;
    border-radius: 4px;
    background-color: ${(props) => props["bg-color"]};
    color: ${(props) => props["text-color"]};
    margin-top: 32px;
`;

const ArticleAd = (props) => {
    const { image, link, color, btn, title, desc } = props;

    return (
        <>
            <a href={link} className="ad-link" target="_blank">
                <ArticleImageContainer
                    bg-color={color["bg-color"]}
                    text-color={color["text-color"]}
                    bgImage={image}
                    className="f-d"
                >
                    <div className="ad-left-wrap">
                        <div className="ad-title h3-heading-v2">{splice(title, 25)}</div>
                        <div className="ad-desc body-medium-text">{splice(desc, 90)}</div>
                        <ArticleAdBtn
                            className="f-d f-v-c f-h-c"
                            bg-color={btn["bg-color"]}
                            text-color={btn["text-color"]}
                        >
                            {btn.text}
                        </ArticleAdBtn>
                    </div>
                    <div className="ad-right-wrap f-d f-v-e">
                        <img src={image} alt={"ad_image"} />
                    </div>
                </ArticleImageContainer>
            </a>
            <style jsx={"true"}>{`
                .ad-link,
                .ad-link:active,
                .ad-link:hover {
                    color: unset;
                }
            `}</style>
        </>
    );
};

const SideBardCardWrapper = styled(ArticleImageContainer)`
    min-height: 435px;
    height: auto;
    width: 100%;
    margin-bottom: 32px;
    position: relative;

    .ad-left-wrap {
        width: 100%;
    }

    .ad-left-wrap .ad-title {
        width: 100%;
    }

    .ad-left-wrap .ad-desc {
        width: 100%;
    }

    .ad-right-wrap {
        width: 100%;

        .ad-image {
            background-image: url(${(props) => props.bgImage});
            height: 200px;
            width: 200px;
        }
    }
`;

const splice = (text, max_char) => {
    if (text.length && text.length > max_char) {
        text = text.slice(0, max_char);
        text += "...";
        return text;
    }
    return text;
};

const SideBarAd = (props) => {
    const { image, link, color, btn, title, desc } = props;

    return (
        <>
            <a href={link} className="ad-link" target="_blank">
                <SideBardCardWrapper
                    bg-color={color["bg-color"]}
                    text-color={color["text-color"]}
                    bgImage={image}
                    className="f-d f-vt"
                >
                    <div className="ad-left-wrap">
                        <div className="ad-title h3-heading-v2">{splice(title, 25)}</div>
                        <div className="ad-desc body-medium-text">{splice(desc, 90)}</div>
                        <ArticleAdBtn
                            className="f-d f-v-c f-h-c"
                            bg-color={btn["bg-color"]}
                            text-color={btn["text-color"]}
                        >
                            {btn.text}
                        </ArticleAdBtn>
                    </div>
                    <div className="ad-right-wrap f-d f-v-e">
                        <div className="bg-image-full ad-image"></div>
                    </div>
                </SideBardCardWrapper>
            </a>

            <style jsx={"true"}>{`
                .ad-link,
                .ad-link:active,
                .ad-link:hover {
                    color: unset;
                }
            `}</style>
        </>
    );
};

export { SideBarAd };

export default ArticleAd;
