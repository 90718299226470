import { Icon, message } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import arrowRedSvg from "./../../assets/icons/svg_icons/arrow-red.svg";
import axios from "axios";
import { G_API_URL } from "../../constants/constants";
import { __getToken } from "../../utils/user-details.util";

const StyledCourseCard = styled.div`
    width: 344px;
    min-height: 272px;
    background: var(--dove);
    border-radius: 16px;
    padding: 8px;
    margin-right: 24px;
    position: relative;

    z-index: 1;
    .course-card-image {
        width: 100%;
        height: 152px;
        border-radius: 12px;
    }

    .course-title {
        font-weight: 400;
        margin-top: 16px;
    }

    .cta {
        color: var(--fp-orange);
        gap: 8px;

        .cta-text {
            margin-bottom: unset;
        }

        .icon {
            width: 14px;
            height: 14px;
            background-image: url(${arrowRedSvg});
        }
    }

    .favorite-container {
        position: absolute;
        right: 18px;
        top: 18px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        width: 26px;
        height: 26px;
        z-index: 9999;
    }

    @media only screen and (max-device-width: 768px) {
        width: 248px;
        min-height: 200px;

        .course-card-image {
            height: 110px;
        }

        .course-title {
            font-size: 16px;
        }
    }
`;

const CoursesCard = ({ bgImage, title, link, favorite = false, courseId }) => {
    const [isFavorite, setisFavorite] = useState(false);

    useEffect(() => {
        setisFavorite(favorite);
    }, []);

    const handleFavorite = () => {
        axios
            .put(
                `${G_API_URL}facepro/favorite/`,
                { course_id: courseId, type: isFavorite ? "unset" : "set" },
                { headers: { Authorization: __getToken() } }
            )
            .then((res) => {
                if (res.data) {
                    message.success(res.data.msg);
                }
            });

        setisFavorite(!isFavorite);
    };

    return (
        <a href={link}>
            <StyledCourseCard>
                <img src={bgImage} className="course-card-image" />
                <p className="body-large-text font-nunito text-c-d course-title">{title}</p>
                <div className="cta f-d f-v-c f-h-c c-pointer">
                    <p className="cta-text">Go to course</p>
                    <div className="icon bg-image-full"></div>
                </div>
                <div
                    className="favorite-container f-d f-h-c f-v-c"
                    onClick={(e) => {
                        e.preventDefault();
                        handleFavorite();
                    }}
                >
                    <Icon type="heart" theme={isFavorite ? "filled" : "outlined"} style={{ color: "var(--dove)" }} />
                </div>
            </StyledCourseCard>
        </a>
    );
};

export default CoursesCard;
