import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import OverviewCard from "../Cards/OverviewCard";
import CourseHeroCarousel from "./CourseCarousel";
import { isMobile } from "react-device-detect";

const OverviewContainer = styled.div`
    padding-top: 64px;
    padding-bottom: 64px;

    .overview-heading {
        font-family: "Nunito sans", sans-serif !important;
        color: var(--carbon);
    }

    .overview-key-details {
        margin-top: 24px;
    }

    .overview-key-details .red-border {
        width: 2px;
        height: 17px;
        background-color: var(--facered);
        margin-right: 8px;
    }

    .overview-key-details .desc {
        color: var(--granite);
    }

    .content-details-wrap {
        margin-top: 56px;
    }

    @media only screen and (max-width: 768px) {
        .content-details-wrap {
            flex-direction: column;
        }

        .overview-heading {
            font-weight: 500;
        }
    }
`;

const ContentLeftWrapper = styled.div`
    width: 55%;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

const ContentRightWrapper = styled.div`
    width: 40%;

    .carousel-heading {
        font-family: "Nunito sans", sans-serif !important;
        color: var(--carbon);
        margin-bottom: 16px;
        margin-bottom: 24px;
    }

    .carousel-desc {
        margin-bottom: 24px;
    }

    @media only screen and (max-width: 768px) {
        margin-top: 32px;
        width: 100%;
    }
`;

const RenderOverviewCards = (object_arr) => {
    let col_jsx = [];

    for (const [key, value] of object_arr) {
        let overview_jsx = (
            <Col className="gutter-row" md={12} xs={24} key={key}>
                <OverviewCard data={value} idx={key} />
            </Col>
        );

        col_jsx.push(overview_jsx);
    }

    return <Row gutter={[32, 32]}>{col_jsx}</Row>;
};

const CourseOverview = ({ overviewContent, overviewCarousel }) => {
    let overviewContent_list = Object.entries(overviewContent);
    let overviewCarousel_length = Object.entries(overviewCarousel).length;

    let classes = ["h1-heading-v2"];
    if (isMobile) classes = ["h4-heading-v2"];

    return (
        <OverviewContainer className="lr-pad-d lr-pad-m">
            <div className={`overview-heading ${classes[0]}`}>Program Overview</div>
            <div className="overview-key-details f-d f-v-c">
                <div className="red-border"></div>
                <div className="desc body-large-text"> KEY DETAILS</div>
            </div>

            <div className="content-details-wrap f-d f-h-sb">
                <ContentLeftWrapper>
                    {RenderOverviewCards(overviewContent_list.slice(0, 2))}
                    {RenderOverviewCards(overviewContent_list.slice(2))}
                </ContentLeftWrapper>

                {overviewCarousel_length > 0 ? (
                    <ContentRightWrapper>
                        <div className="carousel-heading h6-heading-v2">
                            {overviewCarousel.heading}
                        </div>
                        {/* <div className="carousel-desc body-normal-text">
                            {overviewCarousel.desc}
                        </div> */}
                        <CourseHeroCarousel content={overviewCarousel} type={"overview"} />
                    </ContentRightWrapper>
                ) : null}
            </div>
        </OverviewContainer>
    );
};

export default CourseOverview;
