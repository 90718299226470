import * as React from "react";

function SvgRupee(props) {
    return (
        <svg width={16} height={26} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M7.648 26L.844 14.12v-1.584h.792c1.92 0 3.384-.276 4.392-.828 1.032-.552 1.632-1.548 1.8-2.988H.844V6.416h6.948C7.552 5.072 6.916 4.1 5.884 3.5c-1.008-.6-2.424-.9-4.248-.9H.844V.296h14.904V2.6H8.944c1.152.936 1.836 2.208 2.052 3.816h4.752V8.72h-4.68c-.168 1.752-.84 3.12-2.016 4.104-1.152.96-2.688 1.572-4.608 1.836L11.32 26H7.648z"
                fill="#fff"
            />
        </svg>
    );
}

export default SvgRupee;
