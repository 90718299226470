import React, {Component} from "react";
import Layout from "../components/Layout";

export class Downloadables extends Component {
    render() {
        return (
            <Layout>
                <div className="downloadablesWrapper">
                    <iframe width="100%" height="750" src="https://mautic.faceprep.in/index.php/form/5">
                        <p>Your
                            browser does not support iframes.</p></iframe>
                </div>
                <style jsx>
                    {`
                        .downloadablesWrapper {
                            height: 100%;
                            overflow: "hidden";
                        }
                        `}
                </style>
            </Layout>
        );
    }
}

export default Downloadables;
