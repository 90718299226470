import { SET_PRACTICE_DATA, UPDATE_PRACTICE_DATA } from "../constants/constants";
const initialState = {
    cid: 0,
    pid: 0,
    topicName: "",
    practice_duration: 0,
    timerState: true,
    qTime: 0,
    question: {},
    skillStats: {},
    report: {},
    qUpdated: 0,
    zapMeter: {
        average_time: 0,
        minimum_time: 0,
        time_taken_by_user: 0,
    },
    zenMode: false,
};

const skillPracticeReducer = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case SET_PRACTICE_DATA:
            newState = action.payload;
            return (state = { ...state, ...newState });
        case UPDATE_PRACTICE_DATA:
            let updateData = action.payload;
            return { ...state, ...updateData };
        default:
            return state;
    }
};

export default skillPracticeReducer;
