import React from "react";
import { Col } from "antd";
import "./CourseFolder.css";
import FolderIcon from "../../Icons/Folder";
import { Link } from "react-router-dom";

const handleRewrite = topic => {
    topic = topic.replace(/ /g, "-");
    return topic.toLowerCase();
};
const CourseFolder = props => {
    return (
        <React.Fragment>
            <div className="align-bottom ant-col-md-4 ant-col-xs-6">
                {/* <Link
          to={`/videos/${handleRewrite(props.course.topic)}`}
          style={{ color: "black" }}
        > */}
                <Link
                    to={{
                        pathname: `/videos/${handleRewrite(props.course.topic)}`,
                        state: {
                            displayTopic: props.course.topic
                        }
                    }}
                    style={{ color: "black" }}
                >
                    <Col className="gutter-row">
                        <FolderIcon />
                    </Col>
                    <Col className="gutter-row topic">{props.course.topic}</Col>
                    <div className="match-titles">
                        {props.count &&
                            (props.count === "0"
                                ? ""
                                : `Contains ${props.count} matching titles`)}{" "}
                    </div>
                </Link>
            </div>
        </React.Fragment>
    );
};

export default CourseFolder;
