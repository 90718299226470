import { SET_STATS_DATA, SET_STATS_LOADING } from "../constants/constants";

const initialState = {
    loading: true,
    stats: {
        today_points: 0,
        total_points: 100,
        streak: {
            cur: 0,
            best: 0,
        },
        tag: {
            points: 0,
            level: 0,
            total_points: 100,
            name: "Aptitude",
        },
    },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_STATS_LOADING:
            return { ...state, loading: true };
        case SET_STATS_DATA:
            return { loading: false, stats: payload };
        default:
            return state;
    }
};
