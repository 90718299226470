import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import { isMobile } from "react-device-detect";

const FeaturesContainer = styled.div`
    padding-top: 64px;
    padding-bottom: 64px;
    background-color: #f5f6fa;

    .features-heading {
        font-family: "Nunito sans", sans-serif !important;
        color: var(--carbon);
        margin-bottom: 64px;
    }

    @media only screen and (max-width: 768px) {
        .features-heading {
            font-weight: 500;
        }
    }
`;

const FeaturesCardWrap = styled.div``;

const FeatureCard = styled.div`
    height: auto;
    min-height: 240px;
    padding: 32px;
    background-color: var(--dove);
    border-radius: 4px;
    box-shadow: var(--peaky-border);

    .feature-image {
        width: 56px;
        height: 56px;
        border-radius: 4px;
        margin-bottom: 32px;
    }

    .feature-desc {
        color: var(--carbon);
    }
`;

const RenderFeaturesCard = (content) => {
    let features_jsx = [];

    for (const [key, value] of Object.entries(content)) {
        let feature_card = (
            <Col key={key} xs={24} md={8}>
                <FeatureCard>
                    <div
                        className="feature-image bg-image-full"
                        style={{ backgroundImage: "url(" + value.featureImage + ")" }}
                    ></div>
                    <div className="feature-desc body-medium-text">{value.description}</div>
                </FeatureCard>
            </Col>
        );
        features_jsx.push(feature_card);
    }
    return features_jsx;
};

const CourseFeatures = ({ content }) => {
    let classes = ["h1-heading-v2"];
    if (isMobile) classes = ["h4-heading-v2"];

    return (
        <FeaturesContainer className="lr-pad-d lr-pad-m">
            <div className={`features-heading ${classes[0]}`}>This program comes with</div>
            <FeaturesCardWrap>
                <Row gutter={[32, 32]}>{RenderFeaturesCard(content)}</Row>
            </FeaturesCardWrap>
        </FeaturesContainer>
    );
};

export default CourseFeatures;
