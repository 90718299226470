import {
    CUR_REPORT_INSTANCEID,
    SET_ASSESS_DATA,
    SET_ASSESS_DRAWER,
    SET_ASSESS_TITLE,
    SET_ASSESS_CAT_TYPE,
    SET_REPORTS_DATA,
    TOGGLE_SHOW_REPORTS_DATA,
    SET_REPORT_LOADING,
    SHOW_ASSESS_REPORTS,
} from "../constants/constants";

let initalReportsData = [
    {
        test_wise: {
            total_time: 217,
            test_score: -1.9600000000000006,
            test_max_score: 15,
            duration: 1200,
            correct: 2,
            incorrect: 12,
            unanswered: 1,
            accuracy: 14,
        },
        section_wise: {
            "Section 1": {
                score: -1.9600000000000006,
                cutoff_passed: 0,
                time_spent: 217,
                avg_time_spent: 14.466666666666667,
                correct: 2,
                incorrect: 12,
                unanswered: 1,
                total_possible_score: 15,
                section_name: "Pipes and Cisterns",
                partial_correct: 0,
                accuracy: 0.14285714285714285,
                total_attempted: 14,
            },
            "Section 2": {
                score: 6.00000000000006,
                cutoff_passed: 0,
                time_spent: 217,
                avg_time_spent: 14.466666666666667,
                correct: 2,
                incorrect: 12,
                unanswered: 1,
                total_possible_score: 15,
                section_name: "Pipes and Cisterns",
                partial_correct: 0,
                accuracy: 0.14285714285714285,
                total_attempted: 14,
            },
        },
    },
    {
        test_wise: {
            total_time: 351,
            test_score: 7,
            test_max_score: 15,
            duration: 1200,
            correct: 7,
            incorrect: 0,
            unanswered: 8,
            accuracy: 100,
        },
        section_wise: {
            "Section 1": {
                score: 7,
                cutoff_passed: 1,
                time_spent: 351,
                avg_time_spent: 23.4,
                correct: 7,
                incorrect: 0,
                unanswered: 8,
                total_possible_score: 15,
                section_name: "Pipes and Cisterns",
                partial_correct: 0,
                accuracy: 1,
                total_attempted: 7,
            },
        },
    },
];
const initialState = {
    drawerOpen: false,
    loading: true,
    title: "",
    type: "",
    test_details: [],
    showReports: false,
    reportsData: initalReportsData,
    reportsLoading: true,
    currentInstanceId: null,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_ASSESS_DRAWER: {
            if (payload === false)
                return { ...state, drawerOpen: payload, loading: true, test_details: [], showReports: false };
            return { ...state, drawerOpen: payload };
        }
        case SET_ASSESS_TITLE:
            return { ...state, title: payload };
        case SET_ASSESS_CAT_TYPE:
            return { ...state, type: payload };
        case SET_ASSESS_DATA:
            return { ...state, test_details: payload, loading: false };
        case TOGGLE_SHOW_REPORTS_DATA:
            return { ...state, showReports: !state.showReports };
        case CUR_REPORT_INSTANCEID:
            return { ...state, currentInstanceId: payload };
        case SET_REPORTS_DATA:
            return { ...state, reportsData: payload };
        case SET_REPORT_LOADING:
            return { ...state, reportsLoading: payload };
        case SHOW_ASSESS_REPORTS:
            return { ...state, showReports: payload };
        default:
            return state;
    }
};
