import React, { useState, useEffect } from "react";

import "@egjs/react-flicking/dist/flicking.css";

import axios from "axios";
import { G_API_URL } from "../../../constants/constants";
import { __getToken } from "../../../utils/user-details.util";
import CoursesCarousel from "./CoursesCarousel";

const Courses = ({}) => {
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        let config = { headers: { Authorization: __getToken() } };

        axios.get(`${G_API_URL}resource/programs/facepro-dashboard`, config).then((res) => {
            if (res.data && res.data.status && res.data.data) {
                setCourses(res.data.data.courses);
            }
        });
    }, []);

    // console.log(flickingRef);

    const renderCourses = () => {
        return courses.map((course, idx) => {
            return (
                <div className="course-type-container" key={`course-contanier-${course.title}-${idx}`}>
                    <h2 className="h2-heading course-category-title">{course.title}</h2>
                    <div className="course-child-container f-d">
                        <CoursesCarousel course={course} />
                    </div>
                </div>
            );
        });
    };

    return (
        <>
            <div className="courses-container">{renderCourses()}</div>
            <style jsx={"true"}>{`
                .course-child-container {
                    gap: 24px;
                }

                .course-type-container {
                    margin-bottom: 4rem;
                }

                .flicking-arrow-prev {
                    left: 10px;
                    background-position: -57px -77px;
                }

                .flicking-arrow-next {
                    right: 10px;
                    background-position: -228px -77px;
                }

                .flicking-arrow-next,
                .flicking-arrow-prev {
                    cursor: pointer;
                    height: 64px;
                    position: absolute;
                    transform: translateY(-50%);
                    width: 64px;
                    z-index: 2;
                    background-size: 315px 273px;
                    position: absolute;
                    top: 42%;
                    width: 56px;
                    height: 56px;
                    border-radius: 50%;
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px 0px;
                }

                thin:before {
                    height: 3px;
                }

                .flicking-arrow-next.is-thin:after,
                .flicking-arrow-next.is-thin:before,
                .flicking-arrow-prev.is-thin:after,
                .flicking-arrow-prev.is-thin:before {
                    height: 3px;
                }

                .flicking-arrow-next.is-thin:after,
                .flicking-arrow-prev.is-thin:after {
                    top: calc(50% - 2px);
                }

                @media only screen and (max-device-width: 768px) {
                    .course-type-container .course-category-title {
                        font-size: 20px;
                    }
                }
            `}</style>
        </>
    );
};

export default Courses;
