import React from 'react';
import SubscriptionTick from "../../assets/imgs/subscription-tick.svg";
const ModuleAccessibility = ({idx,isAccessbile}) => {
    return (
        <>
            <div className={`tr-col g-d g-h-c g-v-c module-accessiblity-wrapper${idx===0?' tr-odd-col':' tr-even-col'}`}>
                {   
                    isAccessbile &&
                    <img src={SubscriptionTick} alt="accessible" className="module-accessible"/>
                }
            </div>
            <style jsx={'true'}>{`
                .module-accessible {
                    height:1.2rem;
                    width:1.2rem;
                }
            `}</style>
        </>
    )
}

export default ModuleAccessibility;