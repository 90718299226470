import * as React from "react";

function SvgWhatsapp(props) {
    return (
        <svg width={15} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M2.042 13.804l.91-3.016 2.393 2.154-3.303.862z" fill="#000" className="fill" />
            <path
                d="M14.248 7.964a6.318 6.318 0 11-12.637 0 6.318 6.318 0 0112.637 0z"
                fill="#000"
                className="fill"
            />
            <path
                d="M4.773 12.836l.214.127a5.833 5.833 0 002.976.816h.002c3.223 0 5.846-2.625 5.847-5.852a5.819 5.819 0 00-1.71-4.14 5.806 5.806 0 00-4.135-1.716 5.858 5.858 0 00-4.955 8.965l.139.222-.59 2.16 2.212-.582zM.871 15.09l.998-3.648a7.034 7.034 0 01-.939-3.52C.932 4.04 4.087.882 7.965.882c1.882 0 3.648.734 4.976 2.065A7.002 7.002 0 0115 7.927c-.002 3.882-3.158 7.04-7.035 7.04h-.003a7.024 7.024 0 01-3.362-.856l-3.729.979z"
                fill="#000"
                className="fill"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.242 4.998c-.132-.293-.27-.3-.395-.305-.103-.004-.22-.004-.337-.004a.645.645 0 00-.469.22c-.161.177-.615.602-.615 1.468s.63 1.702.717 1.82c.088.117 1.216 1.95 3.003 2.656 1.485.586 1.787.47 2.11.44.322-.029 1.04-.425 1.186-.836.146-.411.146-.763.102-.837-.043-.073-.16-.117-.337-.205-.175-.088-1.04-.514-1.2-.572-.162-.06-.279-.088-.396.088-.117.176-.454.572-.557.69-.102.117-.205.131-.38.044-.176-.089-.742-.274-1.414-.874a5.304 5.304 0 01-.978-1.218c-.102-.176-.01-.272.077-.36.08-.078.176-.205.264-.308.088-.102.117-.176.176-.293.058-.118.029-.22-.015-.308s-.386-.959-.542-1.306z"
                fill="#fff"
            />
        </svg>
    );
}

export default SvgWhatsapp;
