import React, { useState } from "react";
import { Divider, Drawer, Icon, Menu } from "antd";
import brand_logo from "../../assets/icons/svg_icons/logo_web.svg";
import { Link } from "react-router-dom";
import LiveClassroom from "../header/LiveClassroom";
import { G_URL } from "../../constants/constants";

const MobileNav = ({ renderNavItems, login_status, showLivezone, logout_user, isNMLive }) => {
    const [visible, setVisible] = useState(false);

    const onClose = () => {
        setVisible(false);
    };

    const showDrawer = () => {
        setVisible(true);
    };

    return (
        <>
            <div className="mobile-nav-btn hide-d" onClick={showDrawer}>
                <Icon type="menu" />
            </div>
            <Drawer
                title={null}
                placement="left"
                closable={false}
                onClose={onClose}
                visible={visible}
                className="mobile-nav"
            >
                <div className={`drawer-header f-d f-vt f-h-e lr-mobile-nav-pad `}>
                    <Link to={`${process.env.PUBLIC_URL}/`} className={`${login_status ? "hide-m" : ""}`}>
                        <div
                            className="brand-logo bg-image-full c-pointer"
                            style={{
                                backgroundImage: `url(${brand_logo})`,
                            }}
                        />
                    </Link>
                    {login_status && (
                        <Menu mode="inline" inlineIndent={0} style={{ borderRight: "unset" }}>
                            <Menu.SubMenu key={"user-menu"} title={<span>My Account</span>}>
                                <Menu.Item key="9">
                                    <div
                                        className="menu-item"
                                        onClick={() => (window.location.href = `${G_URL}profile/`)}
                                    >
                                        Profile
                                    </div>
                                </Menu.Item>
                                <Menu.Item key="10">
                                    {" "}
                                    <div
                                        className="menu-item"
                                        onClick={() => (window.location.href = `${G_URL}change-password/`)}
                                    >
                                        Change Password
                                    </div>
                                </Menu.Item>
                                <Menu.Item key="11">
                                    <div
                                        className="menu-item logout-btn"
                                        onClick={logout_user}
                                        style={{ color: "var(--facered)" }}
                                    >
                                        Logout
                                    </div>
                                </Menu.Item>
                            </Menu.SubMenu>
                        </Menu>
                    )}
                </div>
                <Divider className="divider" />
                <div className="nav-links-conatiner lr-mobile-nav-pad f-d f-vt">
                    {renderNavItems()}
                    <Menu mode="inline" inlineIndent={0} style={{ borderRight: "unset" }}>
                        <Menu.SubMenu key={"user-menu"} title={<span>Archived courses</span>}>
                            <Menu.Item key="12">
                                <div
                                    className="menu-item"
                                    onClick={() => (window.location.href = `${G_URL}company-corner/`)}
                                >
                                    Company Corner
                                </div>
                            </Menu.Item>
                            <Menu.Item key="13">
                                {" "}
                                <div
                                    className="menu-item"
                                    onClick={() => (window.location.href = `${G_URL}self-paced-courses/`)}
                                >
                                    Self-paced courses
                                </div>
                            </Menu.Item>
                            <Menu.Item key="14">
                                {" "}
                                <div
                                    className="menu-item"
                                    onClick={() => (window.location.href = `${G_URL}skillzone/`)}
                                >
                                    Skill Zone
                                </div>
                            </Menu.Item>
                        </Menu.SubMenu>
                    </Menu>

                    {isNMLive && (
                        <div className="menu-item" onClick={() => (window.location.href = `${G_URL}nm/live/`)}>
                            NM Live
                        </div>
                    )}

                    {!login_status && (
                        <div className="menu-item" onClick={() => (window.location.href = `${G_URL}login/`)}>
                            Login / Sign Up
                        </div>
                    )}
                    {showLivezone && <LiveClassroom />}
                </div>
            </Drawer>
            <style jsx={"true"}>
                {`
                    @media only screen and (max-device-width: 760px) {
                        .mobile-nav-btn {
                            position: absolute;
                            left: 16px;
                        }

                        .mobile-nav .ant-drawer-body {
                            height: 100%;
                            padding: 24px 0;
                        }

                        .drawer-header {
                            min-height: 20%;
                        }

                        .drawer-header .brand-logo {
                            width: 140px;
                            height: 18px;
                        }

                        .lr-mobile-nav-pad {
                            padding: 0px 24px;
                        }

                        .mobile-nav .divider {
                            margin: 32px 0 !important;
                        }

                        .mobile-nav .nav-links-conatiner a {
                            width: max-content;
                        }

                        .liveclassroom-btn {
                            width: 70% !important;
                        }
                    }
                `}
            </style>
        </>
    );
};

export default MobileNav;
