import React, { Component } from 'react';
import Spinner from "../../components/Spinner/spinner";
import {logout_user} from "../../utils/login.util";
class SSOLogout extends Component {

    componentDidMount = () => {
        setTimeout(() => {
            logout_user();
        },2000);
    }

    render() {
        return (
            <>
                <div className="f-d f-vt f-v-c f-h-c logout-content-wrapper">
                    <Spinner />
                    <h3 className="h3-heading">Please wait while we sign you off...</h3>
                </div>
                <style jsx={"true"}>{`
                    .logout-content-wrapper {
                        height:100vh;
                    }
                    .logout-content-wrapper .lds-ring {
                        margin:0;
                    }
                `}</style>
            </>
        )
    }
}

export default SSOLogout;