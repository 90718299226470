import React from "react";
import styled from "styled-components";
import { SkeltonCodeSquare, SkeltonRow } from "../Skelton/skelton";

const HeroSkeltonWrapper = styled.div`
    height: auto;
    width: 100%;
    background-color: var(--bg-black);
    padding-top: 128px;
    padding-bottom: 128px;
    min-height: 80vh;

    .hero-left-container {
        width: 65%;
        .ant-skeleton-title {
            width: 50% !important;
            height: 50px !important;
        }
    }

    .hero-right-container {
        width: 30%;

        .ant-skeleton-header {
            width: 100%;
        }
    }
`;

const CourseHeroSkelton = () => {
    return (
        <HeroSkeltonWrapper className="lr-pad-d lr-pad-m">
            <div className="f-d f-h-sb">
                <div className="hero-left-container">
                    <SkeltonRow title={true} rows={0} />
                    <SkeltonRow title={false} rows={10} />
                </div>
                <div className="hero-right-container">
                    <SkeltonCodeSquare width={"100%"} height={"400px"} />
                </div>
            </div>
        </HeroSkeltonWrapper>
    );
};

const OverviewSkeltonWrapper = styled.div`
    padding-top: 64px;
    padding-bottom: 64px;

    .ant-skeleton-header {
        width: 100%;
    }

    .overview-heading {
        .ant-skeleton-title {
            width: 50% !important;
            height: 50px !important;
        }
        margin-bottom: 32px;
    }

    .overview-left-wrapper {
        width: 55%;
        flex-wrap: wrap;

        .overview-card {
            width: 50%;
            margin-bottom: 16px;
        }
    }

    .overview-right-wrapper {
        width: 35%;
    }
`;

const CourseOverviewSkelton = () => {
    return (
        <OverviewSkeltonWrapper className="lr-pad-d lr-pad-m">
            <div className="overview-heading">
                <SkeltonRow title={true} rows={1} />
            </div>
            <div className="f-d f-h-sb">
                <div className="overview-left-wrapper f-d">
                    <div className="overview-card">
                        <SkeltonCodeSquare width={"100%"} height={"200px"} />
                    </div>
                    <div className="overview-card">
                        <SkeltonCodeSquare width={"100%"} height={"200px"} />
                    </div>
                    <div className="overview-card">
                        <SkeltonCodeSquare width={"100%"} height={"200px"} />
                    </div>
                    <div className="overview-card">
                        <SkeltonCodeSquare width={"100%"} height={"200px"} />
                    </div>
                </div>
                <div className="overview-right-wrapper">
                    <SkeltonCodeSquare width={"100%"} height={"400px"} />
                </div>
            </div>
        </OverviewSkeltonWrapper>
    );
};

const FeaturesSkeltonWrapper = styled.div`
    padding-top: 64px;
    padding-bottom: 64px;

    background-color: #f5f6fa;

    .ant-skeleton-title {
        width: 50% !important;
        height: 50px !important;
    }

    .ant-skeleton-header {
        width: 100%;
    }

    .feature-card-container {
        flex-wrap: wrap;
        margin-top: 32px;

        .feature-card {
            width: 30%;
            margin-bottom: 16px;
        }
    }
`;

const CourseFeaturesSkelton = () => {
    return (
        <FeaturesSkeltonWrapper className="lr-pad-d lr-pad-m">
            <div className="feature-heading">
                <SkeltonRow title={true} />
            </div>
            <div className="feature-card-container f-d f-h-sb">
                <div className="feature-card">
                    <SkeltonCodeSquare width={"100%"} height={"200px"} />
                </div>
                <div className="feature-card">
                    <SkeltonCodeSquare width={"100%"} height={"200px"} />
                </div>
                <div className="feature-card">
                    <SkeltonCodeSquare width={"100%"} height={"200px"} />
                </div>
                <div className="feature-card">
                    <SkeltonCodeSquare width={"100%"} height={"200px"} />
                </div>
                <div className="feature-card">
                    <SkeltonCodeSquare width={"100%"} height={"200px"} />
                </div>
                <div className="feature-card">
                    <SkeltonCodeSquare width={"100%"} height={"200px"} />
                </div>
            </div>
        </FeaturesSkeltonWrapper>
    );
};

const LearnSkeltonWrapper = styled.div`
    height: auto;
    width: 100%;
    background-color: var(--bg-black);
    padding-top: 128px;
    padding-bottom: 128px;
    min-height: 80vh;

    .ant-skeleton-title {
        width: 50% !important;
        height: 50px !important;
    }

    .ant-skeleton-header {
        width: 100%;
    }

    .learn-box .learn-card {
        width: 50%;
        margin-top: 32px;
    }
`;

const CourseLearnSkelton = () => {
    return (
        <LearnSkeltonWrapper className="lr-pad-d lr-pad-m">
            <div className="learn-heading">
                <SkeltonRow title={true} rows={1} />
            </div>
            <div className="learn-box">
                <div className="learn-card">
                    <SkeltonCodeSquare width={"100%"} height={"80px"} />
                </div>
                <div className="learn-card">
                    <SkeltonCodeSquare width={"100%"} height={"80px"} />
                </div>
                <div className="learn-card">
                    <SkeltonCodeSquare width={"100%"} height={"80px"} />
                </div>
                <div className="learn-card">
                    <SkeltonCodeSquare width={"100%"} height={"80px"} />
                </div>
            </div>
        </LearnSkeltonWrapper>
    );
};

export { CourseHeroSkelton, CourseOverviewSkelton, CourseFeaturesSkelton, CourseLearnSkelton };
