import React from "react";
import styled from "styled-components";

import instagram from "../../assets/icons/social/instagram.svg";
import linkedin from "../../assets/icons/social/linkedin.svg";
import youtube from "../../assets/icons/social/youtube.svg";

const StyledFollowUs = styled.div`
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 65px;

    .follow-us {
        background: rgba(0, 0, 0, 0.64);
        backdrop-filter: blur(40px);

        padding: 16px 7px;

        .follow-us-text {
            font-family: "Nunito sans", sans-serif;
            color: var(--dove);
        }
    }

    @media only screen and (max-width: 768px) {
        width: 60px;

        .follow-us {
            padding: 8px 7px;
        }
    }
`;

const getImage = (i) => {
    switch (i) {
        case "instagram":
            return instagram;
        case "linkedin":
            return linkedin;
        case "youtube":
            return youtube;
        default:
            return "";
    }
};

const platforms = [
    {
        platform: "instagram",
        url: "https://www.instagram.com/faceprep",
    },
    {
        platform: "linkedin",
        url: "https://www.linkedin.com/company/faceprep/",
    },
    {
        platform: "youtube",
        url: "https://www.youtube.com/faceprep",
    },
];

const generatePlatform = (platforms) => {
    return platforms.map((i, idx) => (
        <a
            key={idx}
            href={i.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`p-icon-container ${i.platform} f-d f-v-c f-h-c`}
        >
            <div
                className="platform-img bg-image-full"
                style={{
                    backgroundImage: `url('${getImage(i.platform)}')`,
                }}
            />
        </a>
    ));
};

const FollowUs = () => {
    return (
        <>
            <StyledFollowUs>
                <div className="follow-us">
                    <p className="body-normal-text follow-us-text">Follow us on: </p>
                    <div className="follow-us-links f-d f-vt f-v-c">{generatePlatform(platforms)}</div>
                </div>
            </StyledFollowUs>
            <style jsx={"true"}>{`
                .follow-us-links {
                    gap: 16px;
                }

                .follow-us-links .p-icon-container {
                    width: 40px;
                    height: 40px;
                    background-color: var(--smoke);
                    border-radius: var(--peaky-br-full);
                }

                .follow-us-links .platform-img {
                    width: 20px;
                    height: 20px;
                }

                .p-icon-container.instagram {
                    background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
                }

                .p-icon-container.linkedin {
                    background-color: #0072b1;
                }

                .p-icon-container.youtube {
                    background-color: #ff0000;
                }

                @media only screen and (max-width: 768px) {
                    .follow-us-links .p-icon-container {
                        width: 32px;
                        height: 32px;
                    }

                    .follow-us-links .platform-img {
                        width: 16px;
                        height: 16px;
                    }
                }
            `}</style>
        </>
    );
};

export default FollowUs;
