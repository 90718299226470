import { Button } from "antd";
import React from "react";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import displayEnrollBtn from "../../utils/Prograd/bootcamp.utils";
import SvgRupee from "../Svgs/SvgRupee";
import CourseHeroCarousel from "./CourseCarousel";

const CourseHeroContainer = styled.div`
    height: auto;
    width: 100%;
    background-color: var(--bg-black);
    color: var(--dove);
    padding-top: 128px;
    padding-bottom: 128px;
    min-height: 80vh;

    @media only screen and (max-width: 768px) {
        padding-top: 64px;
        padding-bottom: 64px;
        display: block !important;
    }
`;

const CourseLeftContainer = styled.div`
    @media only screen and (max-width: 768px) {
        width: 100%;

        .course-title {
            font-weight: 500 !important;
        }
    }

    width: 55%;

    .course-title {
        font-family: "Nunito sans", sans-serif !important;
        font-weight: 300;
    }

    .course-desc {
        margin-top: 32px;
        margin-bottom: 32px;
        color: var(--dove);
    }

    .course-price-wrap {
        font-family: "Nunito sans", sans-serif !important;
        font-weight: 300;
    }

    .course-price-wrap .strike-off {
        text-decoration: line-through;
        margin-left: 8px;
        color: var(--granite);
    }

    .course-price-wrap .actual-price {
        margin-left: 8px;
    }

    .btn-group {
        margin-top: 32px;
    }

    .btn-group .course-enroll-btn {
        height: 64px;
    }

    .enroll-btn {
        height: 56px;
        width: 200px;
    }

    .btn-group .preview-btn {
        height: 56px;
        width: 200px;
        background-color: var(--dove);
        color: var(--carbon);
        border: none;
        text-transform: uppercase;
    }
`;

const CourseRightContainer = styled.div`
    width: 35%;

    @media only screen and (max-width: 768px) {
        margin-top: 64px;
        width: 100%;
    }
`;

const CourseHero = (props) => {
    const { header, desciption, heroImpDetails } = props.heroContent;
    const {
        PaymentHandler,
        active_batch_id,
        internationalUser,
        isLogin,
        paymentStatus,
        RedirectToLogin,
    } = props;

    let price_block = heroImpDetails["details_2"]["data"].split("_");

    let classes = ["h1-heading-v2"];
    if (isMobile) classes = ["h4-heading-v2"];

    return (
        <CourseHeroContainer className="lr-pad-d lr-pad-m f-d f-h-sb">
            <CourseLeftContainer>
                <div className={`course-title ${classes[0]}`}>{header}</div>
                <div className="course-desc body-large-text">{desciption}</div>
                <div className="course-price-wrap f-d f-v-c">
                    <SvgRupee />
                    <div className="strike-off h3-heading-v2">{price_block[0]}</div>
                    <div className="actual-price h3-heading-v2">{price_block[1]}</div>
                </div>
                <div className="btn-group f-d">
                    {displayEnrollBtn(
                        isLogin,
                        RedirectToLogin,
                        paymentStatus,
                        PaymentHandler,
                        internationalUser,
                        active_batch_id
                    )}
                    <Button className="preview-btn">Preview Course</Button>
                </div>
            </CourseLeftContainer>
            <CourseRightContainer>
                <CourseHeroCarousel content={props.testimonialsContent} />
            </CourseRightContainer>
        </CourseHeroContainer>
    );
};

export default CourseHero;
