import { SET_SKILL_PLANS, SKILL_PLANS_LOADING } from "../constants/constants";

const initialState = {
    loading: true,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_SKILL_PLANS:
            return { ...state, ...payload };
        case SKILL_PLANS_LOADING:
            return { ...state, loading: payload };
        default:
            return state;
    }
};
