import React, {Component} from 'react';
import {isMobile} from "react-device-detect";
import {Carousel} from "antd";

Carousel.defaultProps = {
    dots: true,
    draggable: true,
    arrows: false,
    autoplaySpeed: 4000
};

class PromoContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            imgSrc: this.props.promoContent[0].img,
            imgKey: 1
        };
    }


    componentDidMount() {
        setInterval(this.handleCardChange, 10000)
    }

    handleCardChange = (prevKey = parseInt(this.state.imgKey), key = parseInt(this.state.imgKey) + 1) => {
        if (this.props.promoContent[key - 1] === undefined)
            key = 1;

        if (document.getElementsByClassName("card" + prevKey)[0] !== undefined)
            document.getElementsByClassName("card" + prevKey)[0].classList.remove("promo-active-card");
        if (document.getElementsByClassName("card" + key)[0] !== undefined)
            document.getElementsByClassName("card" + key)[0].classList.add("promo-active-card");

        this.setState({imgSrc: this.props.promoContent[key - 1].img, imgKey: key})
    }

    renderCard(content) {
        return Object.values(content).map((item, i) => {
            let activeClass = "";
            if (item.key === 1)
                activeClass = "promo-active-card";
            return (
                <div className="card-container f-d" onClick={() => {
                    this.handleCardChange(parseInt(this.state.imgKey), parseInt(item.key))
                }} key={"PR" + item.key}>
                    <div className={`promo-card card${item.key} ${activeClass} f-d f-vt`}>
                        <span className="body-regular">{item.text}</span>
                    </div>
                </div>
            )
        })
    }

    renderCrouselCard(content) {
        return Object.values(content).map((item, i) => {
            return (
                <div className="card-container f-d" key={"PR" + item.key}>
                    <img className="img-holder" alt="" src={item.img}/>
                    <div className={`promo-active-card f-d f-vt`}>
                        <span className="body-regular">{item.text}</span>
                    </div>
                </div>
            )
        })
    }

    render() {
        const {promoContent} = this.props;
        return (
            <>
                {
                    isMobile ?
                        <div className="promo-card-crousel">
                            <h2 className="h2-heading">What you’ll get</h2>
                            <Carousel autoplay>
                                {this.renderCrouselCard(promoContent)}
                            </Carousel>
                        </div>
                        :
                        <div className="promo-card-wrapper f-d f-vt">
                            <h2 className="h2-heading">What you’ll get</h2>
                            <div className="promo-content-wrapper f-d f-ht">
                                <img className="img-holder" src={this.state.imgSrc} alt="" />
                                <div className="card-wrapper f-d f-vt">
                                    {
                                        this.renderCard(promoContent)
                                    }
                                </div>
                            </div>
                        </div>}

                <style jsx>
                    {`
                     .promo-card-wrapper .img-holder{
                        height: 400px;
                        width: 450px;
                        padding: 10px;
                     }
                     .promo-active-card{
                        border-bottom: solid 3px var(--facered)!important;
                     }
                     .promo-card-crousel .promo-active-card,.promo-card-wrapper .promo-card{
                        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
                        border: solid 1px #e4e4e4;
                        padding: 30px;
                        width: 100%;
                        align-items: center;
                        cursor: pointer;
                     }
                     .card-wrapper{
                        width: 40%;
                     }
                     .promo-content-wrapper{
                        margin-top: 40px;
                        justify-content: space-between;
                     }
                     .card-container{
                        padding-top: 10px;
                        padding-bottom: 10px;
                     }
                
                     .promo-card-crousel .img-holder{
                        height: 300px;
                        width: 100%;
                        padding: 10px;
                     }
                     .ant-carousel .slick-dots li button {
                        background-color: var(--granite);                       
                    }
                     .ant-carousel .slick-dots li.slick-active button {
                        background-color: var(--facered);                       
                    }
                    .ant-carousel .slick-dots-bottom{
                        bottom: 0px;
                    }
                    `}
                </style>
            </>
        );

    }
}

export default PromoContent;