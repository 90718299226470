import React, { useEffect } from "react";
import Header from "./headerV2/Header";
import Footer from "./footerv2/Footer";
import { check_login } from "../utils/login.util";
import axios from "axios";
import { G_API_URL, G_COOKIE_PREFIX } from "../constants/constants";
import { __getToken } from "../utils/user-details.util";
import { useHistory } from "react-router-dom";
import { __getCookie, __setCookie } from "../utils/cookie.util";

const Layout = ({ children, footer = true, from = null }) => {
    const history = useHistory();

    useEffect(() => {
        if (check_login()) {
            const verify_cookie = __getCookie(`${G_COOKIE_PREFIX}isVerified`);
            if (verify_cookie.cookieExists === false)
                axios.get(`${G_API_URL}user/check/status`, { headers: { Authorization: __getToken() } }).then((res) => {
                    if (res.data && res.data.status === 0)
                        history.replace(`/user-details?rurl=${history.location.pathname}`);
                    else __setCookie(G_COOKIE_PREFIX + "isVerified", "1", 1, "month");
                });
        }
    }, []);

    return (
        <>
            <Header from={from} />
            {children}
            {footer && <Footer />}
        </>
    );
};

export default Layout;
