import React, { Component, useRef } from "react";
import { Form, Input, InputNumber, message, Radio, Select, Upload, Button } from "antd";
import axios from "axios";
import "./formInput.css";
import * as Constants from "../../constants/constants";
import { G_API_URL } from "../../constants/constants";

const { Option } = Select;

// Input Filed Ant-Design

class FormInput extends Component {
    state = {
        to_display: false,
        value2: "online",
        value3: "",
    };

    getInitialValue = () => {
        if (this.props.initialValue) {
            return this.props.initialValue;
        } else {
            return null;
        }
    };

    onChange = (e) => {
        let city = e.target.value;
        city = city.toLowerCase();
        if (city === "bengaluru" || city === "bangalore" || city === "hyderabad") {
            this.setState({ to_display: true });
        } else {
            if (this.state.to_display) {
                this.setState({ to_display: false });
                this.props.revertLocation();
            }
        }
    };

    // centerRadioChange = e => {
    //     this.setState({
    //         value2: e.target.value
    //     });
    // };

    testModeRadioChange = (e) => {
        this.setState({
            value3: e.target.value,
        });
    };

    netSpeed = (getFieldDecorator) => {
        const options = [
            { label: "Yes, I have", value: "yes" },
            { label: "No, I Don't", value: "no" },
        ];

        if (this.state.value2 === "Online") {
            return (
                <>
                    <div className="centre-selection-info">
                        Do you have access to a stable/reliable internet connection with a minimum speed of 1 Mbps?
                    </div>

                    <Form.Item colon={false} required={false}>
                        {getFieldDecorator(
                            `net_speed`,
                            {
                                initialValue: "no",
                            },

                            {
                                rules: [{ required: false, message: { message } }],
                            }
                        )(<Radio.Group options={options} onChange={this.testModeRadioChange} />)}
                    </Form.Item>
                </>
            );
        }
    };

    displayAtCentre = (name, getFieldDecorator) => {
        const options = [
            { label: "Online", value: "Online" },
            { label: "At the Centre", value: "At the Centre" },
        ];

        if (name === "location" && this.state.to_display) {
            return (
                <>
                    <div className="centre-selection-info">
                        You can take this program from our centre in your city too. Please let us know your preference.
                        Know more
                    </div>

                    <Form.Item colon={false} required={false}>
                        {getFieldDecorator(
                            `test_location`,
                            {
                                initialValue: "Online",
                            },
                            {
                                rules: [{ required: false, message: { message } }],
                            }
                        )(<Radio.Group options={options} onChange={this.props.centerRadioChange} />)}
                    </Form.Item>
                </>
            );
        }
    };

    render() {
        const { getFieldDecorator } = this.props;
        const { message, type, name, label, placeholder, isRequired, hideStar } = this.props;

        let classes = "";

        if (!hideStar) {
            if (isRequired) {
                classes = "l-star";
            } else {
                classes = "no-l-star";
            }
        } else {
            classes = "no-l-star";
        }

        return (
            <>
                <label htmlFor={name} className={classes}>
                    {label}
                </label>
                <Form.Item colon={false} required={false}>
                    {getFieldDecorator(`${name}`, {
                        initialValue: this.getInitialValue(),
                        rules: [{ type: type }, { required: isRequired, message: { message } }],
                    })(
                        <div>
                            <Input
                                className="form-input-no-icon"
                                onChange={this.onChange}
                                defaultValue={this.getInitialValue()}
                                disabled={this.props.disabled}
                                id={name}
                                placeholder={placeholder}
                            />
                        </div>
                    )}
                </Form.Item>

                {this.displayAtCentre(name, getFieldDecorator)}
                {this.netSpeed(getFieldDecorator)}
            </>
        );
    }
}

// Radio Button ANT DESIGN

class FormInputRadio extends Component {
    state = {
        value2: "Yes",
        visible: false,
    };

    onChange2 = (e) => {
        this.setState({
            value2: e.target.value,
            visible: e.target.value === "Yes",
        });
    };

    render() {
        const options = [
            { label: "Yes", value: "Yes" },
            { label: "No", value: "No" },
        ];

        const { getFieldDecorator } = this.props;
        const { message, name, label, placeholder, getResponse, responseLabel, respName } = this.props;

        return (
            <>
                {label !== "" ? (
                    <label htmlFor={name} className="l-star">
                        {label}
                    </label>
                ) : (
                    ""
                )}
                <Form.Item colon={false} required={false}>
                    {getFieldDecorator(`${name}`, {
                        rules: [{ required: true, message: { message } }],
                    })(<Radio.Group options={options} onChange={this.onChange2} />)}
                </Form.Item>
                {getResponse && this.state.value2 && this.state.visible ? (
                    <>
                        {responseLabel !== "" ? <label className="l-star">{responseLabel}</label> : ""}
                        <Form.Item colon={false} required={false}>
                            {getFieldDecorator(`${respName}`, {
                                rules: [{ required: true, message: { message } }],
                            })(<Input placeholder={placeholder} />)}
                        </Form.Item>
                    </>
                ) : (
                    ""
                )}
            </>
        );
    }
}

const FormInputMobile = (props) => {
    const prefixSelector = props.getFieldDecorator("prefix", {
        initialValue: "91",
    })(
        <Select style={{ width: 70 }}>
            <Option value="91">+91</Option>
            <Option value="1">+1</Option>
            <Option value="852">+852</Option>
            <Option value="61">+61</Option>
            <Option value="65">+65</Option>
            <Option value="92">+92</Option>
            <Option value="94">+94</Option>
            <Option value="971">+971</Option>
            <Option value="975">+975</Option>
            <Option value="880">+880</Option>
            <Option value="977">+977</Option>
            <Option value="93">+93</Option>
            <Option value="230">+230</Option>
            <Option value="60">+60</Option>
            <Option value="66">+66</Option>
            <Option value="33">+33</Option>
            <Option value="34">+34</Option>
        </Select>
    );
    const { getFieldDecorator } = props;
    const { message, name, label, placeholder, defaultValue } = props;
    const validatePassword = (rule, value, callback) => {
        if (value && isNaN(value)) {
            callback("Invalid number entered!");
        } else {
            callback();
        }
    };
    const inputElement = useRef();

    const getInitialValue = () => {
        if (props.initialValue) {
            return props.initialValue;
        } else {
            return null;
        }
    };

    let inputProps = {};
    if (props.suffix) inputProps = { ...inputProps, suffix: props.suffix };

    return (
        <>
            <label htmlFor={name} className="l-star">
                {label}
            </label>
            <Form.Item htmlFor={name} colon={false} required={false} className="mobile-input">
                {getFieldDecorator(`${name}`, {
                    initialValue: getInitialValue(),
                    rules: [
                        { validator: validatePassword },
                        {
                            required: true,
                            message: { message },
                        },
                    ],
                })(
                    <div className="mobile-input-div">
                        <Input
                            id={name}
                            maxLength={10}
                            disabled={props.disabled}
                            placeholder={placeholder}
                            addonBefore={prefixSelector}
                            className="mobile-input-box"
                            ref={inputElement}
                            defaultValue={getInitialValue()}
                            {...inputProps}
                        />
                    </div>
                )}
            </Form.Item>
        </>
    );
};

// Years Select Dropdown

let getYear = () => {
    let finalYear = new Date().getFullYear() + 10;
    let yearsArray = [];
    let initialYear = 1980;
    for (var i = initialYear; i <= finalYear; i++) {
        yearsArray.push(initialYear++);
    }

    return yearsArray;
};

const FormInputYears = (props) => {
    const { getFieldDecorator } = props;
    const { message, name, label, defaultValue } = props;

    return (
        <>
            <label htmlFor={name} className="l-star">
                {label}
            </label>

            <Form.Item htmlFor={name} colon={false} required={false}>
                {getFieldDecorator(`${name}`, {
                    initialValue: defaultValue,
                    rules: [
                        {
                            required: true,
                            message: { message },
                        },
                    ],
                })(
                    <Select
                        placeholder="Select a year"
                        optionFilterProp="children"
                        initialValue={defaultValue}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {getYear().map((item, index) => {
                            return (
                                <Option key={index + 1} value={item}>
                                    {item}
                                </Option>
                            );
                        })}
                    </Select>
                )}
            </Form.Item>
        </>
    );
};

// Password Input Field

const FormInputPswd = (props) => {
    const { getFieldDecorator } = props;
    const { message, name, label, placeholder, isRequired, getInValue } = props;

    let classes = "";

    if (isRequired) {
        classes = "l-star";
    } else {
        classes = "no-l-star";
    }

    return (
        <>
            <label htmlFor={name} className={classes}>
                {label}
            </label>
            <Form.Item htmlFor={name} colon={false} required={false}>
                {getFieldDecorator(`${name}`, {
                    rules: [
                        {
                            required: true,
                            message: { message },
                        },
                    ],
                })(<Input.Password id={name} placeholder={placeholder} onChange={getInValue} />)}
            </Form.Item>
        </>
    );
};

const FormTextArea = (props) => {
    const { getFieldDecorator } = props;
    const { message, name, label, placeholder, getInValue, isRequired } = props;

    let classes = "";

    if (isRequired) {
        classes = "l-star";
    } else {
        classes = "no-l-star";
    }

    return (
        <>
            <label htmlFor={name} className={classes}>
                {label}
            </label>
            <Form.Item htmlFor={name} colon={false} required={false}>
                {getFieldDecorator(`${name}`, {
                    rules: [
                        {
                            required: isRequired,
                            message: { message },
                        },
                    ],
                })(<Input.TextArea rows={4} placeholder={placeholder} onChange={getInValue} />)}
            </Form.Item>
        </>
    );
};

// Dropdown Field Component (Pure Functional)

const FormInputDropDown = (props) => {
    const { getFieldDecorator } = props;
    const { message, name, label, values, defaultValue } = props;
    let disabled = false;
    if (props.disabled) disabled = true;

    return (
        <>
            <label htmlFor={name} className="l-star">
                {label}
            </label>
            <Form.Item htmlFor={name} className="form-input-drop-down" colon={false} required={false}>
                {getFieldDecorator(`${name}`, {
                    initialValue: defaultValue,
                    rules: [{ required: true, message: { message } }],
                })(
                    <Select placeholder="Select" name={name} style={{ height: "50px" }} disabled={disabled}>
                        {values.map((value) => {
                            return (
                                <Option key={value} value={value}>
                                    {value}
                                </Option>
                            );
                        })}
                    </Select>
                )}
            </Form.Item>
        </>
    );
};

const FormInputSelectMultiple = (props) => {
    const { getFieldDecorator } = props;
    const { message, name, label, values, defaultValue } = props;
    let disabled = false;
    if (props.disabled) disabled = true;

    const handleChange = (selectedItems) => {
        if (props.maxSelected && selectedItems.length > props.maxSelected) {
            if (props.antMsg) props.antMsg.warn("Max selection reached");
            return selectedItems.slice(0, props.maxSelected);
        }

        return selectedItems;
    };

    return (
        <>
            <label htmlFor={name} className="l-star">
                {label}
            </label>
            <Form.Item htmlFor={name} className="form-input-drop-down" colon={false} required={false}>
                {getFieldDecorator(`${name}`, {
                    initialValue: defaultValue,
                    rules: [{ required: true, message: { message } }],
                    getValueFromEvent: handleChange,
                })(
                    <Select
                        placeholder="Select"
                        name={name}
                        style={{ height: "50px" }}
                        disabled={disabled}
                        mode={"multiple"}
                    >
                        {values.map((value) => {
                            return (
                                <Option key={value} value={value}>
                                    {value}
                                </Option>
                            );
                        })}
                    </Select>
                )}
            </Form.Item>
        </>
    );
};

// Upload resume Functionality

const FormFileUpload = ({ message: msg, name, label, values, placeholder, getFieldDecorator }) => {
    const props = {
        customRequest({ action, data, file, filename, headers, onError, onProgress, onSuccess, withCredentials }) {
            var formData = new FormData();
            formData.append("image", file);
            formData.append("file_source", "progradRegister");

            let api_file_upload = Constants.G_API_URL + "fileupload/image-upload/";

            axios
                .post(api_file_upload, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((result) => {
                    // Do somthing
                    onSuccess(data.response, file);
                    let cookie_consent = localStorage.getItem("Nocookie");
                    localStorage.clear();
                    localStorage.setItem("Nocookie", cookie_consent);
                    localStorage.setItem("url", result.data.file);
                    message.success("Resume uploaded Successfully");
                })
                .catch((err) => {
                    // Do somthing
                    console.log(err);
                });
        },
    };

    return (
        <>
            <label htmlFor={name} className="no-l-star">
                {label}
            </label>
            <Form.Item htmlFor={name} className="form-input-drop-down" colon={false} required={false}>
                <Upload
                    {...props}
                    name={name}
                    showUploadList={false}
                    accept={
                        ".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    }
                >
                    <div className="button-secondary btn-border-upload">{placeholder}</div>
                </Upload>
            </Form.Item>
        </>
    );
};

// Select with AutoSearch Component

class FormInputAutoComplete extends Component {
    x = 0;
    api_getColleges = G_API_URL + "colleges/";

    state = {
        colleges: [],
        option_display: false,
        op_list: [],
        value: undefined,
    };

    others_college = ["Others"];
    onInputKeyDown = (e) => {
        let op_list = [];
        axios
            .get(this.api_getColleges, {
                params: { name: e.target.value },
            })
            .then((response) => {
                let colleges = response.data;

                colleges = colleges.data.map((item) => item.name + ", " + item.city);
                op_list = [];
                this.setState({ op_list });

                if (colleges.length === 0) this.setState({ op_list: this.others_college });
                else {
                    op_list = [...colleges];
                    this.setState({ op_list, colleges });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    render() {
        const { getFieldDecorator } = this.props;
        const { message, name, label, placeholder, isRequired, defaultValue } = this.props;

        let classes = "";

        if (isRequired) {
            classes = "l-star";
        } else {
            classes = "no-l-star";
        }

        const options = this.state.op_list.map((item) => <Option key={item}>{item}</Option>);
        return (
            <>
                <label htmlFor={name} className={classes}>
                    {label}
                </label>
                <Form.Item colon={false} required={false}>
                    {getFieldDecorator(`${name}`, {
                        initialValue: defaultValue,
                        rules: [{ required: isRequired, message: { message } }],
                    })(
                        <Select
                            showSearch
                            defaultActiveFirstOption={false}
                            filterOption={false}
                            notFoundContent={null}
                            placeholder={placeholder}
                            onInputKeyDown={this.onInputKeyDown}
                        >
                            {options}
                        </Select>
                    )}
                </Form.Item>
            </>
        );
    }
}

const FormInputNumber = (props) => {
    const { getFieldDecorator } = props;
    const { message, name, label, placeholder, isRequired, defaultValue } = props;
    let classes = "";

    if (isRequired) {
        classes = "l-star";
    } else {
        classes = "no-l-star";
    }

    let inputProps = {};
    if (name === "yop") inputProps = { min: 1980 };

    return (
        <>
            <label htmlFor={name} className={classes}>
                {label}
            </label>
            <Form.Item colon={false} required={false}>
                {getFieldDecorator(`${name}`, {
                    initialValue: defaultValue,
                    rules: [{ required: isRequired, message: { message } }],
                })(<InputNumber className="form-input-number" {...inputProps} id={name} placeholder={placeholder} />)}
                {props.extra_message && props.extra_message}
            </Form.Item>
        </>
    );
};

export {
    FormInput,
    FormInputMobile,
    FormInputDropDown,
    FormFileUpload,
    FormInputAutoComplete,
    FormInputRadio,
    FormInputNumber,
    FormInputPswd,
    FormInputYears,
    FormTextArea,
    FormInputSelectMultiple,
};
