import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { Modal, Button, Input, Checkbox } from "antd";
import FACE_Logo from "../../../assets/icons/svg_icons/logo_web.svg";
import CheckFill from "../../UI/CheckFill";
import CheckOutline from "../../UI/CheckOutline";
import keys from "../../../config/keys";
import axios from "axios";
import queryString from "query-string";
import { __getToken } from "../../../utils/user-details.util";
import { __getCookie, __deleteCookie } from "../../../utils/cookie.util";
import { getTimestamp } from "../../../utils/common.util";
import { showMessage } from "../../../utils/notifications.util";
import { startTrail } from "../../../utils/SkillZone/subscription.util";
import { G_API_URL } from "../../../constants/constants";

const Subscription = ({ content, subStatus, fromStatus, planRef }) => {
    const history = useHistory();
    // Redux Data
    const tokenData = useSelector((state) => state.jwtData);

    // Login
    const [logInStatus, setLoginStatus] = useState(false);

    // Payment
    const [trailBtnLoading, setTrailBtnLoading] = useState(false);
    const [paymentProgress, setPaymentProgress] = useState(false);
    const [payCompleted, setPayCompleted] = useState(false);
    const [totalPrice, setTotalPrice] = useState("");

    // Coupon
    const [showCoupon, setShowCoupon] = useState(false);
    const [couponCode, setCouponCode] = useState("");
    const [discount, setDiscount] = useState("");
    const [couponValidating, setCouponValidating] = useState(false);
    const [couponValidText, setCouponValidText] = useState("");
    const [couponVClass, setCouponVClass] = useState("invalid");

    const [checkoutModal, setCheckoutModal] = useState(false);
    const [activePlan, setActivePlan] = useState(3);

    const [emailConsent, setEmailConsent] = useState(true);

    // Auth Header
    const config = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${__getToken()}`,
        },
    };

    useEffect(() => {
        // Initialize RazorPay
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
        // Update Login status
        if (__getToken() !== "") {
            setLoginStatus(true);
        }

        if (subStatus === 1) {
            // Extend
            content["title"] = "Extend Subscription";
            content["desc"] = "Choose a validity for your skills passport";
        } else if (subStatus === 2) {
            // Renew
            content["title"] = "Renew Subscription";
            content["desc"] = "Choose a validity for your skills passport";
        }
    }, []);

    // NOTE: In future if we go with full page checkout
    // const goToCheckout = () => {
    //     const { plans } = content;
    //     setBtnLoading(true);
    //     if (plans[activePlan]) {
    //         const selectedPlan = plans[activePlan];
    //         const planDetails = {
    //             pid: selectedPlan["pid"],
    //             mrp: selectedPlan["mrp"],
    //             price: selectedPlan["price"],
    //             name: selectedPlan["name"],
    //             desc: `SkillZone Passport Subscription ${selectedPlan["duration"]} at Rs: ${selectedPlan["price"]}`,
    //         };
    //         dispatch(updateSkillZone({ planDetails }));
    //         setBtnLoading(false);
    //         // TODO: Redirect to checkout
    //     } else {
    //         setBtnLoading(false);
    //         console.log("Something went wrong... :/");
    //     }
    // };

    const createOrder = () => {
        const { plans } = content;
        const selectedPlan = plans[activePlan];

        // Set Loading status
        setPaymentProgress(true);

        const createOrderData = {
            pid: selectedPlan["pid"],
            price: totalPrice !== "" ? totalPrice : selectedPlan["price"],
            coupon: couponCode,
        };

        // createOrder API Call - to Create the order on RazorPay
        axios
            .post(G_API_URL + `payment/order`, queryString.stringify(createOrderData), config)
            .then((response) => {
                const { status, message, orderData } = response.data;
                if (status === 1) {
                    // Process the payment with RazorPay
                    paymentHandler(orderData);
                } else {
                    // Order failed
                    setPaymentProgress(false);
                    showMessage("error", message);
                }
            })
            .catch((err) => {
                console.log(err);
                // Order failed
                setPaymentProgress(false);
                showMessage("error", "Something went wrong");
            });
    };

    const paymentHandler = (orderData) => {
        // Active Plan Data
        const { plans } = content;
        const selectedPlan = plans[activePlan];

        // Order Response Data
        const { rzOrderId, pid, price, coupon } = orderData;

        // Subscription mode - for source tracking
        const sModes = {
            PID1: "Day",
            PID2: "Week",
            PID3: "Month",
            PID4: "Year",
        };

        let sourceData = {};

        // Check for source
        let szsCookie = __getCookie(keys.cookiePrefix + "szs").cookieValue;
        if (szsCookie) {
            let szt = __getCookie(keys.cookiePrefix + "szt").cookieValue;
            sourceData["source"] = szsCookie;
            sourceData["recordedAt"] = szt;
            sourceData["mode"] = sModes[selectedPlan["pid"]];
        } else {
            sourceData["source"] = "Organic";
            sourceData["recordedAt"] = getTimestamp();
            sourceData["mode"] = sModes[selectedPlan["pid"]];
        }

        const billDesc = `SkillZone Passport Subscription ${selectedPlan["duration"]} at Rs: ${selectedPlan["price"]}`;

        if (price * 1 === 1) {
            // FREE Course Payment handler
            const data = {
                paymentId: `FREE100OFF`,
                signature: "$VMD_SIGN",
                rzOrderId,
                pid,
                price,
                coupon,
                email: tokenData.email,
                emailConsent: emailConsent,
                ...sourceData,
            };

            axios
                .post(G_API_URL + `payment/verify`, queryString.stringify(data), config)
                .then((res) => {
                    const { status, message } = res.data;
                    if (status === 1) {
                        showMessage("success", message);
                        setPayCompleted(true);
                        // Remove source cookie
                        __deleteCookie(keys.cookiePrefix + "szs");
                        __deleteCookie(keys.cookiePrefix + "szt");
                        // Reload page
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                    } else {
                        showMessage("error", message);
                    }
                    setPaymentProgress(false);
                })
                .catch((err) => {
                    console.log(err);
                    // Verification failed
                    setPaymentProgress(false);
                    showMessage("error", "Something went wrong");
                });
        } else {
            let options = {
                order_id: rzOrderId,
                key: keys.rpSecretKey,
                name: "FACE Prep",
                description: billDesc,
                image: FACE_Logo,
                handler: (response) => {
                    const data = {
                        paymentId: response.razorpay_payment_id,
                        signature: response.razorpay_signature,
                        rzOrderId: response.razorpay_order_id,
                        pid,
                        price,
                        coupon,
                        email: tokenData.email,
                        emailConsent: emailConsent,
                        ...sourceData,
                    };

                    axios
                        .post(G_API_URL + `payment/verify`, queryString.stringify(data), config)
                        .then((res) => {
                            const { status, message } = res.data;
                            if (status === 1) {
                                showMessage("success", message);
                                setPayCompleted(true);
                                // Remove source cookie
                                __deleteCookie(keys.cookiePrefix + "szs");
                                __deleteCookie(keys.cookiePrefix + "szt");
                                // Reload page
                                setTimeout(() => {
                                    window.location.reload();
                                }, 2000);
                            } else {
                                showMessage("error", message);
                            }
                            setPaymentProgress(false);
                        })
                        .catch((err) => {
                            console.log(err);
                            // Verification failed
                            setPaymentProgress(false);
                            showMessage("error", "Something went wrong");
                        });
                },
                prefill: {
                    name: tokenData.usr,
                    email: tokenData.email,
                    contact: tokenData.mobile,
                },
                notes: {
                    address: "Team FACE Prep",
                },
                theme: {
                    color: "#f05136",
                },
                modal: {
                    ondismiss: () => {
                        setPaymentProgress(false);
                    },
                },
            };
            let rzp = new window.Razorpay(options);
            rzp.open();
        }
    };

    const validateCoupon = () => {
        const inCoupon = couponCode.toUpperCase();
        setCouponValidating(true);
        axios
            .get(G_API_URL + `payment/validate/${inCoupon}`, config)
            .then((response) => {
                const { status, message } = response.data;
                if (status === 1) {
                    // Update price with discount
                    setCouponValidating(false);
                    calculateDiscount(response.data);
                    setCouponValidText(message);
                    setCouponVClass("valid");
                } else {
                    setCouponValidating(false);
                    setCouponValidText(message);
                    setCouponVClass("invalid");
                }
            })
            .catch((err) => {
                console.log(err);
                setCouponValidating(false);
                showMessage("error", "Something went wrong!");
            });
    };

    const calculateDiscount = (couponData) => {
        const { plans } = content;
        const selectedPlan = plans[activePlan];
        let discountPrice = (selectedPlan.price * parseFloat(couponData.discount)) / 100;
        let finalPrice = selectedPlan.price - discountPrice;
        setTotalPrice(parseFloat(finalPrice).toFixed(2));
        setDiscount(parseFloat(discountPrice).toFixed(2));
    };

    const renderModalContent = () => {
        const { plans } = content;
        const selectedPlan = plans[activePlan];
        return !payCompleted ? (
            <div className="check-out-content">
                <div className="title">Checkout Summary</div>
                <div className="product-details f-d f-h-sb">
                    <div className="left">
                        <div className="title">SkillZone Passport</div>
                        <div className="desc">{`Subscription ${selectedPlan["duration"]}`}</div>
                    </div>
                    <div className="price-holder">{`₹ ${selectedPlan["price"]}`}</div>
                </div>
                <div className="coupon-block">
                    <div
                        className={`apply-coupon-btn ${showCoupon ? "active" : ""} f-d f-v-c c-pointer`}
                        onClick={() => setShowCoupon(!showCoupon)}
                    >
                        Apply Coupon
                        <i className="icon icon-chevron-down"></i>
                    </div>
                    <div className={`coupon-form ${showCoupon ? "active" : ""} f-d f-v-c`}>
                        <Input
                            className="coupon-input"
                            value={couponCode}
                            placeholder="Enter Coupon Code"
                            onChange={(e) => setCouponCode(e.target.value)}
                        />
                        <Button
                            className="validate-coupon-btn"
                            type="text"
                            onClick={() => (couponCode !== "" ? validateCoupon() : null)}
                            loading={couponValidating}
                        >
                            APPLY
                        </Button>
                    </div>
                    <div className={`coupon-validator ${couponVClass}`}>{couponValidText}</div>
                </div>
                <div className="billing-brief-block">
                    <div className="sub f-d f-v-c f-h-sb">
                        <div className="title">Subtotal</div>
                        <div className="price">{`₹ ${selectedPlan["price"]}`}</div>
                    </div>
                    <div className="discount f-d f-v-c f-h-sb">
                        <div className="title">Discount</div>
                        <div className={`price ${discount !== "" ? "green" : ""}`}>
                            {`${discount !== "" ? "-₹" + discount : "-"}`}
                        </div>
                    </div>
                    <div className="total f-d f-v-c f-h-sb">
                        <div className="title">Total</div>
                        <div className="price">{`₹ ${totalPrice !== "" ? totalPrice : selectedPlan["price"]}`}</div>
                    </div>
                </div>
                <Button
                    loading={paymentProgress}
                    className="pay-confirm-btn btn-p btn-lg"
                    onClick={() => createOrder()}
                >
                    {`Pay INR ${totalPrice !== "" ? totalPrice : selectedPlan["price"]}`}
                </Button>
            </div>
        ) : (
            <div className="payment-complete-content f-d f-vt f-v-c f-h-c">
                <div className="title">Success</div>
                <div className="desc">Payment completed successfully.</div>
            </div>
        );
    };

    const handleSubscription = () => {
        if (logInStatus) {
            // Start 7 day free trail
            setTrailBtnLoading(true);
            startTrail(emailConsent);
        } else {
            history.push(`/login?rurl=skillzone`);
        }
    };

    return (
        <>
            <Modal
                className="check-out-modal"
                centered
                width={560}
                destroyOnClose={true}
                visible={checkoutModal}
                onCancel={() => setCheckoutModal(false)}
                footer={null}
            >
                {renderModalContent()}
            </Modal>
            {/* <div className={`subscription-container ${fromStatus} lr-pad-d tb-pad-d-6 lr-pad-m`} ref={planRef}>
                <h3 className="title h3-heading-v2">{content.title}</h3>
                <div className="desc body-large-text">
                    {content.desc}
                    {fromStatus !== "modal" && subStatus === 0 && (
                        <Button
                            type="text"
                            loading={trailBtnLoading}
                            className={`try-free-btn f-v-c`}
                            onClick={() => handleSubscription()}
                        >
                            TRY 7 DAYS FOR FREE
                            <i className="icon icon-arrow-right"></i>
                        </Button>
                    )}
                </div>
                <div className="plans-container g-d g-col-4 g-col-1-m g-gap-32">
                    {content.plans.map((i, idx) => (
                        <div
                            key={idx}
                            className={`plan-block ${activePlan === idx ? "active" : ""} f-d f-vt f-v-c f-h-c`}
                            onClick={() => setActivePlan(idx)}
                        >
                            <div className="check-icon-container">
                                {activePlan === idx ? (
                                    <CheckFill color={"#FFFFFF"} />
                                ) : (
                                    <CheckOutline color={"#D2D2D4"} />
                                )}
                            </div>
                            <h3 className="title h3-heading-v2">₹{i.price}</h3>
                            {i.mrp !== null && <div className="mrp-price">{i.mrp}</div>}
                            <div className="desc body-medium-text">{i.duration}</div>
                        </div>
                    ))}
                </div>
                {subStatus === 0 &&
                    <div className="email-consent-container">
                        <Checkbox checked={emailConsent} onChange={(e) => setEmailConsent(e.target.checked)}>
                            I would like to receive email updates from Skill Zone. See{" "}
                            <Link to={`/privacy-policy`}>Privacy Policy</Link>
                        </Checkbox>
                    </div>
                }
                <Button
                    type="primary"
                    className="proceed-btn btn-p btn-lg w-20"
                    onClick={() => (logInStatus ? setCheckoutModal(true) : history.push(`/login`))}
                >
                    {content.cta}
                </Button>
            </div> */}

            <style jsx={"true"}>
                {`
                    .subscription-container {
                        background: var(--bg-black);
                    }

                    .subscription-container.modal {
                        padding: 2rem;
                    }

                    .subscription-container > .title,
                    .subscription-container > .desc {
                        color: var(--dove);
                    }

                    .subscription-container > .desc {
                        display: inline-flex;
                        margin-bottom: 4rem;
                    }

                    .subscription-container .desc .try-free-btn {
                        font-size: 18px;
                        font-weight: 300;
                        color: #ff8173 !important;
                        background: none;
                        border: none;
                        outline: none;
                        text-transform: uppercase;
                        transition: active 0.2s;
                    }

                    .subscription-container .try-free-btn .icon {
                        margin-left: 8px;
                    }

                    .subscription-container .proceed-btn {
                        outline: none;
                        border: none;
                    }

                    .subscription-container .plans-container {
                        margin-bottom: 4rem;
                    }

                    .subscription-container .email-consent-container {
                        margin: 0 0 1rem 0;
                    }

                    .plans-container .plan-block {
                        position: relative;
                        background: var(--dove);
                        border-radius: var(--peaky-br-8);
                        height: 280px;
                        cursor: pointer;
                        transition: all 0.2s;
                    }

                    .plan-block:last-child::before {
                        content: "BEST DEAL";
                        background: #deb654;
                        color: var(--dove);
                        padding: 10px 20px;
                        border-radius: var(--peaky-br-full);
                        letter-spacing: 1px;
                        position: absolute;
                        top: -66px;
                        pointer-events: none;
                    }

                    .plans-container .plan-block:hover {
                        background: rgba(255, 255, 255, 0.1);
                    }

                    .plans-container .plan-block.active {
                        background: var(--gradient-1);
                    }

                    .plan-block.active > .title,
                    .plan-block.active > .mrp-price,
                    .plan-block.active > .desc,
                    .plan-block:hover > .title,
                    .plan-block:hover > .mrp-price,
                    .plan-block:hover > .desc {
                        color: var(--dove);
                    }

                    .plan-block .check-icon-container {
                        position: absolute;
                        right: 24px;
                        top: 24px;
                    }

                    .plan-block > .title {
                        margin-bottom: 10px;
                    }

                    .plan-block .mrp-price {
                        font-size: 18px;
                        text-decoration: line-through;
                        margin-bottom: 10px;
                    }

                    .check-out-content > .title {
                        font-size: 24px;
                        color: var(--carbon);
                        text-transform: uppercase;
                        font-weight: 500;
                        margin-bottom: 1rem;
                    }

                    .product-details .title,
                    .product-details .price-holder {
                        color: var(--carbon);
                        font-size: 20px;
                        font-weight: 500;
                    }

                    .billing-brief-block .title,
                    .billing-brief-block .price {
                        color: var(--carbon);
                        font-size: 18px;
                        font-weight: 500;
                    }

                    .billing-brief-block .price.green {
                        color: var(--go);
                    }

                    .product-details .desc {
                        font-size: 14px;
                        color: var(--carbon);
                        font-family: "OpenSans", sans-serif;
                        opacity: 0.54;
                        font-weight: 300;
                    }

                    .check-out-content .coupon-block {
                        margin: 1rem 0;
                    }

                    .coupon-block .coupon-form {
                        visibility: hidden;
                        height: 0;
                        opacity: 0;
                        transition: all 0.2s;
                    }

                    .coupon-block .coupon-form.active {
                        margin-top: 1rem;
                        visibility: visible;
                        height: 64px;
                        opacity: 1;
                    }

                    .coupon-block .apply-coupon-btn {
                        color: var(--bluelagoon);
                        font-family: "OpenSans", sans-serif;
                    }

                    .apply-coupon-btn .icon {
                        margin-left: 6px;
                        transition: all 0.2s;
                    }

                    .apply-coupon-btn.active .icon {
                        transform: rotate(180deg);
                    }

                    .coupon-form input {
                        height: 0;
                        padding: 0 1rem;
                        opacity: 0;
                    }

                    .coupon-form .ant-input:focus,
                    .coupon-form .ant-input:hover {
                        border-color: var(--facered);
                        box-shadow: none;
                    }

                    .coupon-form .validate-coupon-btn {
                        height: 0;
                        padding: 0 2rem;
                        margin-left: 1rem;
                        opacity: 0;
                    }

                    .coupon-form.active input,
                    .coupon-form.active .validate-coupon-btn {
                        height: 64px;
                        opacity: 1;
                    }

                    .coupon-form .ant-btn:focus,
                    .coupon-form .ant-btn:hover {
                        color: var(--facered);
                        border-color: var(--facered);
                    }

                    .coupon-block .coupon-validator {
                        padding: 8px 1rem;
                    }

                    .coupon-block .coupon-validator.valid {
                        color: var(--go);
                    }

                    .coupon-block .coupon-validator.invalid {
                        color: var(--tomato);
                    }

                    .billing-brief-block {
                        margin-bottom: 1rem;
                    }

                    .billing-brief-block > div {
                        margin-bottom: 6px;
                    }

                    .billing-brief-block > .total {
                        margin-top: 12px;
                    }

                    .check-out-content .pay-confirm-btn {
                        width: 100%;
                    }

                    .payment-complete-content {
                        height: 300px;
                    }

                    .payment-complete-content .title {
                        font-size: 32px;
                        color: var(--carbon);
                        font-weight: 500;
                    }

                    .payment-complete-content .desc {
                        color: var(--carbon);
                        font-size: 16px;
                        font-family: "OpenSans", sans-serif;
                        font-weight: 300;
                    }

                    @media only screen and (max-device-width: 760px) {
                        .subscription-container > .desc {
                            display: block;
                        }

                        .subscription-container .desc .try-free-btn {
                            margin-left: 0;
                            margin-top: 1rem;
                        }

                        .subscription-container .proceed-btn {
                            width: 100%;
                        }
                    }

                    @media screen and (min-width: 768px) and (max-width: 1024px) {
                        .subscription-container .plans-container {
                            grid-template-columns: repeat(2, 1fr);
                        }

                        .subscription-container .proceed-btn {
                            min-width: max-content;
                            padding: 0 2rem;
                        }

                        .plan-block:last-child::before {
                            top: -20px;
                        }
                    }

                    @media screen and (min-width: 2000px) {
                        .subscription-container.modal {
                            max-width: 60vw;
                            margin: 0 auto;
                        }
                    }
                `}
            </style>
        </>
    );
};

export default Subscription;
