import * as React from "react";

function SvgLinkedin(props) {
    return (
        <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M7.778 14H4.667V4.667h3.11v1.555a3.592 3.592 0 012.742-1.371A3.5 3.5 0 0114 8.361V14h-3.111V8.75A1.762 1.762 0 009.14 7.238 1.412 1.412 0 007.778 8.75V14zM3.11 14H0V4.667h3.111V14zM1.556 3.111a1.556 1.556 0 110-3.111 1.556 1.556 0 010 3.111z"
                fill="#000"
                className="fill"
            />
        </svg>
    );
}

export default SvgLinkedin;
