import React, { useState } from "react";
import { Carousel, Modal } from "antd";
import TestimonialCard from "./TestimonialCard";
import CustomPrevArrow from "../CompanyCorner/CustomArrows/CustomPrevArrow";
import CustomNextArrow from "../CompanyCorner/CustomArrows/CustomNextArrow";
import styled from "styled-components";

const CarouselContainer = styled.div`
    .overview-img-card {
        height: 400px;
        width: 100%;
    }

    .carousel-navigation-m {
        display: none;
    }

    .ant-carousel .slick-prev,
    .ant-carousel .slick-next {
        top: 45%;
        color: var(--carbon);
        background: var(--dove);
        font-size: 18px;
        cursor: pointer;
    }

    .ant-carousel .slick-prev:hover,
    .ant-carousel .slick-next:hover,
    .ant-carousel .slick-prev:focus,
    .ant-carousel .slick-next:focus {
        color: var(--purple);
        background: var(--dove);
        box-shadow: var(--peaky-shadow-high-2);
    }

    .ant-carousel .slick-prev::before,
    .ant-carousel .slick-next::before {
        content: "";
    }

    .slick-arrow.slick-prev,
    .slick-arrow.slick-next {
        background-color: var(--dove);
        border-radius: 50%;
        box-shadow: var(--peaky-shadow-high);
        border: solid 1px var(--snowfall);
        display: flex !important;
        align-items: center;
        justify-content: center;
        width: 52px;
        height: 52px;
        transition: all 0.3s;
    }

    .slick-arrow.slick-prev {
        display: none !important;
    }

    @media only screen and (max-device-width: 760px) {
        .carousel-navigation-m {
            display: flex;
        }
        .slick-arrow.slick-prev,
        .slick-arrow.slick-next {
            display: none !important;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
        .slick-arrow.slick-prev,
        .slick-arrow.slick-next {
            display: none !important;
        }
    }
`;

const RenderTestimonialCards = (testimonialsContent) => {
    let testimonialsCardList = [];
    let index = 0;

    for (const [key, value] of Object.entries(testimonialsContent)) {
        let card_jsx = <TestimonialCard data={value} idx={index} key={key} />;
        testimonialsCardList.push(card_jsx);
        index++;
    }
    return testimonialsCardList;
};

const RenderOverviewCards = (content, onImageClick) => {
    let desc = content.desc;
    let images = content.images;

    return images.map((img, idx) => {
        return (
            <React.Fragment>
                <div className="body-normal-text">{desc[idx]}</div>
                <div key={"over-img" + idx} onClick={() => onImageClick(img)}>
                    <div
                        className="bg-image-full overview-img-card"
                        style={{ backgroundImage: "url(" + img + ")" }}
                    ></div>
                </div>
            </React.Fragment>
        );
    });
};

Carousel.defaultProps = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    infinite: true,
    prevArrow: <CustomPrevArrow className="custom-prev" />,
    nextArrow: <CustomNextArrow className="custom-next" />,
};

const CourseHeroCarousel = ({ content, type = "hero" }) => {
    const [picSelected, setPic] = useState(null);
    const [modalVisible, setmodalVisible] = useState(false);

    const onImageClick = (image_url) => {
        setPic(image_url);
        setmodalVisible(true);
    };

    let slick_list = [];

    if (type === "hero") slick_list = RenderTestimonialCards(content);
    if (type === "overview") {
        slick_list = RenderOverviewCards(content, onImageClick);
    }

    return (
        <CarouselContainer>
            <Carousel autoplay>{slick_list}</Carousel>

            <Modal
                title={null}
                visible={modalVisible}
                onCancel={() => {
                    setmodalVisible(false);
                    setPic(null);
                }}
                footer={null}
            >
                <img
                    src={picSelected}
                    alt="Please reload to load image"
                    id="img-preview"
                    style={{ height: "auto", maxWidth: "100%" }}
                />
            </Modal>
        </CarouselContainer>
    );
};

export default CourseHeroCarousel;
