import { Modal, Button } from "antd";
import React, { Component } from "react";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import OtpInput from "react-otp-input";
import { openFailureNotification, openSuccessNotification } from "../../utils/notifications.util";
import Countdown from "react-countdown";
import axios from "axios";
import { G_API_URL } from "../../constants/constants";
import { __getToken } from "../../utils/user-details.util";

let preferredCountries = ["in", "us", "ca", "sa", "qa", "ae", "om", "kw", "sg"];

class ActivationMobile extends Component {
    state = {
        userNumber: "",
        numberNotEntered: false,
        isNumberValid: false,
        btnLoading: false,
        otp: null,
        otpSent: false,
        countDown: <></>,
        resendCount: 0,
        btnLoading: [false, false],
    };

    componentDidMount = () => {};

    handleMobileBlur = (status, value, countryData, number, id) => {
        // 1. Get rid of white spaces, dash, # that's formatted during entry to avoid literal string conflict
        // 2. Get rid of first character if its zero

        let mobileNumber = `${value}`.replace(/[\s-#()]/g, "");
        mobileNumber = `${parseInt(mobileNumber)}`;

        let number_valid = false;

        if (countryData.dialCode === "91" && mobileNumber.length === 10) number_valid = true;
        else if (countryData.dialCode !== "91") number_valid = true;

        this.setState({
            userNumber: {
                prefix: countryData.dialCode,
                mobileNumber,
            },
            numberNotEntered: false,
            isNumberValid: number_valid,
        });
    };

    handleGenerateOtp = () => {
        const { isNumberValid, userNumber, resendCount } = this.state;

        let cta = null;
        let msgTemplate = null;
        if (this.props.cta) cta = this.props.cta;
        if (this.props.msgTemplate) msgTemplate = this.props.msgTemplate;

        if (isNumberValid === false) {
            openFailureNotification("Please Enter Valid Number");
        }

        if (isNumberValid) {
            let btnLoading = this.state.btnLoading;
            btnLoading[0] = true;

            this.setState({ btnLoading: btnLoading });

            let params = {
                prefix: userNumber.prefix,
                mobileNumber: userNumber.mobileNumber,
                msgTemplate,
                cta,
            };

            let config = {
                headers: {
                    Authorization: __getToken(),
                },
            };

            axios.post(G_API_URL + "auth/send-otp/", params, config).then((res) => {
                if (res.data.status === 200) {
                    btnLoading[0] = false;
                    this.setState({
                        otpSent: true,
                        countDown: (
                            <Countdown
                                date={Date.now() + 60000}
                                renderer={this.renderer}
                                key={resendCount + 1}
                            />
                        ),
                        resendCount: resendCount + 1,
                        btnLoading: btnLoading,
                    });
                } else {
                    openFailureNotification("Could not send OTP, Please Try Again");
                }
            });
        }
    };

    setInOTP = (otp) => {
        this.setState({ otp: otp });
    };

    submitOTP = () => {
        let { otp, userNumber } = this.state;
        otp = otp.toString();

        if (otp.length !== 6) {
            openFailureNotification("Please enter full OTP");
        } else {
            let params = {
                userMobile: userNumber.mobileNumber,
                otp: otp,
            };

            let config = {
                headers: {
                    Authorization: __getToken(),
                },
            };

            axios.put(G_API_URL + "auth/verify-otp/", params, config).then(async (res) => {
                if (res.data.status === 0) openFailureNotification(res.data.msg);
                if (res.data.status === 1) {
                    openSuccessNotification(res.data.msg);
                    // Used to call again the uid status for phone number
                    await this.props.handleUIDStatus();

                    this.props.onModalCancel();
                }
            });
        }
    };

    renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return (
                <div className="resend-otp-btn" onClick={this.handleGenerateOtp}>
                    Resend
                </div>
            );
        } else {
            // Render a countdown
            return (
                <span className="resend-countdown">
                    {" "}
                    Resend in {minutes}:{seconds}
                </span>
            );
        }
    };

    render() {
        const { otpSent, numberNotEntered, isNumberValid, btnLoading, otp } = this.state;
        const { visible, onModalCancel, handleUIDStatus } = this.props;

        let btn = (
            <Button
                key="submit"
                type="primary"
                loading={btnLoading[0]}
                onClick={this.handleGenerateOtp}
            >
                Generate OTP
            </Button>
        );

        let body = (
            <div
                className={`form-block mobile-number ${
                    numberNotEntered ? (isNumberValid ? "has-error" : "has-error-2") : ""
                }`}
            >
                <IntlTelInput
                    containerClassName="intl-tel-input"
                    inputClassName="form-control"
                    preferredCountries={preferredCountries}
                    defaultCountry={"in"}
                    fieldName={"mobileNumber"}
                    autoComplete="phone"
                    placeholder={"Mobile Number*"}
                    format
                    onPhoneNumberBlur={(status, value, countryData, number, id) =>
                        this.handleMobileBlur(status, value, countryData, number, id)
                    }
                />
            </div>
        );

        if (otpSent === true) {
            btn = (
                <Button key="submit" type="primary" onClick={this.submitOTP}>
                    Verify OTP
                </Button>
            );

            body = (
                <>
                    <div className="msg">
                        Enter the verification code sent on +{this.state.userNumber.prefix}{" "}
                        {this.state.userNumber.mobileNumber}
                    </div>
                    <div className="otp-inputs-container f-d">
                        <OtpInput
                            containerStyle="otp-inputs-container"
                            className="otp-input"
                            value={otp}
                            placeholder="123456"
                            onChange={(otp) => this.setInOTP(otp)}
                            numInputs={6}
                            shouldAutoFocus
                            isInputNum
                        />
                    </div>
                    <div className="resend-otp f-d">
                        <div>Haven't received the code? </div>
                        <div>{this.state.countDown}</div>
                    </div>
                </>
            );
        }

        return (
            <>
                <Modal
                    title={"Verify Mobile Number"}
                    visible={visible}
                    onCancel={async () => {
                        await handleUIDStatus();
                        onModalCancel();
                    }}
                    footer={[btn]}
                    width={600}
                    destroyOnClose={true}
                >
                    <div className="modal-body f-d f-vt f-v-c">
                        <div className="form-wrapper">{body}</div>
                    </div>
                </Modal>
                <style jsx={"true"}>
                    {`
                        .ant-btn-primary {
                            border: none;
                            height: 40px;
                            width: 150px;
                        }

                        .modal-body .form-wrapper {
                            font-family: "Nunito sans", sans-serif;
                            font-size: 16px;
                            color: var(--carbon);
                        }

                        .modal-body .form-wrapper .msg {
                            margin-bottom: 16px;
                        }

                        .modal-body .form-wrapper .resend-otp {
                            margin-top: 16px;
                        }

                        .modal-body .form-wrapper .resend-otp .resend-countdown {
                            color: var(--facered);
                            text-decoration: underline;
                            margin-left: 8px;
                        }

                        .modal-body .form-wrapper .resend-otp .resend-otp-btn {
                            color: var(--facered);
                            text-decoration: underline;
                            margin-left: 8px;
                            cursor: pointer;
                        }

                        .ant-notification {
                            left: 0 !important;
                            right: 0 !important;
                            margin: 0 !important;
                            max-width: unset !important;
                            width: 100%;
                            z-index: 9999 !important;
                            color: white;
                        }

                        .modal-body {
                            padding: 32px;
                        }

                        .modal-body .form-wrapper {
                            width: 80%;
                        }

                        .modal-body .form-wrapper .mobile-number {
                            width: 100%;
                        }

                        .form-wrapper .intl-tel-input,
                        .form-wrapper .intl-tel-input .country-list {
                            width: inherit;
                        }

                        .modal-body .intl-tel-input input {
                            height: 50px;
                            border-radius: var(--peaky-br-4);
                            border: 1px solid #d9d9d9;
                            width: 100%;
                            font-weight: 300;
                            color: var(--carbon);
                            transition: all 0.4s;
                        }
                        .modal-body .intl-tel-input input:hover {
                            border-color: var(--facered);
                        }
                        .modal-body .intl-tel-input input:focus {
                            border-color: var(--facered);
                            outline: none;
                        }

                        .otp-inputs-container .ant-form-item {
                            margin-bottom: 0;
                        }

                        .otp-inputs-container .otp-input input {
                            width: 64px !important;
                            height: 64px;
                            text-align: center;
                            border-radius: 0;
                            color: var(--carbon);
                            font-size: 16px;
                            font-family: "Open Sans", sans-serif;
                            font-weight: 500;
                            border: var(--peaky-border);
                            transition: all 0.3s;
                        }

                        .otp-inputs-container .otp-input:first-child input {
                            border-radius: 4px 0 0 4px;
                        }

                        .otp-inputs-container .otp-input:last-child input {
                            border-radius: 0 4px 4px 0;
                        }

                        .otp-inputs-container .otp-input input:hover,
                        .otp-inputs-container .otp-input input:focus {
                            border-color: var(--facered);
                            border-radius: 4px;
                            box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
                            outline: none;
                        }

                        .otp-inputs-container .otp-input input::placeholder {
                            color: rgba(0, 0, 0, 0.3);
                        }

                        @media only screen and (max-device-width: 760px) {
                            .otp-inputs-container .otp-input input {
                                width: 40px !important;
                            }
                        }
                    `}
                </style>
            </>
        );
    }
}

export default ActivationMobile;
