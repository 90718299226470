import { store } from "../config/store";
import { __setCookie, __deleteCookie, __getCookie } from "./cookie.util";
import keys from "../config/keys";
import { G_URL, G_API_FEED_URL } from "../constants/constants";
import { setJWTData } from "../actions/index";
import axios from "axios";
import queryString from "query-string";
import CryptoJS from "crypto-js";
import jwt from "jwt-decode";

const login_user = (data) => {
    let userdata = data.user;

    let user_uid = userdata.uid;
    let user_name = userdata.usr ? userdata.usr : "there";
    // let user_type = userdata.usr_type;
    let user_email = userdata.email;
    // let user_verified = userdata.usr_verified;
    let user_pic = userdata.picture;

    if (data.status === 1) {
        __setCookie(keys.cookiePrefix + "ut", data.token_data.token, 1, "month");
        __setCookie(keys.cookiePrefix + "uid", user_uid, 1, "month");
        __setCookie(keys.cookiePrefix + "user_name", user_name, 1, "month");
        __setCookie(keys.cookiePrefix + "upic", user_pic, 1, "month");
        __setCookie(keys.cookiePrefix + "email", user_email, 1, "month");

        // Global cookies
        // __setCookie(keys.FP_COOKIE_PREFIX + "ut", data.token_data.token, 1, "month", true);
        // __setCookie(keys.FP_COOKIE_PREFIX + "uid", user_uid, 1, "month", true);
        // __setCookie(keys.FP_COOKIE_PREFIX + "user_name", user_name, 1, "month", true);
        // __setCookie(keys.FP_COOKIE_PREFIX + "upic", user_pic, 1, "month", true);
        // __setCookie(keys.FP_COOKIE_PREFIX + "email", user_email, 1, "month", true);
    }

    // Set JWT data in store
    store.dispatch(setJWTData({ ...data.user }));
};

const socialLogin = function(provider, responseData, rurl, loginType) {
    let data;

    if (provider === "google") {
        let responseDetails = jwt(responseData);
        const { googleId: sub, email, name, imageUrl: picture } = responseDetails;
        const providerToken = responseData;

        data = {
            provider: provider,
            social_id: sub,
            name: name,
            email: email,
            user_image: picture,
            provider_token: providerToken,
            rurl: rurl,
        };
    }

    if (provider === "facebook") {
        const providerToken = responseData.accessToken;
        let user_image = "";
        if (responseData.picture && responseData.picture.data && responseData.picture.data.url)
            user_image = responseData.picture.data.url;

        data = {
            provider: provider,
            social_id: responseData.id,
            name: responseData.name,
            user_image: user_image,
            provider_token: providerToken,
            rurl: rurl,
        };
    }

    const config = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    };

    axios
        .post(G_API_FEED_URL + "auth/social-login/", queryString.stringify(data), config)
        .then((response) => {
            if (response.data.status === 1) {
                this.setState({ loginSuccess: true });
                this.openSuccessNotification();

                // Call Login Function
                login_user(response.data);

                let redUrl;

                if (!response.data.is_data_entered) {
                    redUrl =
                        response.data.rurl !== undefined && response.data.rurl !== ""
                            ? G_URL + "user-details/?rurl=" + response.data.rurl
                            : G_URL + "user-details";
                } else {
                    redUrl =
                        response.data.rurl !== undefined && response.data.rurl !== ""
                            ? G_URL + response.data.rurl
                            : G_URL + "";
                }

                if (loginType === "popup") {
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                } else {
                    setTimeout(() => {
                        window.location = decodeURIComponent(redUrl);
                    }, 2000);
                }
            } else {
                this.openFailureNotification(response.data.msg);
            }
        })
        .catch((err) => {
            console.log(err);
        });
};

const check_login = () => {
    const token_cookie = __getCookie(keys.cookiePrefix + "uid");
    return token_cookie.cookieExists && token_cookie.cookieValue !== undefined && token_cookie.cookieValue !== null;
};

const logout_user = () => {
    let cookies = document.cookie.split(";");
    cookies.map((cookie) => __deleteCookie(cookie.split("=")[0]));
    localStorage.removeItem("Nocookie");

    // Store Reset
    store.dispatch({ type: "USER_LOGOUT", payload: undefined });
    // After logout redirect user to login page
    setTimeout(() => {
        window.location = decodeURIComponent(G_URL + "login");
    }, 1000);
};

const encHexSalt = (content) => {
    let encContent = [];
    let content_B64 = CryptoJS.AES.encrypt(content, "BSLDFS");
    // let content_E64 = CryptoJS.enc.Base64.parse(content_B64);
    // let content_hex = content_E64.toString(CryptoJS.enc.Hex);
    encContent.hex = content_B64.ciphertext.toString();
    encContent.salt = content_B64.salt.toString();
    return encContent;
};

export { login_user, socialLogin, check_login, logout_user, encHexSalt };
