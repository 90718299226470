import React, { Component } from "react";
import Checkout from "../../pages/RazorPay/Checkout";
import { Button } from "antd";
import { __getToken, __getEmail, __getUID, __getDP } from "../../utils/user-details.util";
import { check_login } from "../../utils/login.util";
import { G_HOME_URL, G_FC_API_URL, G_F360_URL } from "../../constants/constants";
import SubscriptionTick from "../../assets/imgs/subscription-tick.svg";
import { initiateTrial } from "../../utils/Premium/premium_login";

class Package extends Component {
    state = {
        MobileVerified:0,
        CheckoutModalOpen:false
    };


    render() {
        const {
            key,
            name,
            price,
            description,
            subscribedCourses,
            courseId,
            isPremiumUser,
            isTrialAvailable,
            courseExpired,
            mVerified,
            accessibility,
            index
        } = this.props;
        const isLogIn = check_login();

        let try_it_handler = () => {
            this.props.handleTrailBtn(name);
            this.setState({MobileVerified:1})
        }
        let btnText = this.props.btnText;

        //If mobile verification is success and trail is available auto redirect to premium course
        if ((this.props.mVerified) && (this.state.MobileVerified === 1) && (isTrialAvailable)) {
            initiateTrial(courseId);
            btnText = "Try for free";
        }

        if ((this.props.mVerified) && (this.state.MobileVerified === 1) && (!isTrialAvailable) && (!this.state.CheckoutModalOpen)){
            this.setState({CheckoutModalOpen : true});
        }

        //Init pay_click_jsx with btn
        let pay_click_jsx = (
            <Button type="primary" onClick={try_it_handler}>
                Subscribe
            </Button>
        );

        //If mobile verification is success reassign pay_click_jsx  with checkout component
        if (mVerified) {
            pay_click_jsx = (
                <Checkout
                    courseID={parseInt(courseId)}
                    courseName={name}
                    coursePrice={price}
                    courseExpired = {courseExpired}
                    calledBy="subscription"
                    cookie={__getToken()}
                />
            );
        }

        let renderModule = () =>{
            if(isLogIn){
                if(subscribedCourses.includes(parseInt(courseId))){
                    return (
                        <>
                            <Button className="subscribed-btn" type="primary" disabled={true}>
                                Subscribed
                            </Button>
                        </>
                    )
                }
                else if((isPremiumUser) && (!isTrialAvailable)){
                    return (
                        <>
                            {this.state.CheckoutModalOpen ? 
                                <>
                                    <Checkout
                                        courseID={parseInt(courseId)}
                                        courseName={name}
                                        coursePrice={price}
                                        courseExpired = {courseExpired}
                                        calledBy="subscription"
                                        checkoutModalOpen = {this.state.CheckoutModalOpen}
                                        cookie={__getToken()}
                                    />
                                </>
                                : 
                                <>
                                    {pay_click_jsx}
                                </>
                            }

                        </>
                    )
                }
                else{
                    return (
                        <>
                            <Button
                                className="try-it not-logged-in"
                                type="primary"
                                onClick={try_it_handler}
                            >
                                {btnText}
                            </Button>
                        </>
                    )
                }
            }
            else{
                return(
                    <>
                        <Button
                            className="try-it not-logged-in"
                            type="primary"
                            onClick={() => {
                                window.location.href = G_HOME_URL + "login/?rurl=premium";
                            }}
                        >
                            Try for free
                        </Button>
                    </>
                )
            }
        }
        return (
            <>
                <div className={`slide-${key} price-card-container f-d f-vt`}> 
                    <h2 className="h2-heading">{name}</h2>
                    <div className="f-d f-vt price">
                        <span className="h2-heading price-txt">INR {price}</span>
                        <span className="body-caption">{description}</span>
                    </div>
                    <div className="btn-holder f-d">
                        {renderModule()}
                    </div>
                    <div className="course-access-container f-d f-vt">
                        <div className="f-d f-ht access-txt">
                            <img src={SubscriptionTick} alt="accessible" className="module-accessible"/>
                            <span className="body-regular module-txt"> Aptitude </span>
                        </div>
                        <div className="f-d f-ht access-txt">
                            <img src={SubscriptionTick} alt="accessible" className="module-accessible"/>
                            <span className="body-regular module-txt"> C++: Fundamentals, OOPS and STL </span>
                        </div>
                        {index === 1 && (
                            <div className="f-d f-ht access-txt">
                                <img src={SubscriptionTick} alt="accessible" className="module-accessible"/>
                                <span className="body-regular module-txt"> Data Structure and Algorithms </span>
                            </div>
                        )}
                    </div>
                </div>

                <style jsx={"true"}>{`
                    .package-wrapper .subscribed-btn {
                        color: white;
                        opacity: 0.7;
                    }
                    .not-logged-in {
                        border: none;
                    }
                    .package-wrapper .package-title {
                        color: var(--batman);
                        font-weight: 450;
                        font-size: 18px;
                    }

                    .package-wrapper .package-inr-container {
                        margin: auto 0;
                    }

                    .package-wrapper .package-inr {
                        color: var(--facered);
                        font-weight: 450;
                        font-size: 18px;
                        line-height: 1;
                    }

                    .package-wrapper .package-access-type {
                        font-size: 10px;
                        text-align: center;
                    }

                    .package-wrapper .package-subscribe-btn {
                        border: none;
                        background-color: var(--facered);
                        border-radius: 4px;
                        color: var(--dove);
                        padding: 0.5rem 0;
                        width: 50%;
                        cursor: pointer;
                        font-size: 12px;
                    }

                    .package-wrapper .please-wait-btn {
                        opacity: 0.8;
                        padding: 0.4rem;
                    }

                    .package-wrapper .try-it {
                        padding: 0 25px;
                    }
                    .subscription-wrapper {
                        margin: 3% 0 0;
                    }

                    .price-card-container .btn-holder,
                    .price-card-container .course-access-container {
                        margin-top: 20px;
                    }

                    .price-card-container .access-txt {
                        padding: 4px;
                    }

                    .price-card-container .module-txt {
                        text-align: start;
                        padding-left: 20px;
                    }
                    .choose-plan-txt {
                        text-align: center;
                        padding: 14px;
                    }
                    .price-card-container .price {
                        line-height: 1;
                    }

                    .price-card-container .subscribed-btn {
                        color: white;
                        opacity: 0.7;
                    }
                    .subscription-wrapper .price-card-container {
                        padding: 20px;
                    }
                    .slider {
                        width: 300px;
                        text-align: center;
                        overflow: hidden;
                    }

                    .slides {
                        display: flex;
                        overflow-x: auto;
                        scroll-snap-type: x mandatory;
                        scroll-behavior: smooth;
                        -webkit-overflow-scrolling: touch;
                    }
                    .slides::-webkit-scrollbar {
                        width: 10px;
                        height: 10px;
                    }
                    .slides::-webkit-scrollbar-thumb {
                        background: black;
                        border-radius: 10px;
                    }
                    .slides::-webkit-scrollbar-track {
                        background: transparent;
                    }
                    .slides > div {
                        scroll-snap-align: start;
                        flex-shrink: 0;
                        width: 300px;
                        height: 510px;
                        margin-right: 50px;
                        border-radius: 10px;
                        transform-origin: center center;
                        transform: scale(1);
                        transition: transform 0.5s;
                        position: relative;
                        display: flex;
                        border: solid 1px var(--snowfall);
                        align-items: center;
                        font-size: 100px;
                    }
                    .slides > div:target {
                        /*   transform: scale(0.8); */
                    }
                    .subscription-wrapper .title-content-wrapper {
                        margin: 5% 0 0;
                    }

                    .subscription-wrapper .subscription-title {
                        display: block;
                        margin: 0 0 2%;
                    }

                    .subscription-wrapper .most-popular {
                        margin: 0 0 1% 0;
                    }

                    .subscription-wrapper .most-popular-plan {
                        color: #27ae60;
                        font-size: 14px;
                    }

                    .subscription-wrapper .sub-text {
                        color: var(--sandstone);
                    }

                    .subscription-wrapper .sub-text-active {
                        color: var(--batman);
                    }

                    .subscription-wrapper .free-subscription-text {
                        font-size: 14px;
                        color: var(--facered);
                    }

                    .subscription-wrapper .individual-wrapper {
                        margin-top: 10px;
                        background: var(--smoke);
                    }

                    .subscription-wrapper .subscription-type-switch {
                        background-color: var(--facered);
                    }

                    .subscription-wrapper .subscription-type-switch.ant-switch {
                        min-width: 35px;
                        height: 20px;
                    }

                    .subscription-wrapper .ant-switch-loading-icon,
                    .subscription-wrapper .ant-switch::after {
                        top: 3.5px;
                        left: 3px;
                        height: 12px;
                        width: 12px;
                    }

                    .subscription-wrapper .ant-switch-checked::after {
                        left: 93%;
                    }

                    .subscription-wrapper .subscription-type {
                        margin: 0.5% 0 0 5%;
                    }

                    .subscription-wrapper .subscription-table-wrapper {
                        margin: 3% 0 0;
                    }

                    .subscription-wrapper .subscription-table-head .th-col {
                        background-color: var(--dove);
                        height: 25vh;
                        border: 1px solid var(--snowfall);
                        padding: 1.5rem;
                    }

                    .subscription-wrapper .subscription-table-head .main-head-title {
                        color: var(--batman);
                        font-weight: 400;
                        font-size: 20px;
                        margin: 5% 0 0;
                    }

                    .subscription-wrapper .individual-packs-title {
                        width: 100%;
                    }

                    .subscription-wrapper .module-variant {
                        margin: 5% 0 5%;
                    }

                    .subscription-wrapper .modules-container {
                        margin: 3.5% 0 0;
                        width: 100%;
                    }

                    .package-subscription-wrapper {
                        padding: 0 16px 12px 16px;
                    }

                    .package-subscription-wrapper .package-detail .name,
                    .package-subscription-wrapper .payable-amount .title {
                        width: 70%;
                    }

                    .package-subscription-wrapper .package-detail .price,
                    .package-subscription-wrapper .payable-amount .price {
                        width: 30%;
                        text-align: end;
                        font-color: var(--batman);
                        font-weight: 450;
                    }

                    .package-subscription-wrapper .package-detail,
                    .package-subscription-wrapper .payable-amount {
                        display: flex;
                        font-size: 16px;
                    }

                    .package-subscription-wrapper .proceed-to-pay .payment-btn {
                        background-color: var(--facered) !important;
                        border-color: unset !important;
                        outline: none;
                        color: var(--dove);
                        width: 100%;
                        padding: 1rem 0 2rem;
                        margin: 10% 0 2%;
                    }
                    .price-txt {
                        color: var(--facered);
                    }
                    .ant-modal-header,
                    .ant-modal-footer {
                        border: none !important;
                    }

                    .confirm-purchase-modal .ant-modal-footer {
                        display: none;
                    }

                    .package-subscription-wrapper .payable-amount {
                        margin: 8% 0 0;
                    }

                    .confirm-purchase-modal .ant-modal-title {
                        font-size: 24px;
                        margin: 1rem;
                    }

                    #payment-process {
                        height: 80vh;
                    }

                    .subscription-crausol {
                        width: 100%;
                    }

                    .subscription-crausol .ant-carousel .slick-dots li button {
                        background: var(--granite);
                    }

                    .subscription-crausol .ant-carousel .slick-dots .slick-active button {
                        background: var(--facered);
                    }
                `}</style>
            </>
        );
    }
}

export default Package;
