import React from "react";
import keys from "../../config/keys";
import { __getCookie } from "../../utils/cookie.util";
import * as Constants from "../../constants/constants";

const jwtDecode = require("jwt-decode");

const RequireAdminAuth = (Component) => {
    return class App extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                loading: true,
            };
        }

        componentDidMount() {
            var allowedUID = [];
            if (Constants.G_URL !== "http://localhost:5000/") {
                if (Constants.G_URL === "https://dev.faceprep.in/") {
                    /**
                     '17','swathi.chintala@focusacademy.in'
                     '67','hussain@chalkstreet.com'
                     '70','vinay@chalkstreet.com'
                     '73','srikanth@chalkstreet.com'
                     '246','srikanth@focusacademy.in'
                     '383','vikrant@chalkstreet.com'
                     '284','varun@chalkstreet.com',
                     '85', 'rushil@chalkstreet.com'
                     '434' 'arvind.zico@gmail.com',
                     '455' 'arvind dev',
                     '607' 'gayathri.selvaraj@faceacademy.in'
                     **/
                    allowedUID = ["17", "73", "67", "70", "246", "383", "284", "85", "434", "455", "607"];
                } else {
                    /**
                     100002        venkat@focusacademy.in
                     130054        anton@chalkstreet.com
                     130112        pandhal.raja@focusacademy.in
                     562891        pandhal@chalkstreet.com
                     962718        hussain@chalkstreet.com
                     1472845       vinay@chalkstreet.com
                     1311912       amrit@focusacademy.in
                     1320224       vishakha.alok@focusacademy.in
                     1457289       anton@focusacademy.in
                     1481596       srikanth@focusacademy.in
                     1340385       srikanth@chalkstreet.in
                     1498870       hussain@focusacademy.in
                     1             rj@focusacademy.in
                     1340775       brindha.faceprep@gmail.com
                     1498249       adithya@focusacademy.in
                     1550912       ram@focusacademy.in
                     721           arvind.ragunathan@focusacademy.in
                     1577715       meghana@focusacademy.in
                     1155672       swathi.chintala@focusacademy.in
                     100010        vijay@focusacademy.in
                     719           karthikraja@focusacademy.in
                     1615057       angeline.margaret@focusacademy.in
                     1513626       dinesh.jayakumar@focusacademy.in
                     1516685       vikrant@chalkstreet.com
                     1511216       Varun@chalkstreet.com
                     1505280       shirlyn.lewis@focusacademy.in
                     1481264       rushil.chalkstreet.com 
                     1724339       gayathri.selvaraj@faceacademy.in
                     1500826       nagarajan.chandramohan@focusacademy.in 
                     1465611       qam.roi@focusacademy.in 
                     971774        prashant.pandey@focusacademy.in
                     1839340        arshia.mitra@faceacademy.in
                     1885466       sachin#focusacademy.in
                     1032          deepak@focusacademy.in
                     1945070       srijan.saha@prograd.org
                     943           arvind.zico@gmail.com
                     131403        sowmya.natarajan@faceacademy.in
                     2060236       atharv.aparajeet@faceacademy.in
                     2059743       prasanth.ramachandran@focusacademy.in
                     1988200       srijan.saha@focusacademy.in
                     1559717       enquiry@faceprep.in
                     2127411       carol@focusacademy.in
                     2144692       sowmya@faceprep.in
                     2372473       nmliveadmin@faceprep.in
                     **/
                    allowedUID = [
                        "1481596",
                        "962718",
                        "100002",
                        "1498870",
                        "1",
                        "100010",
                        "721",
                        "719",
                        "1516685",
                        "1511216",
                        "1481264",
                        "1724339",
                        "1505280",
                        "1500826",
                        "1465611",
                        "1472845",
                        "971774",
                        "1839340",
                        "1340385",
                        "1945070",
                        "943",
                        "131403",
                        "2060236",
                        "2059743",
                        "1988200",
                        "1559717",
                        "2127411",
                        "2271541",
                        "2144692",
                    ];

                    // console.log(this.props.match.path);
                    // console.log(/\/cms\/adcard\/edit\/v2\/*/.test(this.props.match.path));
                    // eslint-disable-next-line default-case
                    switch (this.props.match.path) {
                        case "/affiliate/sales":
                        case "/affiliate/clicks":
                        case "/affiliate/coupon":
                        case "/affiliate":
                            allowedUID.push(
                                "130054",
                                "1457289",
                                "1340775",
                                "1498249",
                                "1513626",
                                "1511216",
                                "1505280",
                                "1885466",
                                "1032"
                            );
                            break;
                        case "/prograd/pat":
                            allowedUID.push(
                                "130112",
                                "562891",
                                "1311912",
                                "1320224",
                                "1457289",
                                "130054",
                                "1498249",
                                "1550912",
                                "1577715",
                                "1155672",
                                "1615057",
                                "1511216"
                            );
                            break;
                        case "/prograd/info/":
                            allowedUID.push(
                                "1498249",
                                "1550912",
                                "1615057",
                                "1457289",
                                "1511216",
                                "971774",
                                "2089955",
                                "2107604",
                                "2096868",
                                "2158157",
                                "2097126"
                            );
                            break;
                        case "/admin/webinars/":
                            allowedUID.push("1472845", "1498249", "1511216", "1505280");
                            break;
                        case "/admin/nm/":
                            allowedUID.push("2372473");
                            break;
                        case "/edge/creator/":
                            allowedUID.push("2096868");
                            break;
                        case "/cms/adcard/":
                        case "/cms/adcard/creator/":
                        case "/cms/adcard/creator/v2/":
                            allowedUID.push("1498249", "1511216", "1505280", "2158157", "2096868", "2107604");
                            break;
                    }
                }

                switch (true) {
                    case /\/cms\/adcard\/edit\/v2\/*/.test(this.props.match.path):
                        allowedUID.push("1498249", "1511216", "1505280", "2158157", "2096868", "2107604");
                        break;
                }

                if (__getCookie(keys.cookiePrefix + "ut").cookieExists) {
                    let decodedToken = jwtDecode(__getCookie(keys.cookiePrefix + "ut").cookieValue);
                    let uid = parseInt(decodedToken.uid) + "";
                    if (allowedUID.includes(uid)) {
                        this.setState({
                            loading: false,
                        });
                    } else {
                        window.location.href = Constants.G_HOME_URL;
                    }
                } else {
                    window.location.href = Constants.G_HOME_URL;
                }
            } else {
                this.setState({
                    loading: false,
                });
            }
        }

        render() {
            return this.state.loading ? <></> : <Component {...this.props} />;
        }
    };
};
export default RequireAdminAuth;
