import React, { Component, useState } from "react";
import styled from "styled-components";

const FaqTagContainer = styled.div`
    margin-bottom: 64px;
    margin-top: 60px;
    flex-wrap: wrap;

    .faq-tag {
        width: max-content;
        padding: 12px 32px;
        border-radius: 34.5px;
        background-color: var(--smoke);
        margin-right: 32px;
        color: var(--carbon);
        font-weight: 400;
    }

    .faq-tag-active {
        background-color: var(--facered);
        color: var(--dove);
    }

    @media only screen and (max-device-width: 760px) {
        flex-direction: vertical;

        .faq-tag {
            padding: 15px;
            margin-right: unset;
            font-size: 14px;
            margin-bottom: 16px;
        }
    }
`;

const FAQTags = (props) => {
    const { title, activeKey } = props;

    let className = "faq-tag";
    if (activeKey === title) className += " faq-tag-active";

    return (
        <div
            className={`${className} c-pointer body-normal-text`}
            onClick={() => props.onClick(title)}
        >
            {title}
        </div>
    );
};

// @@ FAQ CARD STYLES

const FAQCardContainer = styled.div`
    background-color: var(--dove);
    border-radius: 8px;
    margin-bottom: 32px;
    border: var(--peaky-border);

    :hover {
        box-shadow: var(--peaky-shadow-high-2);
    }

    .card-hero-container {
        padding: 24px;

        .card-title {
            color: var(--carbon);
            font-family: "Nunito sans", sans-serif !important;
            width: 85%;
        }

        .card-icon {
            width: 24px;
            height: 24px;
        }

        .plus-icon {
            position: relative;
            width: 14px;
            height: 14px;

            &:before,
            &:after {
                content: "";
                position: absolute;
                transition: transform 0.2s ease-out;
                background-color: ${(props) =>
                    props.isOpen ? "var(--facered)" : "var(--granite)"};
            }

            /* Vertical line */
            &:before {
                top: 0;
                left: 50%;
                width: 4px;
                height: 100%;
                margin-left: -2px;
                transform: ${(props) => (props.isOpen ? "rotate(90deg)" : "unset")};
            }

            /* horizontal line */
            &:after {
                top: 50%;
                left: 0;
                width: 100%;
                height: 4px;
                margin-top: -2px;
                transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "unset")};
            }
        }
    }

    .card-body-container {
        padding: 24px;
        color: var(--carbon-light);
        border-radius: 0px 0px 4px 4px;
        display: ${(props) => (props.isOpen ? "block" : "none")};
        transition: opacity 1s ease-out;
        opacity: ${(props) => (props.isOpen ? 1 : 0)};
    }
`;

const FAQCard = ({ question, answer }) => {
    const [isOpen, setisOpen] = useState(false);
    return (
        <FAQCardContainer className="c-pointer" isOpen={isOpen} onClick={() => setisOpen(!isOpen)}>
            <div className="card-hero-container">
                <div className="card-title-group f-d f-h-sb">
                    <div className="card-title h6-heading-v2">{question}</div>
                    <div className="card-icon">
                        <div className="plus-icon"></div>
                    </div>
                </div>
            </div>
            <div className="card-body-container body-medium-text">{answer}</div>
        </FAQCardContainer>
    );
};

export class Faq extends Component {
    state = {
        activeSubheading: "Program Timings & Curriculum",
        content: [],
        content_map: {},
        activeKey: null,
    };

    componentDidMount = () => {
        let content_map = {};
        let faqContent = this.props.faqContent;

        if (faqContent) {
            for (const [key, value] of Object.entries(faqContent)) {
                let { title, questionsData } = value;
                content_map[title] = Object.values(questionsData);
            }
        }

        this.setState({
            content: Object.values(content_map)[0],
            content_map,
            activeKey: Object.keys(content_map)[0],
        });
    };

    handleFAQTagClick = (key) => {
        let content = this.state.content_map[key];
        this.setState({ activeKey: key, content });
    };

    renderFAQTags = () => {
        const { content_map, activeKey } = this.state;
        return Object.keys(content_map).map((title, idx) => (
            <FAQTags
                title={title}
                onClick={this.handleFAQTagClick}
                activeKey={activeKey}
                key={`FAQ-CARD-${idx}`}
            />
        ));
    };

    renderFAQCards = () => {
        const { content } = this.state;
        let content_list = Object.values(content);
        return content_list.map((content) => <FAQCard {...content} />);
    };

    render() {
        return (
            <>
                <div className="faq-container lr-pad-d tb-pad-d lr-pad-m">
                    <h2 className="faq-header h2-heading-v2">Frequently Asked Questions</h2>
                    <FaqTagContainer className="f-d">{this.renderFAQTags()}</FaqTagContainer>
                    <div className="section-question-con">
                        {this.renderFAQCards()}
                        {/* {Object.values(this.state.content).map((qes, qno) => {
                            return (
                                qes && (
                                    <div
                                        key={qno + 1}
                                        className={`question-container qno-${qno + 1} c-pointer`}
                                        onClick={(e) => this.handleOpen(e)}
                                    >
                                        <div
                                            className="open-icon bg-image-full"
                                            style={{
                                                backgroundImage: "url(" + chevron_arrow + ")",
                                            }}
                                        ></div>
                                        <div className="qes-ans-block">
                                            <div className={`question q-${qno + 1} h6-heading`}>
                                                {qes.question}
                                            </div>
                                            <div className={`answer a-${qno + 1} body-medium-text`}>
                                                {qes.answer}
                                            </div>
                                        </div>
                                    </div>
                                )
                            );
                        })} */}
                    </div>
                </div>

                <style jsx>
                    {`
                        .faq-header {
                            font-weight: 400 !important;
                        }

                        .faq-container > h2 {
                            margin-bottom: 2rem;
                            color: var(--carbon);
                        }

                        @media only screen and (max-device-width: 760px) {
                            .faq-section-con {
                                width: 100%;
                            }

                            .sub-header {
                                flex-direction: column;
                            }
                        }
                    `}
                </style>
            </>
        );
    }
}

export default Faq;
