import * as React from "react";
const SvgResources = (props) => (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M1.684 16a1.76 1.76 0 0 1-1.19-.428C.164 15.287 0 14.945 0 14.546V4.364h1.684v10.181h9.263V16H1.684Zm3.369-2.91a1.76 1.76 0 0 1-1.19-.427c-.33-.285-.495-.627-.495-1.027V1.455c0-.4.165-.743.496-1.028C4.194.142 4.59 0 5.053 0h5.894L16 4.364v7.272c0 .4-.165.743-.495 1.028-.33.285-.727.427-1.19.427H5.054Zm5.052-8h4.21l-4.21-3.635V5.09Z"
            fill={props.color ? props.color : "#8C8C8C"}
        />
    </svg>
);
export default SvgResources;
