import React, { Component } from "react";
import { Button, Carousel, Switch } from "antd";
import axios from "axios";
import Package from "../Subscription/Package";
import MobilePackage from "../Subscription/MobilePackage";
import TableRow from "../Subscription/TableRow";
import Module from "../Subscription/Module";
import { G_API_URL, G_FC_API_URL, G_HOME_URL } from "../../constants/constants";
import { __getToken } from "../../utils/user-details.util";
import { check_login } from "../../utils/login.util";
import SubscriptionTick from "../../assets/imgs/subscription-tick.svg";

import { isMobile,isTablet } from "react-device-detect";
import Checkout from "../../pages/RazorPay/Checkout";
import ActivationMobile from "../../pages/Authentication/ActivationMobile";

Carousel.defaultProps = {
    dots: true,
    draggable: true,
    arrows: false,
    autoplaySpeed: 4000,
    slidesToShow: !isMobile ? 3 : 1,
    slidesToScroll: !isMobile ? 3 : 1,
};
class Subscription extends Component {
    state = {
        isMonthlyBased: true,
        isModalActive: false,
        pack: "",
        price: 0,
        packId: 0,
        packages: [],
        modules: [],
        courseIds: [],
        accessibility: [],
        activeCourse: this.props.courseActive,
        expiredCourse: this.props.courseExpired,
        pleasewait: false,
        otp_modalVisible: false,
        btnText: "Try for free",
        courseName: "" // for trial pack Click tracking
    };


    handleTrailBtn = (courseName) => {
        this.setState({ otp_modalVisible: true, btnText: "Please Wait", courseName: courseName});
    };

    handleModalCancel = () => {
        this.setState({ otp_modalVisible: !this.state.otp_modalVisible, btnText: "Try for free" });
    };



    togglePlan = () =>
        this.setState({ isMonthlyBased: !this.state.isMonthlyBased }, () => {
            this.getPlans();
        });

    getPlans = () => {
        const { isMonthlyBased } = this.state;
        axios
            .get(G_FC_API_URL + "subscription/packs/", {
                params: {
                    duration: isMonthlyBased ? 1 : 6,
                    type: "month"
                },
            })
            .then((res) => {
                let { status, packs } = res.data;
                if (status) {
                    let { packages, modules, accessibility } = packs;
                    let courseIds = packages.map((a) => a.course_id);
                    courseIds = courseIds.concat(modules.map((a) => a.course_id));
                    this.setState({ packages, modules, accessibility, courseIds: courseIds });
                    // this.getSubscriptions();
                }
            })
            .catch((err) => {});
    };

    getSubscriptions = async () => {
        let order_status_api = G_API_URL + "orders";
        if (check_login()) {
            await axios
                .post(order_status_api, {
                    token: __getToken(),
                    courseIds: this.state.courseIds,
                })
                .then((response) => {
                    if (response.data.status === 1 && response.data.data.length > 0) {
                        let subscribedCourses = response.data.data.map((a) => a.course_id);
                        this.setState({ subscribedCourses: subscribedCourses });
                    }
                })
                .catch((err) => {});
        }
    };

    componentDidMount = () => {
        localStorage.setItem("fromPage", "subscriptions");
        this.getPlans();
    };

    courseSubscriptions = () => {
        const isLogIn = check_login();
        const { packages, accessibility } = this.state;
        const {courseActive,courseExpired} = this.props;
        return Object.values(packages).map((item, i) => {
            return (
                <div className={`slide-${i} price-card-container f-d f-vt`}>
                    <h2 className="h2-heading">{item.name}</h2>
                    <div className="f-d f-vt price">
                        <span className="h2-heading price-txt">INR {item.price}</span>
                        <span className="body-caption">{item.description}</span>
                    </div>
                    <div className="btn-holder f-d">
                        {isLogIn ? (
                            courseActive.includes(parseInt(item.course_id)) ? (
                                <Button className="subscribed-btn" type="primary" disabled={true}>
                                    Subscribed
                                </Button>
                            ) : (
                                <Checkout
                                    courseID={parseInt(item.course_id)}
                                    courseName={item.name}
                                    coursePrice={item.price}
                                    courseExpired = {courseExpired}
                                    calledBy="subscription"
                                    cookie={__getToken()}
                                />
                            )
                        ) : (
                            <Button
                                className="apply-now"
                                type="primary"
                                onClick={() => {
                                    window.location.href = G_HOME_URL + "login/?rurl=premium";
                                }}
                            >
                                Try for free
                            </Button>
                        )}
                    </div>
                    <div className="course-access-container f-d f-vt">
                        {Object.values(accessibility).map((accessItem, j) => {
                            // eslint-disable-next-line no-lone-blocks
                            {
                                if (accessItem.access[i] === 1)
                                    return (
                                        <div className="f-d f-ht access-txt">
                                            <img
                                                src={SubscriptionTick}
                                                alt="accessible"
                                                className="module-accessible"
                                            />
                                            <span className="body-regular module-txt">
                                                {accessItem.topic}
                                            </span>
                                        </div>
                                    );
                                else return "";
                            }
                        })}
                    </div>
                </div>
            );
        });
    };

    renderPlans = (isMonthlyBased, packages, tableRows) => {

        if (isMobile && !isTablet) {
            return (
                <>
                    {" "}
                    <h3 className="h3-heading subscription-title  ">Plus is for everyone</h3>
                    <div className="f-d mob-subscription-type ">
                        <span className={`sub-text${isMonthlyBased ? " sub-text-active" : ""}`}>
                            Monthly
                        </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Switch
                            className="subscription-type-switch"
                            onChange={() => this.togglePlan()}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span
                            className={`f-d f-vt sub-text${
                                !isMonthlyBased ? " sub-text-active" : ""
                            }`}
                        >
                            4 months + 2 months free
                            <span className="free-subscription-text">Save 33%</span>
                        </span>
                    </div>
                    <div className="subscription-crausol">
                        <Carousel id="subcription-carousel" className="sub-slick-list" autoplay>
                            {/* {this.courseSubscriptions()} */}
                            {packages}
                        </Carousel>
                    </div>
                </>
            );
        }

        return (
            <>
                {" "}
                <div className="f-d f-vt f-v-c ">
                    <h3 className="h3-heading subscription-title ">Ready to get Started?</h3>
                    <div className="f-d subscription-type">
                        <span className={`sub-text${isMonthlyBased ? " sub-text-active" : ""}`}>
                            Monthly Pack
                        </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Switch
                            className="subscription-type-switch"
                            onChange={() => this.togglePlan()}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <br />
                        <span
                            className={`f-d f-vt sub-text${
                                !isMonthlyBased ? " sub-text-active" : ""
                            }`}
                        >
                            4 months + 2 months free
                            <span className="free-subscription-text">Save 33%</span>
                        </span>
                    </div>
                </div>
                <div className="subscription-table-wrapper lr-pad-d tb-pad-d lr-pad-m">
                    <div className="subscription-table">
                        <div className="g-d g-col-4 most-popular">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span className="f-d f-v-c f-h-c most-popular-plan">
                                &nbsp;MOST POPULAR
                            </span>
                        </div>
                        <div className="g-d g-col-3 subscription-table-head">
                            <div className="main-head th-col">
                                <span className="f-d f-vt main-head-title">
                                    Pick your <span>Subscription Pack</span>
                                </span>
                            </div>
                            {packages}
                        </div>
                        {tableRows}
                    </div>
                </div>
            </>
        );
    };

    render() {
        let {
            packages,
            modules,
            accessibility,
            isMonthlyBased,
            packId,
            pleasewait,
        } = this.state;
        let {isPremiumUser,isTrialAvailable,courseActive,courseExpired} = this.props;
        if(isMobile && !isTablet){
            packages = packages.map((pack,i) => (
                <MobilePackage
                    key={pack.id}
                    subscribedCourses={courseActive}
                    courseExpired = {courseExpired}
                    isWaiting={packId === pack.course_id ? pleasewait : false}
                    packId={pack.id}
                    courseId={pack.course_id}
                    name={pack.name}
                    description={pack.description}
                    price={pack.price}
                    onSubscribe={this.handleClick}
                    isPremiumUser={isPremiumUser}
                    isTrialAvailable={isTrialAvailable}
                    handleTrailBtn={this.handleTrailBtn}
                    mVerified={this.props.mVerified}
                    btnText={this.state.btnText}
                    accessibility = {accessibility}
                    index = {i}
                />
            ));
        }else{
            packages = packages.map((pack) => (
                <Package
                    key={pack.id}
                    subscribedCourses={courseActive}
                    courseExpired = {courseExpired}
                    isWaiting={packId === pack.course_id ? pleasewait : false}
                    packId={pack.id}
                    courseId={pack.course_id}
                    name={pack.name}
                    description={pack.description}
                    price={pack.price}
                    onSubscribe={this.handleClick}
                    isPremiumUser={isPremiumUser}
                    isTrialAvailable={isTrialAvailable}
                    handleTrailBtn={this.handleTrailBtn}
                    mVerified={this.props.mVerified}
                    btnText={this.state.btnText}   
                />
            ));
        }
        let tableRows = accessibility.map((accessibility, key) => (
            <TableRow
                idx={key % 2}
                key={key}
                topic={accessibility.topic}
                access={accessibility.access}
            />
        ));

        let idx = 0;
        modules = modules.map((module, key) => {
            idx++;
            if (idx > 3) idx = 1;
            return (
                <Module
                    key={key}
                    isWaiting={packId === module.course_id ? pleasewait : false}
                    idx={idx}
                    subscribedCourses={courseActive}
                    packId={module.id}
                    courseId={module.course_id}
                    name={module.name}
                    description={module.description}
                    image={module.image}
                    price={module.price}
                    is_available={module.is_available}
                    isTrialAvailable={isTrialAvailable}
                    onSubscribe={this.handleClick}
                    handleTrailBtn={this.handleTrailBtn}
                    mVerified={this.props.mVerified}
                    btnText={this.state.btnText}
                />
            );
        });
        return (
            <>
                <div className="subscription-wrapper">
                    <div className="g-d package-variant" id="plan-wrapper">
                        <div className="f-d f-vt f-v-c title-content-wrapper">
                            {this.renderPlans(isMonthlyBased, packages, tableRows)}
                            <div className="individual-wrapper">
                                <div className="g-d g-h-c module-variant lr-pad-d tb-pad-d lr-pad-m">
                                    <h2 className="individual-packs-title h2-heading">
                                        Choose individual modules
                                    </h2>
                                    <div className="g-d g-col-3 modules-container">{modules}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ActivationMobile
                        visible={this.state.otp_modalVisible}
                        onModalCancel={this.handleModalCancel}
                        handleUIDStatus={this.props.handleUIDStatus}
                        cta={this.state.courseName}
                        msgTemplate={"premium-msg"}
                />

                <style jsx={"true"}>{`
                    .subscription-wrapper {
                        margin: 3% 0 0;
                    }

                    .price-card-container .btn-holder,
                    .price-card-container .course-access-container {
                        margin-top: 20px;
                    }

                    .price-card-container .access-txt {
                        padding: 4px;
                    }

                    .price-card-container .module-txt {
                        text-align: start;
                        padding-left: 20px;
                    }
                    .choose-plan-txt {
                        text-align: center;
                        padding: 14px;
                    }
                    .price-card-container .price {
                        line-height: 1;
                    }

                    .price-card-container .subscribed-btn {
                        color: white;
                        opacity: 0.7;
                    }
                    .subscription-wrapper .price-card-container {
                        padding: 20px;
                    }
                    .slider {
                        width: 300px;
                        text-align: center;
                        overflow: hidden;
                    }

                    .slides {
                        display: flex;
                        overflow-x: auto;
                        scroll-snap-type: x mandatory;
                        scroll-behavior: smooth;
                        -webkit-overflow-scrolling: touch;
                    }
                    .slides::-webkit-scrollbar {
                        width: 10px;
                        height: 10px;
                    }
                    .slides::-webkit-scrollbar-thumb {
                        background: black;
                        border-radius: 10px;
                    }
                    .slides::-webkit-scrollbar-track {
                        background: transparent;
                    }
                    .slides > div {
                        scroll-snap-align: start;
                        flex-shrink: 0;
                        width: 300px;
                        height: 510px;
                        margin-right: 50px;
                        border-radius: 10px;
                        transform-origin: center center;
                        transform: scale(1);
                        transition: transform 0.5s;
                        position: relative;
                        display: flex;
                        border: solid 1px var(--snowfall);
                        align-items: center;
                        font-size: 100px;
                    }
                    .slides > div:target {
                        /*   transform: scale(0.8); */
                    }
                    .subscription-wrapper .title-content-wrapper {
                        margin: 5% 0 0;
                    }

                    .subscription-wrapper .subscription-title {
                        display: block;
                        margin: 0 0 2%;
                    }

                    .subscription-wrapper .most-popular {
                        margin: 0 0 1% 0;
                    }

                    .subscription-wrapper .most-popular-plan {
                        color: #27ae60;
                        font-size: 14px;
                    }

                    .subscription-wrapper .sub-text {
                        color: var(--sandstone);
                    }

                    .subscription-wrapper .sub-text-active {
                        color: var(--batman);
                    }

                    .subscription-wrapper .free-subscription-text {
                        font-size: 14px;
                        color: var(--facered);
                    }

                    .subscription-wrapper .individual-wrapper {
                        margin-top: 10px;
                        background: var(--smoke);
                    }

                    .subscription-wrapper .subscription-type-switch {
                        background-color: var(--facered);
                    }

                    .subscription-wrapper .subscription-type-switch.ant-switch {
                        min-width: 35px;
                        height: 20px;
                    }

                    .subscription-wrapper .ant-switch-loading-icon,
                    .subscription-wrapper .ant-switch::after {
                        top: 3.5px;
                        left: 3px;
                        height: 12px;
                        width: 12px;
                    }

                    .subscription-wrapper .ant-switch-checked::after {
                        left: 93%;
                    }

                    .subscription-wrapper .subscription-type {
                        margin: 0.5% 0 0 5%;
                    }

                    .subscription-wrapper .subscription-table-wrapper {
                        margin: 3% 0 0;
                    }

                    .subscription-wrapper .subscription-table-head .th-col {
                        background-color: var(--dove);
                        height: 25vh;
                        border: 1px solid var(--snowfall);
                        padding: 1.5rem;
                    }

                    .subscription-wrapper .subscription-table-head .main-head-title {
                        color: var(--batman);
                        font-weight: 400;
                        font-size: 20px;
                        margin: 5% 0 0;
                    }

                    .subscription-wrapper .individual-packs-title {
                        width: 100%;
                    }

                    .subscription-wrapper .module-variant {
                        margin: 5% 0 5%;
                    }

                    .subscription-wrapper .modules-container {
                        margin: 3.5% 0 0;
                        width: 100%;
                    }

                    .package-subscription-wrapper {
                        padding: 0 16px 12px 16px;
                    }

                    .package-subscription-wrapper .package-detail .name,
                    .package-subscription-wrapper .payable-amount .title {
                        width: 70%;
                    }

                    .package-subscription-wrapper .package-detail .price,
                    .package-subscription-wrapper .payable-amount .price {
                        width: 30%;
                        text-align: end;
                        font-color: var(--batman);
                        font-weight: 450;
                    }

                    .package-subscription-wrapper .package-detail,
                    .package-subscription-wrapper .payable-amount {
                        display: flex;
                        font-size: 16px;
                    }

                    .package-subscription-wrapper .proceed-to-pay .payment-btn {
                        background-color: var(--facered) !important;
                        border-color: unset !important;
                        outline: none;
                        color: var(--dove);
                        width: 100%;
                        padding: 1rem 0 2rem;
                        margin: 10% 0 2%;
                    }
                    .price-txt {
                        color: var(--facered);
                    }
                    .ant-modal-header,
                    .ant-modal-footer {
                        border: none !important;
                    }

                    .confirm-purchase-modal .ant-modal-footer {
                        display: none;
                    }

                    .package-subscription-wrapper .payable-amount {
                        margin: 8% 0 0;
                    }

                    .confirm-purchase-modal .ant-modal-title {
                        font-size: 24px;
                        margin: 1rem;
                    }

                    #payment-process {
                        height: 80vh;
                    }

                    .subscription-crausol {
                        width: 100%;
                    }

                    .subscription-crausol .ant-carousel .slick-dots li button {
                        background: var(--granite);
                    }

                    .subscription-crausol .ant-carousel .slick-dots .slick-active button {
                        background: var(--facered);
                    }
                `}</style>
            </>
        );
    }
}

export default Subscription;
