import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Progress, Divider, Modal, Popover } from "antd";
import _ from "lodash";
import HTMLFlipBook from "react-pageflip";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import Layout from "../../Layout";
import { PassportContainer } from "./passport.styles";
import Subscription from "../Main/Subscription";
import Spinner from "../../Spinner/spinner";

import { G_API_URL, G_HOME_URL } from "../../../constants/constants";
import { __getToken } from "../../../utils/user-details.util";
import { fetchProfileData, fetchSkillZonePlans } from "../../../actions";

import FaceLogoSvg from "../../../assets/icons/svg_icons/face-logo.svg";
import CertificateSvg from "./certificate.svg";
import ArrowLeftSvg from "../../../assets/icons/svg_icons/arrow-left.svg";
import StarSvg from "../../../assets/icons/stats/star.svg";
import AwardSvg from "../../../assets/icons/svg_icons/award.svg";

function getVerifiedSkillsSVGColor(value) {
    if (value === 1) return "#27ae60";
    return "#9e9e9e";
}

const PopoverContent = (course_name = "Verbal Ability") => {
    return (
        <>
            <div>You can unlock this verified skill badge in one of the following ways:</div>
            <ul>
                <li>Achieve LEVEL 3 proficiency in the skill - {course_name}</li>
                <li>
                    Score more than 80% marks in at least 4 full-length <br /> mock tests listed under Quantitative
                    Aptitude
                </li>
            </ul>
        </>
    );
};

const SkillsPassport = () => {
    // Global Redux State
    const jwtData = useSelector((state) => state.jwtData);
    const skillZoneData = useSelector((state) => state.skillZoneData);
    const skillPlans = useSelector((state) => state.skillPlans);

    // Component internal State
    const [proficiencyData, setProficiencyData] = useState({});
    const [loading, setloading] = useState(true);
    const [flipPageWidth, setFlipPageWidth] = useState("");
    const [pageInfo, setpageInfo] = useState("Front cover of 14 pages");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [verifiedSkills, setverifiedSkills] = useState({
        "Quantitative Aptitude": 0,
        "Verbal Ability": 0,
        "Logical Reasoning": 0,
        "Programming Fundamentals": 0,
        "Data Structure and Algorithms": 0,
    });
    const [certificates, setCertificates] = useState([]);

    const { status: subscriptionStatus } = skillZoneData.subscription;

    const dispatch = useDispatch();

    // flipBook Ref
    const filpBookRef = useRef();

    useEffect(() => {
        dispatch(fetchSkillZonePlans());
        const user_token = __getToken();

        if (user_token) dispatch(fetchProfileData({ jwt_token: user_token }));

        axios.get(`${G_API_URL}skills/proficiency/`, { headers: { Authorization: __getToken() } }).then((res) => {
            if (res.data.status === 1) {
                setProficiencyData(res.data.data);
                setloading(false);
            }
        });

        axios.get(`${G_API_URL}skills/verified-skills/`, { headers: { Authorization: __getToken() } }).then((res) => {
            if (res.data.status === 1) {
                setverifiedSkills(res.data.skills_map);
            }
        });

        axios.get(`${G_API_URL}certificate/all/`, { headers: { Authorization: __getToken() } }).then((res) => {
            if (res.data.status === 1) {
                setCertificates(res.data.certificates);
            }
        });
    }, []);

    useEffect(() => {
        let windowWidth = window.innerWidth;
        if (windowWidth >= 1400) setFlipPageWidth(550);
        else if (windowWidth > 1287 && windowWidth <= 1380) setFlipPageWidth(500);
        else if (windowWidth > 1247 && windowWidth <= 1287) setFlipPageWidth(480);
        else if (windowWidth > 1207 && windowWidth <= 1247) setFlipPageWidth(460);
        else if (windowWidth > 1127 && windowWidth <= 1207) setFlipPageWidth(420);
        else if (windowWidth > 1092 && windowWidth <= 1127) setFlipPageWidth(400);
        else setFlipPageWidth(400);
    }, []);

    const handleOnFlip = (e) => {
        // let totalPages = filpBookRef.getPageCount();
        let pageNumber = e.data;
        if (pageNumber === 0) setpageInfo("Front cover of 14 pages");
        else setpageInfo(`${pageNumber} - ${pageNumber + 1} of 14 pages`);
    };

    const renderProficiencyOverall = ({ type = "cTag", index = 0 }) => {
        let { categories, lv3_points } = proficiencyData;
        let list = [];
        let usr_points = {};
        // Map the user Points
        categories.forEach((element) => {
            if (element.usr_points) usr_points = { ...usr_points, ...element.usr_points };
        });

        if (type === "cTag") list = categories.map((ele) => ele.program);
        if (type === "l0Tag") list = categories[index]["l0_child_map"];

        let {} = categories[index];
        return list.map((program, idx) => {
            let points = 0;
            let level = 0;
            if (usr_points[program]) {
                points = usr_points[program]["points"];
                level = usr_points[program]["level"];
            }
            let total = lv3_points[program];
            let percetage = parseInt((points / total) * 100);

            return (
                <div className="ctag-proficiency-container" key={idx}>
                    <div className="title body-medium-text">{program}</div>
                    <div className="prof-details f-d f-h-sb">
                        <div className="prof-stats">
                            <div
                                className="star-svg bg-image-full"
                                style={{ backgroundImage: "url(" + StarSvg + ")" }}
                            ></div>
                            <span className="score">{points}</span>/{total}
                        </div>
                        <div className="prof-progress">
                            <Progress percent={percetage} size="small" showInfo={false} strokeColor={"#FAB201"} />
                        </div>
                        <div className="prof-level">Level {level}</div>
                    </div>
                </div>
            );
        });
    };

    const renderChildProficiency = (childs, lv3_points, usr_points) => {
        return childs.map((ele, idx) => {
            let total = 1;
            let points = 0;
            let level = 0;
            if (lv3_points[ele]) total = lv3_points[ele];
            if (usr_points[ele]) {
                points = usr_points[ele]["points"];
                level = usr_points[ele]["level"];
            }
            let percentage = parseInt((points / total) * 100);

            return (
                <React.Fragment key={idx}>
                    <Divider />
                    <div className="child-proficiency f-d f-h-sb f-v-c">
                        <div className="details">
                            <div className="name">{ele}</div>
                            <div className="points f-d f-v-c">
                                <div
                                    className="star-svg bg-image-full"
                                    style={{ backgroundImage: "url(" + StarSvg + ")" }}
                                ></div>
                                <div className="score">{points}</div>/<div className="total">{total}</div>
                            </div>
                        </div>
                        <div className="prof-progress">
                            <Progress percent={percentage} size="small" showInfo={false} strokeColor={"#FAB201"} />
                        </div>
                        <div className="prof-level f-d f-h-e">Level {level}</div>
                    </div>
                </React.Fragment>
            );
        });
    };

    const renderProficiencyTags = (index) => {
        let { categories, lv3_points } = proficiencyData;
        let categoryData = categories[index];
        let list = categoryData["l1_child_map"];
        let usr_points = categoryData["usr_points"];

        return list.map(({ parent, child }, idx) => {
            let child_chunks = _.chunk(child, 5);
            return child_chunks.map((child_list, cidx) => {
                return (
                    <div className={`page proficiency-l1-tag`} data-density="hard" key={`${idx}-${cidx}`}>
                        <div className="page-header">
                            <h4 className="h4-heading-v2">Proficiency</h4>
                            <div className="desc">{parent} drill-down by topic</div>
                            {renderChildProficiency(child_list, lv3_points, usr_points)}
                        </div>
                    </div>
                );
            });
        });
    };

    const renderCerticateHeader = (
        <>
            <h4 className="h4-heading-v2">Certifications</h4>
            <div className="desc">Awarded for completing select courses on FACE Prep</div>
        </>
    );

    const renderCertificatesBlocks = (start, end) => {
        let ceritifcatesList = certificates.slice(start, end);

        return ceritifcatesList.map((ele, idx) => {
            return (
                <div className="certificate-block f-d" key={`CERTIFICATE-${idx}`}>
                    <div className="award-icon">
                        <div className="icon bg-image-full" style={{ backgroundImage: "url(" + AwardSvg + ")" }}></div>
                    </div>
                    <div className="content">
                        <div className="body-medium-text heading">Certificate of completion</div>
                        <div className="body-normal-text course-name">{ele.course_name}</div>
                        <Link
                            to={`/skillzone/certificate/${jwtData.uid}/${ele.course_slug}/`}
                            className="cert-link body-normal-text"
                        >
                            View certificate
                        </Link>
                    </div>
                </div>
            );
        });
    };

    return (
        <>
            <Layout>
                {!loading && !skillPlans.loading ? (
                    <>
                        <div className="lr-pad-d lr-pad-m skills-passport-wrapper">
                            <div className="passport-wrapper">
                                <div
                                    className="back-navigation f-d f-v-c c-pointer"
                                    onClick={() => window.history.back()}
                                >
                                    <div className="back-btn f-d f-h-c f-v-c">
                                        <div
                                            className="bg-image-full arrow-left"
                                            style={{ backgroundImage: "url(" + ArrowLeftSvg }}
                                        ></div>
                                    </div>
                                    <span className="body-medium-text">Back</span>
                                </div>
                                <h3 className="skill-title h3-heading-v2">Your skills passport</h3>
                                <div className="validity body-large-text">
                                    Valid till {moment.unix(skillZoneData.subscription.validity).format("lll")}{" "}
                                    <span
                                        className="extend c-pointer hide-d hide-m"
                                        onClick={() => setIsModalVisible(!isModalVisible)}
                                    >
                                        {subscriptionStatus === 1 ? "Extend" : "Renew"}
                                    </span>
                                </div>

                                <PassportContainer className="passport-book-container f-d f-h-c f-vt f-v-c">
                                    <>
                                        <HTMLFlipBook
                                            width={flipPageWidth}
                                            height={750}
                                            drawShadow={false}
                                            showCover={true}
                                            ref={filpBookRef}
                                            onFlip={handleOnFlip}
                                        >
                                            {/* <div className="page page-0 "></div> */}
                                            <div className="page page-1 page-cover-top" data-density="hard">
                                                <div className="page-content">
                                                    <div className="title text-c-d">SKILLS PASSPORT</div>
                                                    <div className="desc text-c-d">
                                                        ALL ACCESS PASS TO FACE PREP SKILL ZONE
                                                    </div>
                                                    <div className="pass-logo-box f-d f-h-c">
                                                        <div className="pass-logo f-d f-h-c f-v-c">
                                                            <span
                                                                className="face-img"
                                                                style={{
                                                                    backgroundImage: "url(" + FaceLogoSvg + ")",
                                                                }}
                                                            ></span>
                                                        </div>
                                                    </div>
                                                    <div className="body-medium-text footer text-c-d">
                                                        COMPREHENSIVE INTERVIEW READINESS INDICATOR
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Personal Info Page Starts */}
                                            <div className="page page-2" data-density="hard">
                                                <Row className="user-info">
                                                    <Col md={12} className="user-info-left">
                                                        <div className="info">
                                                            <div className="title">FULL NAME</div>
                                                            <div className="value">
                                                                {jwtData.usr ? jwtData.usr : "NA"}
                                                            </div>
                                                        </div>
                                                        <div className="info">
                                                            <div className="title">COLLEGE NAME</div>
                                                            <div className="value">
                                                                {jwtData.college_name ? jwtData.college_name : "NA"}
                                                            </div>
                                                        </div>
                                                        <div className="info">
                                                            <div className="title">YEAR OF GRADUATION</div>
                                                            <div className="value">
                                                                {jwtData.yop ? jwtData.yop : "NA"}
                                                            </div>
                                                        </div>
                                                        <div className="info">
                                                            <div className="title">BRANCH/STREAM</div>
                                                            <div className="value">
                                                                {jwtData.stream ? jwtData.stream : "NA"}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col md={12} className="user-info-right">
                                                        <div className="user-image-box">
                                                            <div
                                                                className="user-image bg-image-full"
                                                                style={{
                                                                    backgroundImage: "url(" + jwtData.picture + ")",
                                                                }}
                                                            ></div>
                                                        </div>
                                                        <div className="info">
                                                            <div className="title">DEGREE</div>
                                                            <div className="value">
                                                                {jwtData.deg ? jwtData.deg : "NA"}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            {/* |||| Personal Info Page ENDS |||| */}
                                            {/* @@@@ Verified Skill Page Starts @@@@ */}
                                            <div className="page page-3" data-density="hard">
                                                <div className="page-header">
                                                    <h4 className="h4-heading-v2">Verified skills</h4>
                                                    <div className="desc">
                                                        Verified by FACE Prep based on proficiency and assessment
                                                        results
                                                    </div>
                                                </div>
                                                <Row className="verified-skills-grid">
                                                    <Col md={12} className="left-pane">
                                                        <Popover
                                                            content={PopoverContent("Quantitative Aptitude")}
                                                            placement={"bottom"}
                                                        >
                                                            <div className="skill-box f-d f-vt f-h-c f-v-c">
                                                                <CertificateSvg
                                                                    color={getVerifiedSkillsSVGColor(
                                                                        verifiedSkills["Quantitative Aptitude"]
                                                                    )}
                                                                />
                                                                <div className="body-regular course-title">
                                                                    <span className="f-d f-h-c">Quantitative </span>
                                                                    <span className="f-d f-h-c">Aptitude</span>
                                                                </div>
                                                            </div>
                                                        </Popover>

                                                        <Popover
                                                            content={PopoverContent("Logical Reasoning")}
                                                            placement={"bottom"}
                                                        >
                                                            <div className="skill-box f-d f-vt f-h-c f-v-c">
                                                                <CertificateSvg
                                                                    color={getVerifiedSkillsSVGColor(
                                                                        verifiedSkills["Logical Reasoning"]
                                                                    )}
                                                                />
                                                                <div className="body-regular course-title">
                                                                    <span className="f-d f-h-c">Logical</span>
                                                                    <span className="f-d f-h-c">reasoning</span>
                                                                </div>
                                                            </div>
                                                        </Popover>

                                                        <Popover
                                                            content={PopoverContent("Data Structure and Algorithms")}
                                                            placement={"bottom"}
                                                        >
                                                            <div className="skill-box f-d f-vt f-h-c f-v-c">
                                                                <CertificateSvg
                                                                    color={getVerifiedSkillsSVGColor(
                                                                        verifiedSkills["Data Structure and Algorithms"]
                                                                    )}
                                                                />
                                                                <div className="body-regular course-title">
                                                                    <span className="f-d f-h-c">
                                                                        Data structures and{" "}
                                                                    </span>
                                                                    <span className="f-d f-h-c">algorithms</span>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    </Col>
                                                    <Col md={12} className="right-pane">
                                                        <Popover
                                                            content={PopoverContent("Verbal Ability")}
                                                            placement={"bottom"}
                                                        >
                                                            <div className="skill-box f-d f-vt f-h-c f-v-c">
                                                                <CertificateSvg
                                                                    color={getVerifiedSkillsSVGColor(
                                                                        verifiedSkills["Verbal Ability"]
                                                                    )}
                                                                />
                                                                <div className="body-regular course-title">
                                                                    <span className="f-d f-h-c"> Verbal </span>
                                                                    <span className="f-d f-h-c"> Ability</span>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                        <Popover
                                                            content={PopoverContent("Programming Fundamentals")}
                                                            placement={"bottom"}
                                                        >
                                                            <div className="skill-box f-d f-vt f-h-c f-v-c">
                                                                <CertificateSvg
                                                                    color={getVerifiedSkillsSVGColor(
                                                                        verifiedSkills["Programming Fundamentals"]
                                                                    )}
                                                                />
                                                                <div className="body-regular course-title">
                                                                    <span className="f-d f-h-c">Foundations of </span>
                                                                    <span className="f-d f-h-c">programming</span>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                        <div className="skill-box f-d f-vt f-h-c f-v-c"></div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            {/* |||| Verified Skills Page ENDS |||| */}
                                            {/* @@@@ Certicate Page Starts @@@@ */}
                                            <div className="page certificate-page page-4" data-density="hard">
                                                {renderCerticateHeader}
                                                {renderCertificatesBlocks(0, 3)}
                                            </div>
                                            <div className="page certificate-page page-5" data-density="hard">
                                                {renderCerticateHeader}
                                            </div>
                                            <div className="page certificate-page page-6" data-density="hard">
                                                {renderCerticateHeader}
                                            </div>
                                            {/* |||| Certicate Page ENDS |||| */}
                                            {/* @@@@ Proficiency Page Starts @@@@ */}
                                            <div className="page proficiency-overall-page " data-density="hard">
                                                <div className="page-header">
                                                    <h4 className="h4-heading-v2">Proficiency</h4>
                                                    <div className="desc">Overall</div>
                                                </div>
                                                {!loading && renderProficiencyOverall({})}
                                            </div>
                                            <div className="page proficiency-overall-page" data-density="hard">
                                                <div className="page-header">
                                                    <h4 className="h4-heading-v2">Proficiency</h4>
                                                    <div className="desc">Aptitude drill-down</div>
                                                    {!loading && renderProficiencyOverall({ type: "l0Tag", index: 0 })}
                                                </div>
                                            </div>
                                            {!loading ? renderProficiencyTags(0) : <></>}
                                        </HTMLFlipBook>
                                        <div className="page-number-footer f-d f-h-c f-v-c">
                                            <div
                                                className="back-btn f-d f-h-c f-v-c"
                                                onClick={() => filpBookRef.current.pageFlip().flipPrev()}
                                            >
                                                <div
                                                    className="bg-image-full arrow-left"
                                                    style={{ backgroundImage: "url(" + ArrowLeftSvg }}
                                                ></div>
                                            </div>
                                            <div className="page-info f-d f-h-c">{pageInfo}</div>
                                            <div
                                                className="next-btn f-d f-h-c f-v-c"
                                                onClick={() => filpBookRef.current.pageFlip().flipNext()}
                                            >
                                                <div
                                                    className="bg-image-full arrow-left"
                                                    style={{ backgroundImage: "url(" + ArrowLeftSvg }}
                                                ></div>
                                            </div>
                                        </div>
                                    </>
                                </PassportContainer>
                            </div>
                            <div className="f-d f-h-c btn-container">
                                <div
                                    className="edit-profile-btn body-small f-d f-h-c f-v-c c-pointer"
                                    onClick={() => (window.location.href = `${G_HOME_URL}/profile/edit`)}
                                >
                                    Edit Profile
                                </div>
                            </div>
                        </div>

                        <Modal
                            className="subscription-modal"
                            title={null}
                            destroyOnClose={true}
                            footer={null}
                            visible={isModalVisible}
                            onCancel={() => setIsModalVisible(false)}
                        >
                            <Subscription content={skillPlans} subStatus={subscriptionStatus} fromStatus={"modal"} />
                        </Modal>
                    </>
                ) : (
                    <div className="f-d f-v-c f-h-c">
                        <Spinner />
                    </div>
                )}
            </Layout>
            <style jsx={"true"}>
                {`
                    .skills-passport-wrapper {
                        margin-top: 8rem;
                        padding-bottom: 4rem;
                    }

                    .passport-wrapper {
                        padding: 48px;
                        background-color: #ddf6fd;
                        border-radius: 8px;
                    }

                    .passport-wrapper .back-navigation .back-btn,
                    .page-number-footer .back-btn,
                    .page-number-footer .next-btn {
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        background: var(--dove);
                        border: var(--peaky-border);
                        margin-right: 8px;
                        cursor: pointer;
                    }

                    .passport-wrapper .back-navigation .back-btn .arrow-left,
                    .page-number-footer .back-btn .arrow-left,
                    .page-number-footer .next-btn .arrow-left {
                        width: 12px;
                        height: 12px;
                    }

                    .page-number-footer .next-btn .arrow-left {
                        transform: rotate(180deg);
                    }

                    .page-number-footer {
                        width: 100%;
                    }

                    .page-number-footer .page-info {
                        width: 16%;
                    }

                    .passport-wrapper .skill-title {
                        margin-top: 16px;
                        margin-bottom: 8px;
                    }

                    .passport-wrapper .validity .extend {
                        text-decoration: underline;
                    }

                    .subscription-modal {
                        width: 100vw !important;
                        height: 100vh;
                        padding: 0;
                        margin: 0;
                        top: 0;
                    }

                    .subscription-modal .ant-modal-content {
                        height: inherit;
                        width: inherit;
                    }

                    .subscription-modal .ant-modal-body {
                        height: inherit;
                        background: var(--bg-black);
                        border-radius: 0;
                    }

                    .subscription-modal .ant-modal-close {
                        color: var(--dove);
                        background: rgba(255, 255, 255, 0.1);
                        border-radius: var(--peaky-br-full);
                        margin: 2rem;
                    }

                    .subscription-modal .ant-modal-close:hover {
                        color: var(--facered);
                    }

                    .btn-container {
                        margin-top: 32px;
                    }

                    .edit-profile-btn {
                        height: 64px;
                        color: var(--dove);
                        width: 200px;
                        background-color: var(--carbon);
                        border-radius: var(--peaky-br-4);
                    }

                    .ant-popover-arrow {
                        border-color: var(--carbon-shade-1) !important;
                    }

                    .ant-popover-inner {
                        background-color: var(--carbon-shade-1) !important;
                    }

                    .ant-popover-title {
                        border-bottom: unset !important;
                        color: var(--dove) !important;
                        padding: 24px 24px 0px 24px !important;
                        font-size: 24px !important;
                    }

                    .ant-popover-inner-content {
                        color: var(--dove) !important;
                        padding: 24px 24px 24px 24px !important;
                        font-size: 16px;
                        font-weight: 300;
                    }

                    @media only screen and (max-device-width: 760px) {
                        .passport-wrapper {
                            padding: 24px;
                        }

                        .passport-wrapper .skill-title {
                            font-size: 32px;
                        }

                        .passport-wrapper .validity {
                            font-size: 16px;
                        }

                        .subscription-modal {
                            max-width: unset;
                        }

                        .subscription-modal .ant-modal-content {
                            height: unset;
                        }

                        .subscription-container.modal {
                            padding: 0 0 2rem 0 !important;
                        }

                        .ant-modal-wrap {
                            bottom: unset;
                        }

                        .subscription-modal .subscription-container .plans-container .plan-block {
                            height: 170px;
                        }

                        .subscription-modal .subscription-container .plan-block:last-child::before {
                            top: -21px;
                        }

                        .passport-book-container {
                            zoom: 0.8;
                        }

                        .passport-book-container .ant-row.user-info::before,
                        .passport-book-container .ant-row.user-info::after,
                        .passport-book-container .ant-row.verified-skills-grid::before,
                        .passport-book-container .ant-row.verified-skills-grid::after {
                            display: none;
                        }

                        .passport-book-container .ant-row.user-info,
                        .passport-book-container .ant-row.verified-skills-grid {
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                        }
                    }
                `}
            </style>
        </>
    );
};

export default SkillsPassport;
