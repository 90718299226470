import React, { useState } from "react";
import clock_icon from "../../assets/icons/svg_icons/clock.svg";
import projects_icon from "../../assets/icons/svg_icons/projects.svg";
import labs_icon from "../../assets/icons/svg_icons/labs.svg";
import styled from "styled-components";

const LearnCardContainer = styled.div`
    background-color: var(--dove);
    border-radius: 4px;
    margin-bottom: 32px;

    .card-hero-container {
        padding: 24px 32px;

        .card-title-group {
            position: relative;

            .card-title {
                color: var(--carbon);
                font-family: "Nunito sans", sans-serif !important;
                width: 90%;
            }

            .card-icon {
                width: 24px;
                height: 24px;
            }

            .icon {
                color: var(--carbon);
                font-size: 24px;
            }

            .icon-chevron-down {
                position: absolute;
                top: 0px;
                right: 0px;
                transform: ${(props) => (props.isOpen ? "rotate(0deg)" : "rotate(180deg)")};
                transition: all 0.4s;
            }
        }
        /* End for card-title-group */

        .card-meta {
            margin-top: 16px;
            .card-icon {
                width: 18px;
                height: 18px;
                color: var(--granite);
                background-image: url(${(props) => props.metaIcon});
            }

            .desc {
                color: var(--granite);
                margin-left: 8px;
            }
        }
    }
    /* End for card-hero-group */

    .card-body-container {
        padding: 32px;
        background-color: #f5f6fa;
        color: var(--carbon-light);
        border-radius: 0px 0px 4px 4px;
        display: ${(props) => (props.isOpen ? "block" : "none")};
        transition: opacity 1s ease-out;
        opacity: ${(props) => (props.isOpen ? 1 : 0)};
    }
`;

const CourseLearnCard = (props) => {
    const [isOpen, setisOpen] = useState(false);
    let meta_data = {
        icon: null,
        text: "",
    };

    if (props.duration) {
        meta_data["icon"] = clock_icon;
        meta_data["text"] = props.duration;
    }

    if (props.projects) {
        meta_data["icon"] = projects_icon;
        meta_data["text"] = props.projects;
    }
    if (props.labs) {
        meta_data["icon"] = labs_icon;
        meta_data["text"] = props.labs;
    }

    return (
        <LearnCardContainer
            className="c-pointer"
            isOpen={isOpen}
            onClick={() => setisOpen(!isOpen)}
            metaIcon={meta_data["icon"]}
        >
            <div className="card-hero-container">
                <div className="card-title-group f-d">
                    <div className="card-title h6-heading-v2">{props.title}</div>
                    <div className="card-icon">
                        <span className="icon icon-chevron-down"></span>
                    </div>
                </div>
                <div className="card-meta f-d f-v-c">
                    <div className="card-icon bg-image-full"></div>
                    <div className="desc body-small-text">{meta_data["text"]}</div>
                </div>
            </div>
            <div className="card-body-container body-medium-text">
                {props.topicSchedule.schedule_1.topic}
            </div>
        </LearnCardContainer>
    );
};

export default CourseLearnCard;
