import React from "react";

const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div className={className} style={{ ...style, display: "block" }} onClick={onClick}>
            <i className="icon icon-arrow-right"></i>
        </div>
    );
};

export default CustomNextArrow;
