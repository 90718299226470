import keys from "../config/keys";
import axios from "axios";
import queryString from "query-string";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { __getCookie, __setCookie } from "./cookie.util";
import { G_API_URL } from "../constants/constants";
import { check_login } from "./login.util";

// convert time into string
const convert_time_into_string = (time_in_sec) => {
    var seconds = time_in_sec % 60;
    var minutes = Math.floor(time_in_sec / 60);
    var hours = Math.floor(minutes / 60);
    var days = -1;
    var weeks = -1;
    var months = -1;
    if (hours > 24) {
        days = Math.floor(hours / 24);
        hours %= 24;
    }
    if (days >= 7) {
        weeks = Math.floor(days / 7);
        days %= 7;
    }
    if (weeks >= 5) {
        months = Math.floor(weeks / 5);
        weeks %= 5;
    }
    if (minutes >= 60) minutes %= 60;

    var time_string = "";

    if (months !== -1) {
        time_string += months + "m ";
    }
    if (weeks !== -1) {
        time_string += weeks + "w ";
    }
    if (days !== -1) time_string += days + "d ";
    if (hours !== 0) time_string += hours + "h ";
    if (minutes !== 0) time_string += minutes + "m ";
    if (seconds === 0) {
        if (minutes === 0 && hours === 0) time_string += seconds + "s";
    } else time_string += seconds + "s";

    return time_string;
};

const arr_diff = (arr1, arr2) => {
    let a = [],
        diff = [];
    for (let i = 0; i < arr1.length; i++) {
        a[arr1[i]] = true;
    }
    for (let i = 0; i < arr2.length; i++) {
        if (a[arr2[i]]) {
            delete a[arr2[i]];
        } else {
            a[arr2[i]] = true;
        }
    }
    for (let k in a) {
        diff.push(k);
    }
    return diff;
};

//flat() alternative, as flat() is only available on some browsers
const custom_flat = (input, depth = 1, flat_arr = []) => {
    for (let item of input) {
        if (item instanceof Array && depth > 0) {
            custom_flat(item, depth - 1, flat_arr);
        } else {
            flat_arr.push(item);
        }
    }
    return flat_arr;
};

const getInitials = (name) => {
    if (name !== undefined && name !== null) {
        function makeInitialsFromSingleWord(word) {
            if (word.length > 1) {
                return word.substr(0, 2).toUpperCase();
            }
            return word.toUpperCase();
        }

        var words = name.split(" ");
        if (words.length >= 2) {
            return (words[0][0] + words[1][0]).toUpperCase();
        }

        return makeInitialsFromSingleWord(name);
    }
    return "";
};

/*
        ******* @@@@ check_integer Fn() @@@@ *******
        Functionalities: 
                        Number.isInteger() is num => is integer return true else fale
                        if false Fix the decimal places to 2
*/

const check_integer = (num) => {
    if (Number.isInteger(num)) {
        return num;
    } else {
        return num.toFixed(2);
    }
};

const getSearchParam = (param) => {
    const urlParams = new URLSearchParams(window.location.search);
    let paramValue = urlParams.get(param);
    return paramValue;
};

const WebpIsSupported = () => {
    let res = true;
    if (!window.createImageBitmap) {
        res = false;
    } else {
        let webpdata = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=";
        fetch(webpdata)
            .then(function(response) {
                return response.blob();
            })
            .then(function(blob) {
                createImageBitmap(blob).then(
                    function() {
                        res = true;
                    },
                    function() {
                        res = false;
                    }
                );
            });
    }
    return res;
};

const recordReferralUrl = async (refParam, decodedToken) => {
    try {
        setTimeout(() => {
            // Save refParam in cookie
            if (refParam !== null && refParam !== "") {
                // Cookie
                // __setCookie(keys.cookiePrefix + "ref", refParam, 3, "day");
                sessionStorage.setItem("ref", refParam);
            }

            const windLoc = window.location;
            if (check_login()) {
                var decodedToken = jwtDecode(__getCookie(keys.cookiePrefix + "ut").cookieValue);
            }
            const userId = decodedToken !== undefined ? decodedToken.uid : "";

            const config = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            };

            const data = {
                rid: refParam.trim(),
                url: windLoc.href,
                page: windLoc.pathname === "/" ? "/home" : windLoc.pathname,
                visitorUid: userId,
            };

            axios
                .post(G_API_URL + `tracker/referral/url`, queryString.stringify(data), config)
                .then((response) => {
                    console.log("(◔‿◔)");
                })
                .catch((err) => {
                    console.log(err);
                });
        }, 2000);
    } catch (e) {
        throw e;
    }
};

const recordSkillZoneSource = async (szs) => {
    let szsCookie = __getCookie(keys.cookiePrefix + "szs").cookieValue;
    if (!szsCookie) {
        let ts = getTimestamp();
        __setCookie(keys.cookiePrefix + "szs", szs, 7, "day");
        __setCookie(keys.cookiePrefix + "szt", ts, 7, "day");
    }
};

const calculateTimeDiff = () => {
    let today = moment();
    let tomorrow = moment()
        .add(1, "d")
        .startOf("D");

    let timeDiff = tomorrow.diff(today, "seconds");
    return timeDiff * 1000;
};

const getTimestamp = () => {
    return parseInt(new Date().getTime() / 1000);
};

const getMarketingModalData = () => {
    let marketing_modal_show_ts = null;
    let marketing_modal_filled = null;
    let marketing_modal_filled_show_ts = null;

    if (localStorage) {
        marketing_modal_show_ts = localStorage.getItem("marketing_modal_show_ts");
        marketing_modal_filled_show_ts = localStorage.getItem("marketing_modal_filled_show_ts");
        marketing_modal_filled = localStorage.getItem("marketing_modal_filled");
    }

    return { marketing_modal_show_ts, marketing_modal_filled, marketing_modal_filled_show_ts };
};

const setMarketingModalData = (data) => {
    if (data && localStorage) {
        for (const [key, value] of Object.entries(data)) {
            if (value && key) {
                localStorage.setItem(key, value);
            }
        }
    }
};

export {
    convert_time_into_string,
    getInitials,
    check_integer,
    getSearchParam,
    arr_diff,
    custom_flat,
    WebpIsSupported,
    recordReferralUrl,
    calculateTimeDiff,
    getTimestamp,
    recordSkillZoneSource,
    getMarketingModalData,
    setMarketingModalData,
};
