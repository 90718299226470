import React from "react";
import styled from "styled-components";
import DeliverySvg from "./../../assets/icons/svg_icons/delivery_mode.svg";
import DurationSvg from "./../../assets/icons/svg_icons/duration.svg";
import CourseValidSvg from "./../../assets/icons/svg_icons/validity.svg";
import TargetSvg from "./../../assets/icons/svg_icons/target.svg";

let icons = [DeliverySvg, DurationSvg, CourseValidSvg, TargetSvg];
let cardBgColors = ["#fef2db", "#FFDADA", "#DDF6FD", "#DCF7E8"];

const OverviewCardContainer = styled.div`
    height: auto;
    min-height: 250px;
    padding: 32px;
    background-color: ${(props) => props.bgColor};
    border-radius: 4px;

    .card-icon {
        background-image: url(${(props) => props.cardIcon});
        width: 30px;
        height: 30px;
        margin-bottom: 24px;
    }

    .card-header {
        font-family: "Nunito sans", sans-serif !important;
        color: var(--carbon);
        margin-bottom: 12px;
    }
`;

const OverviewCard = ({ data, idx }) => {
    idx = parseInt(idx.split("_")[1]) - 1;
    let bgColor = cardBgColors[idx];
    let cardIcon = icons[idx];

    return (
        <OverviewCardContainer bgColor={bgColor} cardIcon={cardIcon}>
            <div className="card-icon bg-image-full"></div>
            <div className="card-header h6-heading-v2">{data.title}</div>
            <div className="card-desc body-normal-text">{data.description}</div>
        </OverviewCardContainer>
    );
};

export default OverviewCard;
