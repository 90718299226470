import "polyfill-library-node";
import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./index.css";
import "antd/dist/antd.min.css";
import "./custom-icons.css";
import Spinner from "./components/Spinner/spinner";
import ReactGA from "react-ga";
import GoogleTagManager from "react-gtm-module";
import { GA_TRACK_ID, GTM_TRACK_ID } from "./constants/constants";
import { Provider } from "react-redux";
import { store, persistor } from "./config/store";
import { PersistGate } from "redux-persist/integration/react";
// import keys from "./config/keys";
// import { __getCookie } from "./utils/cookie.util";
import { getSearchParam, recordReferralUrl, recordSkillZoneSource } from "./utils/common.util";
import * as serviceWorker from "./serviceWorker";
import RequireAdminAuth from "./components/Auth/App";
import Courses from "./pages/Videos/courses";
import SSOLogout from "./pages/SSOLogin/SSOLogout";
import PremiumLandingPage from "./pages/Premium/PremiumLandingPage";
import { render } from "react-dom";
import ScrollToTop from "./utils/scrollToTop";
import * as Sentry from "@sentry/browser";
import { check_login } from "./utils/login.util";
import Downloadables from "./pages/Downloadables";
import ArticleDetails from "./pages/Articles/ArticleDetails";
import CourseDetail from "./pages/Course/CourseDetail";
import WithSkillzoneHoc from "./components/Hocs/skillZoneHoc";
import WithFaceproHoc from "./components/Hocs/faceproHoc";
import SkillsPassport from "./components/SkillZone/Passport/passport";
import ProDashboard from "./pages/FacePrepPro/dashboard/ProDashboard";

const TCS = lazy(() => retry(() => import("./pages/Program/ProgramTCS")));
const Login = lazy(() => retry(() => import("./pages/Authentication/Login")));
const Signup = lazy(() => retry(() => import("./pages/Authentication/Signup")));
const AdCardCMS = lazy(() => retry(() => import("./pages/CMS/AdCard/Creator/AdCard")));
const AdCardCMSv2 = lazy(() => retry(() => import("./pages/CMS/AdCard/Creator/AdCardv2")));
const Home = lazy(() => retry(() => import("./pages/Home/Home")));
const HomeV2 = lazy(() => retry(() => import("./pages/HomeV2/Home")));
const HomeLegacy = lazy(() => retry(() => import("./pages/Home/HomeLegacy")));
const CreatePassword = lazy(() => retry(() => import("./pages/Authentication/CreatePassword")));
const ChangePassword = lazy(() => retry(() => import("./pages/Authentication/ChangePassword")));
const ResetPassword = lazy(() => retry(() => import("./pages/Authentication/ResetPassword")));
const ExtraDetailsV2 = lazy(() => retry(() => import("./pages/Authentication/v2/ExtraDetailsV2.js")));
const ExtraDetails = lazy(() => retry(() => import("./pages/Authentication/ExtraDetails")));
const HomeAdminPanel = lazy(() => retry(() => import("./pages/Home/HomeAdminPanel")));
const WebinarAdminPanel = lazy(() => retry(() => import("./pages/Webinar/WebinarAdminPanel")));
const ConfirmWebinar = lazy(() => retry(() => import("./pages/Webinar/Confirm/ConfirmWebinar")));
const Webinar = lazy(() => retry(() => import("./pages/Webinar/Webinar")));
const WebinarLanding = lazy(() => retry(() => import("./pages/Webinar/WebinarLanding")));
const Prograd = lazy(() => retry(() => import("./pages/Prograd/Prograd")));
const ProgradInfo = lazy(() => retry(() => import("./pages/Prograd/Info/info")));
const Table = lazy(() => retry(() => import("./pages/CMS/AdCard/Listing/Table")));
const Videos = lazy(() => retry(() => import("./pages/PlayList/videos")));
const Program = lazy(() => retry(() => import("./pages/Program/Program")));
const ProgradForm = lazy(() => retry(() => import("./pages/Prograd/Register/progradReg")));
const FormSuccess = lazy(() => retry(() => import("./pages/Prograd/Register/FormSucess")));
const HirePrograd = lazy(() => retry(() => import("./pages/Prograd/Hire/HirePrograd")));
const PaymentSucess = lazy(() => retry(() => import("./pages/RazorPay/PaymentSuccess")));
const Placement = lazy(() => retry(() => import("./pages/Placement-Prep/placement")));
const Contact = lazy(() => retry(() => import("./pages/Contact/Contact")));
const Terms = lazy(() => retry(() => import("./pages/Terms/Terms")));
const Policy = lazy(() => retry(() => import("./pages/Policy/Policy")));
const CheckReview = lazy(() => retry(() => import("./pages/Prograd/ProfileValidate/checkReview")));
const Articles = lazy(() => retry(() => import("./pages/Articles/Articles")));
const PracticeMockTest = lazy(() => retry(() => import("./pages/PracticeMockTest/PracticeMockTest")));
const SSOLogin = lazy(() => retry(() => import("./pages/SSOLogin/SSOLogin")));
const NanMudhalvanSSO = lazy(() => retry(() => import("./pages/SSOLogin/NanMudhalvanSSO")));
const TagView = lazy(() => retry(() => import("./pages/TagView/TagView")));
const TestLanding = lazy(() => retry(() => import("./pages/PracticeMockTest/TestLanding")));
const TestReport = lazy(() => retry(() => import("./pages/PracticeMockTest/TestReport")));
const Affiliate = lazy(() => retry(() => import("./pages/Affiliate/affiliate")));
const AffiliateSales = lazy(() => retry(() => import("./pages/Affiliate/Sales/sales")));
const AffiliateCoupon = lazy(() => retry(() => import("./pages/Affiliate/Sales/couponSales")));
const AffiliateClicks = lazy(() => retry(() => import("./pages/Affiliate/Clicks/clicks")));
const AffiliateManagerSales = lazy(() => retry(() => import("./pages/Affiliate/Manager/purchases")));
const AffiliateManagerClicks = lazy(() => retry(() => import("./pages/Affiliate/Manager/clicks")));
const Profile = lazy(() => retry(() => import("./pages/Profile/Profile")));
const EditProfile = lazy(() => retry(() => import("./pages/Profile/EditProfile")));
const NewsFeedManager = lazy(() => retry(() => import("./pages/Profile/NewsFeedManager")));
const Classroom = lazy(() => retry(() => import("./pages/classroom/classroom")));
const ProgramBootCamp = lazy(() => retry(() => import("./pages/Program/ProgramBootCamp")));
const AffiliateManagerDashboard = lazy(() => retry(() => import("./pages/Affiliate/Manager/dashboard")));
const AffiliateManagerCouponSales = lazy(() => retry(() => import("./pages/Affiliate/Manager/couponSales")));
const ExternalLogin = lazy(() => import("./pages/Authentication/ExternalLogin"));
const TrainHire = lazy(() => import("./pages/Train_Hire/train_hire"));
const TrainRegForm = lazy(() => import("./pages/Train_Hire/train_apply"));
const WhatsApp = lazy(() => import("./pages/Whatsapp/Thoughtworks"));
const PoisonLink = lazy(() => import("./pages/Premium/PoisonLink"));
// const Edge = lazy(() => import("./pages/Edge/SocialShare"));
const Edgev2 = lazy(() => import("./pages/Edge/v2/Edge"));
const EdgeSubscribe = lazy(() => import("./pages/Edge/v2/EdgeSubscribe"));

// const MasterClass = lazy(() => import("./pages/Masterclass/masterclass"));
const EdgeCreator = lazy(() => import("./pages/Edge/creator"));
const CompanyCorner = lazy(() => import("./pages/CompanyCorner/CompanyCorner"));
const AllCourses = lazy(() => import("./pages/CompanyCorner/AllCourses"));
const Testimonials = lazy(() => import("./pages/Testimonials/Testimonials"));
const PlacementTrainingSSO = lazy(() => retry(() => import("./pages/SSOLogin/PlacementTraningSSO")));
const Onboard = lazy(() => import("./components/Onboard/Onboard"));

// SkillZone Imports
const SkillZone = lazy(() => import("./pages/SkillZone/SkillZone"));
const SkillBoard = lazy(() => import("./pages/SkillBoard/skillBoard"));
const CategoryLanding = lazy(() => import("./pages/SkillZone/CategoryLanding"));
const MarketLanding = lazy(() => import("./pages/SkillZone/MarketLanding"));
const Practice = lazy(() => import("./pages/Practice/Practice"));
const PracticeReport = lazy(() => import("./pages/Practice/PracticeReport"));
const Certificate = lazy(() => import("./pages/Certificate/certificate_v2"));

// Study Abroad
const StudyAbroad = lazy(() => import("./pages/StudyAbroad/StudyAbroad"));

const NMRegistration = lazy(() => import("./pages/NM/NMRegistration"));
const NMLive = lazy(() => import("./pages/NM/NMLive"));
const NMAdmin = lazy(() => import("./pages/NM/Admin/NMAdmin"));

// Checkout Page
const CheckoutDetails = lazy(() => retry(() => import("./pages/Checkout/CheckoutDetails")));
const PaymentCheckoutSuccess = lazy(() => retry(() => import("./pages/Checkout/PaymentCheckoutSuccess")));
const FacePrepPro = lazy(() => import("./pages/FacePrepPro/FacePrepPro"));
const UPP = lazy(() => import("./pages/UPP/UPP"));
const Review = lazy(() => import("./pages/Review/review"));

if (check_login() && process.env.NODE_ENV === "production") {
    Sentry.init({
        environment: process.env.NODE_ENV,
        dsn: "https://c85b1e91dd404b7d86bfd4c2a8c4f07c@sentry.io/2625322",
    });
}

// ******** @@@@ retry function() @@@@ ********
//If the browser fails to download the module, it'll try again 5 times with a 1 second delay between each attempt.
//If even after 5 tries it import it, then an error is thrown.
function retry(fn, retriesLeft = 5, interval = 1000) {
    return new Promise((resolve, reject) => {
        fn()
            .then(resolve)
            .catch((error) => {
                setTimeout(() => {
                    if (retriesLeft === 1) {
                        // reject('maximum retries exceeded');
                        reject(error);
                        return;
                    }
                    // Passing on "reject" is the important part
                    retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
            });
    });
}

function App() {
    useEffect(() => {
        // Get user info if available
        // let userEmail =
        //     __getCookie(keys.cookiePrefix + "email").cookieValue != null
        //         ? __getCookie(keys.cookiePrefix + "email").cookieValue
        //         : "";
        // let userName =
        //     __getCookie(keys.cookiePrefix + "user_name").cookieValue != null
        //         ? __getCookie(keys.cookiePrefix + "user_name").cookieValue
        //         : "";

        // Initialize Mautic
        // const script = document.createElement("script");
        // script.type = "text/javascript";
        // script.async = true;
        // script.innerHTML =
        //     "(function(w,d,t,u,n,a,m){w['MauticTrackingObject']=n;\n" +
        //     "w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)},a=d.createElement(t),\n" +
        //     "m=d.getElementsByTagName(t)[0];a.async=1;a.src=u;m.parentNode.insertBefore(a,m)\n" +
        //     "})(window,document,'script','https://mautic.faceprep.in/mtc.js','mt');\n" +
        //     "mt('send', 'pageview', {\n" +
        //     "email:'" +
        //     userEmail +
        //     "',\n" +
        //     "firstname:'" +
        //     userName +
        //     "'\n" +
        //     "});\n";
        // document.body.appendChild(script);

        // Initialize GTM
        const tagManagerArgs = {
            gtmId: GTM_TRACK_ID,
        };
        GoogleTagManager.initialize(tagManagerArgs);

        // Initialize GA
        ReactGA.initialize(GA_TRACK_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);

        // Check for referral params in URL
        const refParam = getSearchParam("ref");
        if (refParam !== undefined && refParam !== null) {
            recordReferralUrl(refParam);
        }

        // Check for skillzone source params in URL
        const szsParam = getSearchParam("szs");
        if (szsParam !== undefined && szsParam !== null) {
            recordSkillZoneSource(szsParam);
        }
    }, []);

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Suspense fallback={<Spinner from="suspense" />}>
                    <Switch>
                        <Route
                            path={`${process.env.PUBLIC_URL}/checkout/:productId`}
                            exact
                            component={CheckoutDetails}
                        />

                        <Route
                            path={`${process.env.PUBLIC_URL}/checkout/payment/success`}
                            exact
                            component={PaymentCheckoutSuccess}
                        />

                        {/* Skill Zone Paths */}

                        <Route path={`${process.env.PUBLIC_URL}/skillzone/onboard/`} exact component={Onboard} />

                        <Route
                            path={`${process.env.PUBLIC_URL}/skillzone/certificate/:uid/:slug`}
                            exact
                            component={Certificate}
                        />

                        {/* Skill Zone Paths Ends */}

                        <Route
                            path={`${process.env.PUBLIC_URL}/edge/creator/`}
                            exact
                            component={RequireAdminAuth(EdgeCreator)}
                        />

                        {/* SkillZone Routes */}

                        <WithSkillzoneHoc path={`${process.env.PUBLIC_URL}/skillzone`} exact component={SkillZone} />

                        <WithSkillzoneHoc
                            path={`${process.env.PUBLIC_URL}/skillzone/passport`}
                            exact
                            component={SkillsPassport}
                        />

                        <WithSkillzoneHoc
                            path={`${process.env.PUBLIC_URL}/skillboard/:slug`}
                            exact
                            component={SkillBoard}
                            type={"skillboard"}
                        />

                        <WithSkillzoneHoc
                            path={`${process.env.PUBLIC_URL}/skillzone/:category`}
                            exact
                            component={CategoryLanding}
                        />

                        <Route path={`${process.env.PUBLIC_URL}/skillzone/m/:slug`} exact component={MarketLanding} />

                        <Route
                            path={`${process.env.PUBLIC_URL}/practice/report/:cid/:pid/:uid`}
                            exact
                            component={PracticeReport}
                        />

                        <WithSkillzoneHoc
                            path={`${process.env.PUBLIC_URL}/practice/:cid/:pid`}
                            exact
                            component={Practice}
                        />

                        {/* <Route
                            path={`${process.env.PUBLIC_URL}/masterclass/`}
                            exact
                            component={MasterClass}
                        /> */}

                        <Route
                            path={`${process.env.PUBLIC_URL}/new-article/:pslug/:uslug`}
                            exact
                            component={ArticleDetails}
                        />

                        <Route exact path={`/company-corner/courses/`} component={AllCourses} />

                        <Route
                            path={`${process.env.PUBLIC_URL}/company-corner/:slug/`}
                            exact
                            render={(props) => <ArticleDetails pageType="company" {...props} />}
                        />

                        <Route
                            path={`${process.env.PUBLIC_URL}/self-paced-courses/`}
                            exact
                            render={(props) => <CompanyCorner pageType="generic-course" {...props} />}
                        />

                        <Route exact path={`${process.env.PUBLIC_URL}/reviews`} component={Testimonials} />

                        <Route
                            path={`${process.env.PUBLIC_URL}/course-detail/:course/`}
                            exact
                            component={CourseDetail}
                        />

                        <Route path={`${process.env.PUBLIC_URL}/tcs-codevita/`} exact component={TCS} />
                        <Route path={`${process.env.PUBLIC_URL}/downloadables`} component={Downloadables} />

                        <Route path={`${process.env.PUBLIC_URL}/signup/`} exact component={Signup} />
                        <Route path={`${process.env.PUBLIC_URL}/create-password/`} exact component={CreatePassword} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/user-details/`} exact component={ExtraDetails} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/user-details/`} exact component={ExtraDetailsV2} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/v2/user-details/`} exact component={ExtraDetailsV2} /> */}

                        <Route path={`${process.env.PUBLIC_URL}/login/`} exact component={Login} />
                        <Route path={`${process.env.PUBLIC_URL}/e-login/`} exact component={ExternalLogin} />
                        <Route path={`${process.env.PUBLIC_URL}/change-password/`} exact component={ChangePassword} />
                        <Route path={`${process.env.PUBLIC_URL}/reset-password/`} exact component={ResetPassword} />

                        <Route path={`${process.env.PUBLIC_URL}/profile/`} exact component={Profile} />
                        <Route path={`${process.env.PUBLIC_URL}/edit-profile/`} exact component={EditProfile} />
                        <Route
                            path={`${process.env.PUBLIC_URL}/profile/news/`}
                            exact
                            component={RequireAdminAuth(NewsFeedManager)}
                        />

                        {/* Temporarily removed premium from faceprep */}
                        <Route
                            path={`${process.env.PUBLIC_URL}/premium/:cname/`}
                            exact
                            component={RequireAdminAuth(PremiumLandingPage)}
                        />

                        <Route
                            path={`${process.env.PUBLIC_URL}/premium/`}
                            exact
                            component={RequireAdminAuth(PremiumLandingPage)}
                        />

                        <Route
                            path={`${process.env.PUBLIC_URL}/admin/home/`}
                            exact
                            component={RequireAdminAuth(HomeAdminPanel)}
                        />

                        <Route
                            path={`${process.env.PUBLIC_URL}/admin/webinars/`}
                            exact
                            component={RequireAdminAuth(WebinarAdminPanel)}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL}/webinars/register/success`}
                            exact
                            component={ConfirmWebinar}
                        />
                        <Route path={`${process.env.PUBLIC_URL}/webinars/`} exact component={Webinar} />
                        <Route path={`${process.env.PUBLIC_URL}/webinars/:slug`} exact component={WebinarLanding} />

                        <Route
                            path={`${process.env.PUBLIC_URL}/prograd/pat`}
                            exact
                            component={RequireAdminAuth(CheckReview)}
                        />

                        <Route path={`${process.env.PUBLIC_URL}/classroom`} exact component={Classroom} />

                        <Route path={`${process.env.PUBLIC_URL}/prograd/hire`} exact component={HirePrograd} />

                        <Route
                            path={`${process.env.PUBLIC_URL}/prograd/payment/success/:slug/`}
                            exact
                            component={PaymentSucess}
                        />

                        <Route
                            path={`${process.env.PUBLIC_URL}/prograd/register/:course/:batch_id`}
                            exact
                            component={ProgradForm}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL}/prograd/register/success/:slug/:batch`}
                            component={FormSuccess}
                        />

                        <Route exact path={`${process.env.PUBLIC_URL}/videos/:id`} component={Videos} />
                        <Route path={`${process.env.PUBLIC_URL}/videos/:id/:vid`} component={Videos} />
                        <Route path={`${process.env.PUBLIC_URL}/placement-prep`} exact component={Placement} />
                        <Route path={`${process.env.PUBLIC_URL}/videos`} component={Courses} />

                        <Route
                            path={`${process.env.PUBLIC_URL}/create/premium-link/`}
                            exact
                            component={RequireAdminAuth(PoisonLink)}
                        />

                        {/* <Route
                            path={`${process.env.PUBLIC_URL}/cms/adcard/creator/`}
                            exact
                            component={RequireAdminAuth(AdCardCMS)}
                        /> */}

                        <Route
                            path={`${process.env.PUBLIC_URL}/cms/adcard/creator/v2/`}
                            exact
                            component={RequireAdminAuth(AdCardCMSv2)}
                        />

                        <Route
                            path={`${process.env.PUBLIC_URL}/cms/adcard/edit/v2/:alias`}
                            exact
                            component={RequireAdminAuth(AdCardCMSv2)}
                        />

                        <Route
                            path={`${process.env.PUBLIC_URL}/cms/adcard/`}
                            exact
                            component={RequireAdminAuth(Table)}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL}/test-landing/:instance`}
                            exact
                            component={TestLanding}
                        />
                        <Route path={`${process.env.PUBLIC_URL}/test-report/:instance`} exact component={TestReport} />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/prograd/info/`}
                            component={RequireAdminAuth(ProgradInfo)}
                        />

                        <Route path={`${process.env.PUBLIC_URL}/prograd/:name`} exact component={Program} />
                        <Route
                            path={`${process.env.PUBLIC_URL}/prograd-bootcamp/:name`}
                            exact
                            component={ProgramBootCamp}
                        />
                        <Route path={`${process.env.PUBLIC_URL}/articles`} exact component={Articles} />
                        <Route path={`${process.env.PUBLIC_URL}/tests`} exact component={PracticeMockTest} />

                        <Route
                            path={`${process.env.PUBLIC_URL}/ssologin/live-class`}
                            exact
                            component={PlacementTrainingSSO}
                        />

                        <Route
                            path={`${process.env.PUBLIC_URL}/nanmudhalvan-sso/:slug`}
                            exact
                            component={NanMudhalvanSSO}
                        />

                        <Route path={`${process.env.PUBLIC_URL}/ssologin/:instance`} exact component={SSOLogin} />

                        <Route path={`${process.env.PUBLIC_URL}/logout/`} exact component={SSOLogout} />
                        <Route path={`${process.env.PUBLIC_URL}/prograd/:name/:courseid`} exact component={Program} />

                        <Route exact path={`${process.env.PUBLIC_URL}/prograd/`} component={Prograd} />

                        <Route exact path={`${process.env.PUBLIC_URL}/contact/`} component={Contact} />

                        <Route exact path={`${process.env.PUBLIC_URL}/terms-and-conditions/`} component={Terms} />

                        <Route exact path={`${process.env.PUBLIC_URL}/privacy-policy/`} component={Policy} />
                        <Route
                            path={`${process.env.PUBLIC_URL}/affiliate`}
                            exact
                            component={RequireAdminAuth(Affiliate)}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL}/affiliate/sales`}
                            exact
                            component={RequireAdminAuth(AffiliateSales)}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL}/affiliate/coupon`}
                            exact
                            component={RequireAdminAuth(AffiliateCoupon)}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL}/affiliate/clicks`}
                            exact
                            component={RequireAdminAuth(AffiliateClicks)}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL}/affiliate/dashboard`}
                            exact
                            component={AffiliateManagerDashboard}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL}/affiliate/dashboard/sales`}
                            exact
                            component={AffiliateManagerSales}
                        />

                        <Route
                            path={`${process.env.PUBLIC_URL}/affiliate/dashboard/clicks`}
                            exact
                            component={AffiliateManagerClicks}
                        />

                        <Route
                            path={`${process.env.PUBLIC_URL}/affiliate/dashboard/coupon`}
                            exact
                            component={AffiliateManagerCouponSales}
                        />

                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/thoughtworks-developer-program/`}
                            component={TrainHire}
                        />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/thoughtworks-developer-program/apply/`}
                            component={TrainRegForm}
                        />

                        <Route exact path={`${process.env.PUBLIC_URL}/company-corner/`} component={CompanyCorner} />

                        <Route exact path={`${process.env.PUBLIC_URL}/prograd-whatsapp`} component={WhatsApp} />

                        <Route exact path={`${process.env.PUBLIC_URL}/edge`} component={Edgev2} />
                        <Route exact path={`${process.env.PUBLIC_URL}/verify-email/:vslug`} component={EdgeSubscribe} />
                        <Route exact path={`${process.env.PUBLIC_URL}/studyabroad`} component={StudyAbroad} />

                        <Route exact path={`${process.env.PUBLIC_URL}/facepro`} component={FacePrepPro} />
                        <Route exact path={`${process.env.PUBLIC_URL}/ultimate-placement-prep`} component={UPP} />
                        <WithFaceproHoc
                            exact
                            path={`${process.env.PUBLIC_URL}/facepro/dashboard/:slug`}
                            component={ProDashboard}
                        />

                        <Route exact path={`${process.env.PUBLIC_URL}/nm/register`} component={NMRegistration} />
                        <Route exact path={`${process.env.PUBLIC_URL}/nm/live`} component={NMLive} />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/admin/nm/`}
                            component={RequireAdminAuth(NMAdmin)}
                        />

                        <Route exact path={`${process.env.PUBLIC_URL}/review/:slug`} component={Review} />
                        <Route path={`${process.env.PUBLIC_URL}/:slug/`} exact component={TagView} />

                        <Route exact path={`${process.env.PUBLIC_URL}/new/liftoff`} component={Home} />
                        {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomeLegacy} /> */}
                        {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} /> */}
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomeV2} />
                        <Route path={`${process.env.PUBLIC_URL}/:pslug/:uslug`} exact component={ArticleDetails} />
                    </Switch>
                </Suspense>
            </PersistGate>
        </Provider>
    );
}

render(
    <Router>
        <ScrollToTop />
        <App />
    </Router>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
