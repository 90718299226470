import React from "react";
import "./spinner.css";

const Spinner = (props) => {
    let style = {};
    if (props.from === "suspense") {
        style = {
            "position": "fixed",
            "width": "100%",
            "justifyContent": "center"
        }
    }
    return (
        <div className="lds-ring" style={style}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export default Spinner;
