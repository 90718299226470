import styled from "styled-components";

export const ProDashboardView = styled.div`
    .pro-sidebar {
        width: 16%;
        border-right: 1px solid #f0f0f0;
        box-shadow: var(--peaky-shadow-high);
        clip-path: inset(0px -6px 0px 0px);
    }

    .pro-content {
        width: 84%;
        background: var(--smoke-3);
        padding: 32px;
        min-height: 100vh;
    }

    @media only screen and (max-device-width: 768px) {
        .pro-content {
            width: 100%;
        }
    }
`;
