import React, { Component } from "react";
import Layout from "../../components/Layout";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import { G_API_URL, G_HOME_URL } from "../../constants/constants";
import {
    ArticleBody,
    ArticleBodyContainer,
    ArticleHeader,
    ArticleWrapper,
    BreadCrumbsContainer,
    CourseListContainer,
    MeteredContentLogin,
    MeteredShadow,
    SocailShareDiv,
    VideoListContainer,
} from "./ArticleDetails.styles";
import PracticeQuestion from "../../components/Articles/PracticeQuestion";
import ArticleAd, { SideBarAd } from "../../components/Articles/ArticleAd";
import { Skeleton, Tag, Breadcrumb, Row, Col } from "antd";
import { FacebookShare, LinkedinShare, TwitterShare, WhatsAppShare } from "./../../components/MediaShare/MediaShare";
import { Helmet } from "react-helmet";
import { SkeltonCodeSquare } from "../../components/Skelton/skelton";

import GoogleIcon from "../../assets/icons/svg_icons/google_icon.svg";
import FbIcon from "../../assets/icons/social/facebook-2.svg";
import MessageIcon from "./../../assets/icons/svg_icons/Message.svg";

import { Link } from "react-router-dom";
import { check_login } from "../../utils/login.util";
import FollowUs from "../../components/FollowUs/followUs";
import MarketingModalForm from "../../components/Modal/MarketingModal";

const VideoCard = React.lazy(() => import("../../components/Cards/VideoCard"));
const CourseCard = React.lazy(() => import("../../components/CompanyCorner/CourseCard"));
const SocialLoginCard = React.lazy(() => import("../../components/Cards/SocialLoginCard"));
const Coding = React.lazy(() => import("../../components/Articles/Coding"));

class ArticleDetails extends Component {
    state = {
        article_doc: null,
        pageloaded: false,
        type: null,
        question_map: {},
        question_no: 1,
        ad_map: {},
        tag_map: [],
        company_name: null,
        course_data: [],
        restrict: 0,
    };

    fetchArticleContent = async (pslug, uslug, restrict) => {
        try {
            const article_content = await axios.get(G_API_URL + "articles/content/", {
                params: {
                    pslug,
                    uslug,
                    restrict,
                },
            });

            if (article_content && article_content.data && article_content["data"]["data"]) {
                let article_doc = article_content.data.data[0]["article_doc"];
                let type = article_content.data.data[0]["type"];
                let question_map = [];
                let ad_map = {};
                let tag_map = [];

                question_map = article_content.data.data[0]["question_map"];
                ad_map = article_content.data.data[0]["ad_map"];
                tag_map = article_content.data.data[0]["tag_map"];

                if (article_doc.meta_desc) this.changeMetaDescription(article_doc.meta_desc);

                this.setState({
                    article_doc,
                    pageloaded: true,
                    type,
                    question_map,
                    ad_map,
                    tag_map,
                    restrict,
                });
            }
        } catch (error) {}
    };

    fetchCompanyContent = async (slug) => {
        try {
            const article_content = await axios.get(G_API_URL + "articles/content-company/", {
                params: {
                    slug,
                },
            });

            if (article_content && article_content.data && article_content["data"]["data"]) {
                let article_doc = article_content.data.data[0]["article_doc"];
                let type = article_content.data.data[0]["type"];
                let question_map = [];
                let ad_map = {};

                ad_map = article_content.data.data[0]["ad_map"];
                let company_name = article_content.data.data[0]["company_name"];
                let course_data = article_content.data.data[0]["course_data"];

                this.setState({
                    article_doc,
                    pageloaded: true,
                    type,
                    question_map,
                    ad_map,
                    company_name,
                    course_data,
                });
            }
        } catch (error) {}
    };

    // Add Katex CSS

    addKatex = () => {
        let tagsElements = document.getElementsByTagName("head");

        if (tagsElements.length > 0) {
            let headTag = tagsElements[0];
            let itm = document.createElement("link");
            itm.rel = "stylesheet";
            itm.href = "https://cdn.jsdelivr.net/npm/katex@0.16.4/dist/katex.min.css";
            itm.integrity = "sha384-vKruj+a13U8yHIkAyGgK1J3ArTLzrFGBbBc0tDp4ad/EyewESeXE/Iv67Aj8gKZ0";
            itm.crossOrigin = "anonymous";

            headTag.appendChild(itm);
        }
    };

    changeMetaDescription = (content) => {
        const description = document.querySelector('meta[name="description"]');
        if (description) {
            description.content = content;
        }
    };

    componentDidMount = async () => {
        // If user is not login
        this.addKatex();

        const url_params = this.props.match.params;

        if (this.props && this.props.pageType && this.props.pageType === "company") {
            let slug = url_params.slug;
            await this.fetchCompanyContent(slug);
        } else {
            const { pslug, uslug } = url_params;
            // let restrict = 1;
            // Removing the restriction for article pages
            let restrict = 0;
            if (check_login()) restrict = 0;

            // if not logged in
            if (!check_login()) {
                let article_limit_obj = JSON.parse(localStorage.getItem("article_limit"));
                let setData = {
                    slug: [uslug],
                    count: 1,
                };

                // if history is present
                if (article_limit_obj) {
                    let { slug, count } = article_limit_obj;
                    if (!slug.includes(uslug)) {
                        setData = {
                            slug: [uslug, ...slug],
                            count: count + 1,
                        };
                    } else setData = { slug, count };
                }

                if (setData.count >= 3) restrict = 1;
                else restrict = 0;

                localStorage.setItem("article_limit", JSON.stringify(setData));
            }

            // Removing the restriction for article pages
            restrict = 0;
            await this.fetchArticleContent(pslug, uslug, restrict);
        }
    };

    /* ******** @@@@  transformNodes Function  @@@@  ******** 
        Usage: 
            Use to parse HTML Content node recieved from backend
    */

    transformHTMLData = (content) => {
        let match_results = content.match(/@@coding::\d+@@/g);
        let tb_match_results = content.match(/@@table::\d+@@/g);
        let ques_match_results = content.match(/@@question::\w+@@/g);
        let ad_match_results = content.match(/@@ad::\w+@@/g);

        if (ques_match_results) {
            let question_jsx = [];

            ques_match_results.forEach((x_value) => {
                let temp_tag = x_value;
                temp_tag = temp_tag.replace("@@question::", "");
                temp_tag = temp_tag.replace("@@", "");
                let question_id = temp_tag;
                let question_data = this.state.question_map[question_id];
                // console.log(question_data);

                if (question_data && question_data.question_id) {
                    let question_wrapper = <PracticeQuestion {...question_data} key={question_data.question_id} />;
                    question_jsx.push(question_wrapper);
                }
            });

            return question_jsx;
        }

        if (ad_match_results) {
            let adlist_jsx = [];

            ad_match_results.forEach((x_value) => {
                let temp_tag = x_value;
                temp_tag = temp_tag.replace("@@ad::", "");
                temp_tag = temp_tag.replace("@@", "");
                let ad_id = temp_tag;
                if (this.state.ad_map && this.state.ad_map[ad_id]) {
                    let ad_data = this.state.ad_map[ad_id];
                    let adCard_wrapper = <ArticleAd {...ad_data} />;
                    adlist_jsx.push(adCard_wrapper);
                }
            });
            return adlist_jsx;
        }

        if (tb_match_results) {
            let table_jsx = [];
            tb_match_results.forEach((x_value) => {
                let temp_tag = x_value;
                temp_tag = temp_tag.replace("@@table::", "");
                temp_tag = temp_tag.replace("@@", "");
                let table_id = temp_tag;

                let table_data = this.state.article_doc["table_data"][table_id];
                let table_data_wrapper = <div class="table-container">{ReactHtmlParser(table_data)}</div>;
                table_jsx.push(table_data_wrapper);
            });
            return table_jsx;
        }

        if (match_results) {
            let code_mirror_jsx = [];

            match_results.forEach((x_value) => {
                let temp_tag = x_value;
                temp_tag = temp_tag.replace("@@coding::", "");
                temp_tag = temp_tag.replace("@@", "");

                let coding_block_id = temp_tag;

                let coding_data = this.state.article_doc["coding_data"][coding_block_id];

                let code_jsx = (
                    <React.Suspense fallback={<SkeltonCodeSquare width={"50vw"} height={"250px"} />}>
                        <Coding coding_data={coding_data} />
                    </React.Suspense>
                );

                code_mirror_jsx.push(code_jsx);
            });

            return code_mirror_jsx;
        }
    };

    transformNodes = (node) => {
        if (node.children && node.children[0] && node.children[0]["type"] === "text") {
            return this.transformHTMLData(node.children[0]["data"]);
        }

        if (
            node.children &&
            node.children[0] &&
            node.children[0]["children"] &&
            node.children[0]["children"][0] &&
            node.children[0]["children"][0]["data"]
        ) {
            return this.transformHTMLData(node.children[0]["children"][0]["data"]);
        }
    };

    /* |||||||| @@@@  transformNodes ENDs  ||||||||
     */

    renderSkelton = (type) => {
        if (type === "header") {
            return (
                <div style={{ marginBottom: 16 }}>
                    <Skeleton active title={true} paragraph={{ rows: 1 }} />
                    <Skeleton
                        active
                        avatar={{
                            shape: "square",
                            size: "large",
                            style: { width: "50vw", height: "250px" },
                        }}
                        title={false}
                        paragraph={{ rows: 0 }}
                    />
                </div>
            );
        }

        if (type === "content") {
            let skelton_jsx = <Skeleton title={false} paragraph={{ rows: 5 }} active></Skeleton>;
            let array_jsx = Array(10).fill(skelton_jsx);
            return array_jsx;
        }
    };

    renderCourseData = () => {
        let courseData = this.state.course_data;

        if (courseData.length === 0) return null;

        let courseCardMap = courseData.map((course, idx) => {
            return (
                <Col md={8} lg={8} key={`COURSE-CARD-${idx}`}>
                    <CourseCard {...course} />
                </Col>
            );
        });

        return (
            <CourseListContainer>
                <h4 className="h4-heading-v2">Courses</h4>
                <Row
                    className="course-row"
                    gutter={[
                        { xs: 8, sm: 16, md: 24, lg: 32 },
                        { xs: 8, sm: 16, md: 24, lg: 32 },
                    ]}
                >
                    {courseCardMap}
                </Row>
            </CourseListContainer>
        );
    };

    renderVideosData = () => {
        const { article_doc, pageloaded } = this.state;
        if (pageloaded && article_doc.has_videos) {
            let video_data = article_doc.video_data;

            let videos_jsx = video_data.map((video, idx) => {
                return (
                    <Col md={8} lg={8} key={`VIDEO-CARD-${idx}`}>
                        <VideoCard video={video} />
                    </Col>
                );
            });

            return (
                <VideoListContainer>
                    <h4 className="h4-heading-v2">Videos</h4>
                    <Row
                        className="course-row"
                        gutter={[
                            { xs: 8, sm: 16, md: 24, lg: 32 },
                            { xs: 8, sm: 16, md: 24, lg: 32 },
                        ]}
                    >
                        {videos_jsx}{" "}
                    </Row>
                </VideoListContainer>
            );
        }
    };

    /* ******** @@@@  renderArticleHeader Function  @@@@  ******** 
        Functionalities: 
            1. To render article page header
            2. To render tags and bredcrumbs if present
            3. Renders Article Title
            4. Checks for pageload condition
    */

    renderArticleHeader = () => {
        const { article_doc, pageloaded, tag_map, type } = this.state;

        let TagMapJSX = [];

        TagMapJSX = tag_map.map((tag_data) => (
            <Tag onClick={() => (window.location.href = G_HOME_URL + tag_data.slug)}>
                <div className="body-small-text"> {tag_data.name} </div>
            </Tag>
        ));

        const renderBreadCrumbs = () => {
            if (type === "COMPANY") {
                return (
                    <BreadCrumbsContainer className="body-medium-text">
                        <Breadcrumb
                            separator={<span className="icon icon-chevron-right"></span>}
                            style={{ marginBottom: 32 }}
                        >
                            <Breadcrumb.Item
                                className="body-medium-text crumb-item"
                                onClick={() => (window.location.href = G_HOME_URL)}
                            >
                                Home
                            </Breadcrumb.Item>
                            <Breadcrumb.Item
                                className="body-medium-text  crumb-item"
                                onClick={() => (window.location.href = G_HOME_URL + "company-corner/")}
                            >
                                Company Corner
                            </Breadcrumb.Item>
                            <Breadcrumb.Item
                                className="body-medium-text last-item"
                                onClick={() => window.location.reload()}
                            >
                                {this.state.company_name}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </BreadCrumbsContainer>
                );
            }
        };

        if (pageloaded) {
            return (
                <>
                    {renderBreadCrumbs()}
                    <Helmet>
                        <meta name="keywords" content={article_doc.meta_desc} />
                        <title>{article_doc.article_title ? article_doc.article_title : article_doc.title}</title>
                    </Helmet>
                    <h1 className="article-title h2-heading-v2">
                        {ReactHtmlParser(article_doc.article_title ? article_doc.article_title : article_doc.title)}
                    </h1>
                    {type === "COMPANY" || type === "PRACTICE_EXERCISE" ? null : (
                        <div className="article-publish-date">
                            Published on {new Date(parseInt(article_doc.posted_on_ts * 1000)).toDateString()}
                        </div>
                    )}
                    {type === "COMPANY" ? null : <div className="tags-container">{TagMapJSX}</div>}
                </>
            );
        } else return this.renderSkelton("header");
    };

    /* |||||||| @@@@  renderArticleHeader ENDs  @@@@ ||||||||
     */

    renderSideBannerAds = () => {
        const { article_doc, ad_map } = this.state;
        if (article_doc && article_doc["side_banner_ads"]) {
            let side_banner_list = article_doc["side_banner_ads"];
            return side_banner_list.map((ad) => {
                if (ad_map[ad]) return <SideBarAd {...ad_map[ad]} />;
            });
        }
    };

    // render Restrict Social Block

    renderRestrict = () => {
        let slug = window.location.href.split(G_HOME_URL)[1];
        slug = "rurl=/" + slug;

        if (this.state.restrict) {
            return (
                <>
                    <MeteredShadow></MeteredShadow>
                    <MeteredContentLogin className="lr-pad-d lr-pad-m">
                        <h4 className="text-c-d h4-heading-v2">
                            Continue reading with a <span className="color-red">free</span> account
                        </h4>
                        <div className="f-d social-container">
                            <Link
                                to={{
                                    pathname: "/login",
                                    search: slug,
                                    state: {
                                        call: "google-btn",
                                    },
                                }}
                            >
                                <SocialLoginCard icon={GoogleIcon} text="Google" />
                            </Link>

                            <Link
                                to={{
                                    pathname: "/login",
                                    search: slug,
                                    state: {
                                        call: "facebook-btn",
                                    },
                                }}
                            >
                                <SocialLoginCard icon={FbIcon} text="Facebook" />
                            </Link>

                            <Link
                                to={{
                                    pathname: "/signup",
                                    search: slug,
                                }}
                            >
                                <SocialLoginCard icon={MessageIcon} text="Email" className="social-email" />
                            </Link>
                        </div>
                        <div className="login-message text-c-d body-normal-text">
                            Already have an account?{" "}
                            <span
                                className="color-red c-pointer"
                                onClick={() => (window.location.href = `${G_HOME_URL}login?${slug}`)}
                            >
                                Sign in
                            </span>
                        </div>
                    </MeteredContentLogin>
                </>
            );
        }

        return null;
    };

    render() {
        const { pageloaded, article_doc, type } = this.state;

        let share_url = window.location.href;

        const socialShareJSX = (
            <>
                <div className="share-item mr-8">
                    <WhatsAppShare url={share_url} />
                </div>
                <div className="share-item mr-8">
                    <FacebookShare url={share_url} />
                </div>
                <div className="share-item mr-8">
                    <TwitterShare url={share_url} />
                </div>
                <div className="share-item mr-8">
                    <LinkedinShare url={share_url} />
                </div>
            </>
        );

        return (
            <>
                <Layout>
                    <Row className="article-container lr-pad-d lr-pad-m" gutter={16}>
                        <Col sm={24} md={24} lg={18}>
                            <ArticleWrapper>
                                <ArticleHeader>{this.renderArticleHeader()}</ArticleHeader>
                                <ArticleBodyContainer>
                                    <Row gutter={16} key="ARTCILE-BODY-ROW">
                                        {type !== "COMPANY" ? (
                                            <Col span={2}>
                                                <SocailShareDiv className="hide-m" type={type}>
                                                    <div className="share-title">Share</div>

                                                    {socialShareJSX}
                                                </SocailShareDiv>
                                            </Col>
                                        ) : null}

                                        <Col sm={24} md={20} lg={22}>
                                            <SocailShareDiv className="hide-d social-share-mobile" type={type}>
                                                <div className="share-title f-d">Share</div>
                                                <div className="f-d">{socialShareJSX}</div>
                                            </SocailShareDiv>

                                            <ArticleBody className="article-body" type={type}>
                                                {pageloaded
                                                    ? ReactHtmlParser(article_doc.content, {
                                                          transform: this.transformNodes,
                                                      })
                                                    : this.renderSkelton("content")}
                                            </ArticleBody>
                                        </Col>
                                    </Row>
                                </ArticleBodyContainer>
                            </ArticleWrapper>
                        </Col>
                        <Col sm={0} md={0} lg={6}>
                            <div className="sidebar">{this.renderSideBannerAds()}</div>
                        </Col>
                    </Row>
                    <div className="article-container lr-pad-d lr-pad-m f-d "></div>
                    <div className="lr-pad-d lr-pad-m">
                        {this.renderCourseData()}
                        {this.renderVideosData()}
                    </div>

                    {this.renderRestrict()}
                    <FollowUs />
                    <MarketingModalForm />

                    <style jsx={"true"}>{`
                        .article-container {
                            padding-top: 64px;
                            color: var(--carbon);
                        }
                        .sidebar {
                            width: 100%;
                        }

                        .social-share-mobile .share-title {
                            width: 100%;
                        }

                        .katex .stretchy {
                            height: 1px !important;
                            background: var(--carbon);
                            margin: 1px 0;
                        }

                        @media only screen and (max-device-width: 760px) {
                            .sidebar {
                                display: none;
                            }

                            .social-share-mobile .mr-8 {
                                margin: unset;
                            }

                            .social-share-mobile .share-item {
                                margin-right: 16px;
                            }

                            .article-container {
                                margin-left: unset !important;
                                margin-right: unset !important;
                            }
                        }
                    `}</style>
                </Layout>
            </>
        );
    }
}

export default ArticleDetails;
