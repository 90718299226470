import React from "react";
import ModuleAccessibility from "./ModuleAccessibility";

const TableRow = ({ idx, topic, access }) => {
    access = access.map((access) => (
        <ModuleAccessibility idx={idx} isAccessbile={access ? true : false} />
    ));
    return (
        <>
            <div className="g-d g-col-3 table-row-wrapper">
                <div
                    className={`g-d g-h-s g-v-c tr-col${
                        idx === 0 ? " tr-odd-col" : " tr-even-col"
                    }`}
                >
                    <span className="topic">{topic}</span>
                </div>
                {access}
            </div>
            <style jsx={"true"}>{`
                .table-row-wrapper .tr-col {
                    background-color: var(--snowflake);
                    height: 7vh;
                    padding: 0 2rem;
                }

                .table-row-wrapper .tr-odd-col {
                    background-color: var(--snowflake);
                }

                .table-row-wrapper .tr-even-col {
                    background-color: var(--smoke);
                }
            `}</style>
        </>
    );
};

export default TableRow;
