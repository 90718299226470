import axios from "axios";
import { check_login, logout_user } from "../../utils/login.util";
import { __getEmail, __getToken, __getUID } from "../../utils/user-details.util";
import { message } from "antd";
import { useState, useEffect } from "react";
import { G_API_LEARN_URL } from "../../constants/constants";

function usePlacementZone() {
    const [showLivezone, setshowLivezone] = useState(false);

    useEffect(() => {
        if (check_login()) {
            axios
                .post(
                    G_API_LEARN_URL + "auth/prograd/",
                    {
                        uid: __getUID(),
                        email: __getEmail(),
                    },
                    {
                        headers: {
                            Authorization: __getToken(),
                        },
                    }
                )
                .then((response) => {
                    response = response.data;
                    if (response.status !== undefined && response.status === 0 && response.code !== undefined) {
                        message.error(
                            "The same account is used in multiple devices (or) your session has expired. Please login again to authorize your account.",
                            6000
                        );
                        setTimeout(logout_user, 2500);
                    }

                    if (response.status !== undefined && response.status === 1 && response.isLiveClassSubscriber) {
                        setshowLivezone(true);
                    }
                });
        }
    }, []);

    return [showLivezone];
}

export default usePlacementZone;
