import React, { Component } from "react";
import styled from "styled-components";
import axios from "axios";
import { message, Modal } from "antd";
import { G_API_URL, G_CF_URL } from "../../constants/constants";
import { Button, Form } from "antd";
import { FormInput, FormInputDropDown, FormInputMobile, FormInputNumber } from "../Form/FormInput";
import moment from "moment";

const StyledModalBody = styled.div`
    gap: 24px;
    .modal-left-container {
        min-width: 420px;
        min-height: 740px;
    }

    .modal-right-container {
        width: 60%;
        padding: 40px 16px;
    }

    .modal-left-container .img-container {
        width: 100%;
        height: 100%;
        background-position-x: left;
    }

    .marketing-modal-form {
        width: 90%;
    }

    .marketing-modal-form .ant-btn {
        width: 100%;
        height: 50px;
        color: var(--dove);
        font-family: "OpenSans", sans-serif;
        border: unset;
    }

    #marketing-modal-form_mobile_no {
        height: 50px;
    }

    .desc {
        color: var(--sandstone);
    }

    @media only screen and (max-width: 768px) {
        .modal-left-container {
            display: none;
        }

        .modal-right-container {
            width: 100%;
            position: relative;
            padding-top: 248px;

            .mobile-image {
                width: 100%;
                height: 300px;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 238px;
            }
        }

        .title {
            font-size: 24px;
        }

        .marketing-modal-form {
            width: 100%;
        }
    }
`;

class MarketingModal extends Component {
    state = {
        visible: false,
        btnLoding: false,
    };

    componentDidMount() {
        let modal_to_show = true;
        let time = moment().valueOf();
        // check for localstorage
        if (localStorage) {
            if (localStorage.getItem("marketing_modal_show_ts")) {
                let marketing_modal_show_ts = parseInt(localStorage.getItem("marketing_modal_show_ts"));
                if (time < marketing_modal_show_ts) modal_to_show = false;
            }

            if (
                localStorage.getItem("marketing_modal_filled") &&
                localStorage.getItem("marketing_modal_filled_show_ts")
            ) {
                let marketing_modal_filled_show_ts = parseInt(localStorage.getItem("marketing_modal_filled_show_ts"));
                if (time < marketing_modal_filled_show_ts) modal_to_show = false;
            }
        }

        if (modal_to_show) {
            // To be visible after 12 sec
            setTimeout(() => {
                this.setState({ visible: true });
            }, 30000);
        }
    }

    handleCancel = () => {
        const time = moment()
            .add(2, "hours")
            .valueOf();
        localStorage.setItem("marketing_modal_show_ts", time);
        this.setState({ visible: false });
    };

    download = (url) => {
        const a = document.createElement("a");
        a.href = url;
        a.download = url.split("/").pop();
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ btnLoding: true });
                // console.log("Received values of form: ", values);
                axios
                    .post(`${G_API_URL}marketing-modal`, values)
                    .then((res) => {
                        const time = moment()
                            .add(7, "days")
                            .valueOf();

                        localStorage.setItem("marketing_modal_filled", "true");
                        localStorage.setItem("marketing_modal_filled_show_ts", time);
                        this.setState({ btnLoding: false });
                        this.download(`${G_CF_URL}lead/faceprep_pro_brochure.pdf`);
                        this.setState({ visible: false });
                    })
                    .catch((err) => {
                        this.setState({ btnLoding: false });
                        message.error("Something went wrong, Please try again");
                    });
            }
        });
    };

    render() {
        const { visible } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <>
                <Modal
                    visible={visible}
                    title={null}
                    onCancel={this.handleCancel}
                    footer={null}
                    style={{ top: 40, scale: "0.88" }}
                    width={"68vw"}
                    className="marketing-modal"
                    destroyOnClose={true}
                >
                    <StyledModalBody className="f-d">
                        <div className="modal-left-container">
                            <div
                                className="bg-image img-container"
                                style={{ backgroundImage: "url(" + `${G_CF_URL}feed/images/fppro-web-popup.png` + ")" }}
                            ></div>
                        </div>
                        <div className="modal-right-container">
                            <div
                                className="bg-image img-container hide-d mobile-image"
                                style={{
                                    backgroundImage: "url(" + `${G_CF_URL}feed/images/fppro-mobile-popup.png` + ")",
                                }}
                            ></div>

                            <h4 className="h4-heading-v2 title">Fill in your details</h4>
                            <p className="body-medium-text desc">Our career counselor will call you shortly!</p>

                            <Form className="marketing-modal-form" onSubmit={this.handleSubmit}>
                                <FormInput
                                    message="Full Name cannot be empty!"
                                    label={`Your full Name`}
                                    name="full_name"
                                    placeholder="Enter your full name"
                                    getFieldDecorator={getFieldDecorator}
                                    isRequired={true}
                                />

                                <FormInput
                                    message="Email cannot be empty!"
                                    type="email"
                                    label={`Email`}
                                    name="email"
                                    placeholder="Your email address"
                                    value={localStorage.getItem("email")}
                                    getFieldDecorator={getFieldDecorator}
                                    isRequired={true}
                                />
                                <FormInputMobile
                                    message="Mobile Number cannot be empty!"
                                    label={`Mobile Number`}
                                    name="mobile_no"
                                    placeholder="Your 10 digit mobile number"
                                    getFieldDecorator={getFieldDecorator}
                                />

                                <FormInputNumber
                                    message="This field cannot be empty"
                                    label={`Year of Graduation`}
                                    name="yop"
                                    placeholder="Enter year of graduation"
                                    getFieldDecorator={getFieldDecorator}
                                    isRequired={true}
                                />

                                <FormInputDropDown
                                    message="requirement cannot be empty"
                                    label={`Your requirement `}
                                    name="requirement"
                                    getFieldDecorator={getFieldDecorator}
                                    isRequired={true}
                                    values={["Placements", "Upskilling", "Looking for information"]}
                                />

                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="submit-btn"
                                        loading={this.state.btnLoding}
                                    >
                                        Download Brochure
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </StyledModalBody>
                </Modal>
                <style jsx={"true"}>
                    {`
                        .marketing-modal .ant-modal-body {
                            padding: unset;
                            min-height: 60vh;
                        }
                        @media only screen and (max-width: 768px) {
                            .marketing-modal {
                                width: 94vw !important;
                            }
                            .ant-modal-close-x {
                                color: var(--dove);
                            }
                        }
                    `}
                </style>
            </>
        );
    }
}

const MarketingModalForm = Form.create({ name: "marketing-modal-form" })(MarketingModal);

export default MarketingModalForm;
