import {
    CLEAR_STORE,
    G_API_URL,
    SET_ASSESS_DATA,
    SET_ASSESS_DRAWER,
    SET_ASSESS_TITLE,
    SET_ASSESS_CAT_TYPE,
    SET_JWT_DATA,
    SET_PRACTICE_DATA,
    SET_SKILLZONE,
    SET_STATS_DATA,
    SET_STATS_LOADING,
    UPDATE_CODE,
    UPDATE_PRACTICE_DATA,
    UPDATE_SKILLZONE,
    SWITCH_LANGUAGE,
    RESET_CODE,
    RESET_EDITOR,
    SET_COMP_QUESTION_OPTION,
    SET_COMP_GUESS_STATUS,
    RESET_COMP_DATA,
    SET_QUESTION_UPDATED,
    CUR_REPORT_INSTANCEID,
    SET_REPORTS_DATA,
    SET_REPORT_LOADING,
    SET_SKILL_PLANS,
    SKILL_PLANS_LOADING,
    SHOW_ASSESS_REPORTS,
    G_CF_URL,
    SET_PROFILE_DATA,
    SET_SKILLBOARD_CID,
} from "../constants/constants";
import axios from "axios";
import { __getToken } from "../utils/user-details.util";

export const clearStore = () => {
    return {
        type: CLEAR_STORE,
        payload: null,
    };
};

export const setJWTData = (payload) => {
    return {
        type: SET_JWT_DATA,
        payload,
    };
};

export const setSkillZone = (payload) => {
    return {
        type: SET_SKILLZONE,
        payload,
    };
};

export const updateSkillZone = (payload) => {
    return {
        type: UPDATE_SKILLZONE,
        payload,
    };
};

export const setAssessDrawer = (payload) => {
    return {
        type: SET_ASSESS_DRAWER,
        payload,
    };
};

export const setAssessData = (payload) => ({
    type: SET_ASSESS_DATA,
    payload,
});

export const setAssessTitle = (payload) => ({
    type: SET_ASSESS_TITLE,
    payload,
});

export const setAssessCatType = (payload) => ({
    type: SET_ASSESS_CAT_TYPE,
    payload,
});

export const fetchTestDetails = ({ instance_ids, title, type, quizr_token = null }) => {
    return (dispatch) => {
        dispatch(setAssessDrawer(true));
        dispatch(setAssessTitle(title));
        dispatch(setAssessCatType(type));
        axios
            .get(`${G_API_URL}skills/test-details/`, {
                headers: {
                    "instance-ids": instance_ids.join(","),
                    Authorization: __getToken(),
                    "quizr-token": quizr_token,
                },
            })
            .then((res) => {
                if (res.data && res.data.data) {
                    dispatch(setAssessData(res.data.data));
                }
                // dispatch(setUserAnsLoading(false));
            })
            .catch((err) => {
                //  dispatch error action
            });
    };
};

export const setStatsLoading = (payload) => ({
    type: SET_STATS_LOADING,
    payload,
});

export const setStatsData = (payload) => ({
    type: SET_STATS_DATA,
    payload,
});

export const fetchSkillStats = ({ category = 1, ...rest }) => {
    return (dispatch) => {
        let params = { category };
        if (rest.tagName) params = { ...params, tagName: rest.tagName };

        dispatch(setStatsLoading(true));
        axios
            .get(`${G_API_URL}skills/stats/`, {
                params: {
                    ...params,
                },
                headers: {
                    Authorization: __getToken(),
                },
            })
            .then((res) => {
                if (res.data && res.data.data) {
                    dispatch(setStatsData(res.data.data));
                }
            })
            .catch((err) => {
                //  dispatch error action
            });
    };
};

export const setSkillBoardCid = (payload) => ({
    type: SET_SKILLBOARD_CID,
    payload,
});

export const setPracticeData = (payload) => {
    return {
        type: SET_PRACTICE_DATA,
        payload,
    };
};

export const updatePracticeData = (payload) => {
    return {
        type: UPDATE_PRACTICE_DATA,
        payload,
    };
};

export const updateCode = (payload) => ({
    type: UPDATE_CODE,
    payload,
});

export const switchLanguage = (payload) => ({
    type: SWITCH_LANGUAGE,
    payload,
});

export const resetCode = (payload) => ({
    type: RESET_CODE,
    payload,
});

export const resetEditor = (payload) => ({
    type: RESET_EDITOR,
    payload,
});

export const setComprehenstionOption = (payload) => ({
    type: SET_COMP_QUESTION_OPTION,
    payload,
});

export const setCompGuessStatus = (payload) => ({
    type: SET_COMP_GUESS_STATUS,
    payload,
});

export const resetCompData = (payload) => ({
    type: RESET_COMP_DATA,
    payload,
});

export const setShowAssesReports = (payload) => ({
    type: SHOW_ASSESS_REPORTS,
    payload,
});

export const fetchReportsData = ({ instance_id, quizr_token }) => {
    return (dispatch) => {
        dispatch({ type: CUR_REPORT_INSTANCEID, payload: instance_id });
        dispatch({ type: SET_REPORT_LOADING, payload: true });
        axios
            .get(`${G_API_URL}skills/test/report/`, {
                headers: {
                    Authorization: __getToken(),
                    "instance-id": instance_id,
                    "quizr-token": quizr_token,
                },
            })
            .then((res) => {
                if (res.data && res.data.data) {
                    dispatch({ type: SET_REPORTS_DATA, payload: res.data.data });
                    dispatch({ type: SET_REPORT_LOADING, payload: false });
                }
            })
            .catch((err) => {
                //  dispatch error action
            });
    };
};

export const fetchSkillZonePlans = () => {
    return (dispatch) => {
        dispatch({ type: SKILL_PLANS_LOADING, payload: true });
        axios
            .get(`${G_API_URL}resource/plans`)
            .then((response) => {
                const { status, data } = response.data;
                if (status === 1) {
                    dispatch({ type: SET_SKILL_PLANS, payload: data.subscription });
                    dispatch({ type: SKILL_PLANS_LOADING, payload: false });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

export const setProfileData = (payload) => ({
    type: SET_PROFILE_DATA,
    payload,
});

export const fetchProfileData = ({ jwt_token = "" }) => {
    return (dispatch) => {
        axios
            .post(`${G_API_URL}user/profile/`, {}, { headers: { Authorization: jwt_token } })
            .then((response) => {
                if (response && response.data && response.data.data && response.data.status) {
                    let { college, deg, usr, uid, yop, picture } = response.data.data;
                    picture = `${G_CF_URL}users/picture/` + picture;
                    let user_data = {
                        college_name: college,
                        deg,
                        usr,
                        uid,
                        yop,
                        picture,
                    };
                    dispatch({ type: SET_PROFILE_DATA, payload: user_data });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
};
