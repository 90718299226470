import React from "react";
import { Row } from "antd";
import NoVideo from "../../assets/NoVideos.gif";

import CourseFolder from "../CourseFolder/CourseFolder";

const Grid = props => {
    const { courses, count, searched } = props;

    let sortCourses = Object.values(courses).sort( (a, b) => a.topic.localeCompare(b.topic, 'en', {'sensitivity': 'base'}));

    function NovideosDisplay() {
        const style = {
            "font-family": "Nunito sans",
            "font-size": "18px",
            "font-weight": "500",
            color: "var(--carbon)",
            marginTop: "32px"
        };

        const style2 = {
            ...style,
            "font-weight": "300",
            marginTop: "8px",
            "text-align": "center"
        };
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    margin: "0 auto",
                    marginTop: "6rem"
                }}
            >
                <img src={NoVideo} alt="No Videos Found.." style={{ height: "40%" }} />
                <div style={style}>Oh, Snap!</div>
                <div style={style2}>This is embarassing but there are no videos to show.</div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Row type="flex" justify="start">
                {
                    sortCourses.map((course, index) => (
                    <CourseFolder key={index+1} course={course} count={count[parseInt(course.id)]} />
                ))}
                {courses.length === 0 && searched ? NovideosDisplay() : null}
            </Row>
        </React.Fragment>
    );
};

export default Grid;
