import React from "react";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import SvgCourse from "./svg_icons/course.svg";
import SvgHeart from "./svg_icons/heart.svg";
import SvgResources from "./svg_icons/resources.svg";
import SvgTest from "./svg_icons/test.svg";
import { Menu, Icon } from "antd";

const { SubMenu } = Menu;

const StyledSideBarItem = styled.div`
    height: 54px;
    border-left: 4px solid transparent;

    .icon-container {
        height: 16px;
        width: 16px;
        margin-left: 36px;
    }

    .side-bar-item {
        margin-left: 12px;
        color: var(--granite-shade-2);
        transition: all 0.2s;

        :active {
            color: var(--granite-shade-2);
        }
    }

    :hover {
        background-color: rgb(140, 140, 140, 0.1);
        border-left: 4px solid rgb(140, 140, 140);

        .side-bar-item {
            color: var(--granite-shade-1);
        }
    }

    .side-bar-item-active {
        color: var(--facered);
    }
`;

const SideBarItem = ({ title, icon, url_slug }) => {
    const { slug } = useParams();
    const history = useHistory();

    let color = "";
    let SvgIcon = <></>;

    let className = ["", ""];

    if (slug === url_slug) {
        className[0] = "sidebar-active";
        className[1] = "side-bar-item-active";
        color = "#f05136";
    }

    switch (icon) {
        case "course":
            SvgIcon = <SvgCourse color={color} />;
            break;
        case "test":
            SvgIcon = <SvgTest color={color} />;
            break;
        case "favorite":
            SvgIcon = <SvgHeart color={color} />;
            break;
        case "resources":
            SvgIcon = <SvgResources color={color} />;
            break;
        default:
            break;
    }

    return (
        <StyledSideBarItem
            className={`f-d f-v-c c-pointer ${className[0]}`}
            onClick={() => history.push(`/facepro/dashboard/${url_slug}`)}
        >
            <div className="icon-container">{SvgIcon}</div>
            <div className={`side-bar-item body-normal-text font-nunito ${className[1]}`}>{title}</div>
        </StyledSideBarItem>
    );
};

const menuData = [
    {
        title: "Courses",
        icon: "course",
        slug: "courses",
    },
    {
        title: "Test",
        icon: "test",
        slug: "tests",
    },
    {
        title: "Favourites",
        icon: "favorite",
        slug: "favorites",
    },
];

const Sidebar = () => {
    const history = useHistory();

    return (
        <>
            <div className="sidebar-items-wrapper">
                {menuData.map((item, idx) => (
                    <SideBarItem
                        title={item.title}
                        icon={item.icon}
                        url_slug={item.slug}
                        key={`side-bar-items-${idx}`}
                    />
                ))}

                <Menu mode="inline" className="pro-dash-menu">
                    <SubMenu
                        key="sub2"
                        title={
                            <div className="f-d f-v-c">
                                <div className="icon-container f-d">
                                    <SvgResources />
                                </div>
                                <div className="side-bar-item body-normal-text font-nunito">Resources</div>
                            </div>
                        }
                    >
                        <Menu.Item key="6" onClick={() => history.push(`/videos`)}>
                            Videos
                        </Menu.Item>

                        <Menu.Item key="8" onClick={() => history.push(`/articles`)}>
                            Articles
                        </Menu.Item>
                    </SubMenu>
                </Menu>
            </div>
            <style jsx={"true"}>{`
                .sidebar-items-wrapper {
                    margin-top: 1rem;
                }

                .sidebar-active {
                    background-color: rgba(241, 84, 57, 0.06);
                    border-left: 4px solid var(--facered);
                }

                .sidebar-active .side-bar-item {
                    color: var(--facered);
                }

                .sidebar-active:hover {
                    background-color: rgba(241, 84, 57, 0.06) !important;
                    border-left: 4px solid var(--facered) !important;
                }

                .sidebar-active:hover .side-bar-item {
                    color: var(--facered) !important;
                }

                .pro-dash-menu .ant-menu-submenu-title {
                    padding-left: 40px !important;
                }

                .pro-dash-menu .side-bar-item {
                    margin-left: 12px;
                    color: var(--granite-shade-2);
                    transition: all 0.2s;
                }

                .pro-dash-menu .side-bar-item:active {
                    color: var(--granite-shade-2);
                }

                .pro-dash-menu .ant-menu-submenu-title:hover {
                    color: var(--granite-shade-2) !important;
                }

                .pro-dash-menu .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
                .pro-dash-menu .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after {
                    background: var(--granite-shade-2) !important;
                }

                .pro-dash-menu div.ant-menu-item:active,
                .pro-dash-menu div.ant-menu-submenu-title:active,
                .pro-dash-menu div.ant-menu-submenu-title:hover {
                    background: rgb(140, 140, 140, 0.1) !important;
                    border-left: 4px solid rgb(140, 140, 140) !important;
                }

                .pro-dash-menu div.ant-menu-submenu-title {
                    height: 56px !important;
                    display: flex !important;
                    border-left: 4px solid transparent !important;
                }

                .pro-dash-menu .ant-menu-sub .ant-menu-item:hover,
                .pro-dash-menu .ant-menu-sub .ant-menu-item:active,
                .pro-dash-menu .ant-menu-sub .ant-menu-item:focus {
                }

                .pro-dash-menu .ant-menu-sub .ant-menu-item-selected,
                .pro-dash-menu .ant-menu-sub .ant-menu-item-selected {
                    background-color: unset !important;
                }

                .pro-dash-menu .ant-menu-inline .ant-menu-item:after {
                    border-right: unset !important;
                }

                .pro-dash-menu .ant-menu-sub .ant-menu-item:hover,
                .pro-dash-menu .ant-menu-item-selected {
                    color: var(--facered) !important;
                }
            `}</style>
        </>
    );
};

export default Sidebar;
