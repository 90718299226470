import React, {Component} from 'react';
import {Carousel} from "antd";

Carousel.defaultProps = {
    dots: true,
    draggable: true,
    arrows: false,
    autoplaySpeed: 4000
};

class Testimonials extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }


    componentDidMount() {
    }

    renderCard() {
        const {testimonialContent} = this.props;
        return Object.values(testimonialContent).map((item, i) => {
            return (
                <div key={"TES" + i}>
                    <div className="card-container f-d f-vt" >
                        <span className="quotes">“</span>
                        <h2 className="h2-heading testimonial-heading">{item.title}</h2>
                        <p className="body-small">{item.desc}</p>
                        <div className="f-d f-ht user-wrapper">
                            <img className="user-img" src={item.img} alt="" />
                            <div className="f-d f-vt user-details">
                                <h3 className="h3-heading">{item.name}</h3>
                                <span className="body-small">{item.user_type}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    render() {
        return (
            <>
                <div className="testimonials-card-wrapper f-d f-vt">
                    <h2 className="h2-heading">FACE Preppers Love Premium</h2>
                    <div className="crousel-container">
                        <Carousel autoplay>
                            {this.renderCard()}
                        </Carousel>
                    </div>
                </div>

                <style jsx>
                    {`
                     .testimonials-card-wrapper .quotes{
                        font-size: 90px;
                        line-height: 0.5;
                        color: var(--facered);
                     }
                     .testimonials-card-wrapper .user-wrapper{
                        margin-top: 50px;
                     }
                     .testimonials-card-wrapper .user-img{
                        height: 50px;
                        width: 50px;
                        border-radius: 50%;
                     }
                     .testimonials-card-wrapper .user-details{
                        line-height: 40%;
                        padding-left: 10px;
                     }
                     .testimonials-card-wrapper .card-container{
                       border-radius: 4px;
                       box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
                       border: solid 1px var(--snowfall);
                       padding: 40px;
                       height: 400px;
                     }
                     .testimonials-card-wrapper  .crousel-container{
                        padding: 40px;
                     }
                     .testimonials-card-wrapper .ant-carousel .slick-dots-bottom {
                        bottom: -12px;
                     }
                     .testimonials-card-wrapper .ant-carousel .slick-dots li button{
                        background: var(--granite);
                     }
                     .testimonials-card-wrapper .ant-carousel .slick-dots  .slick-active button{
                        background: var(--facered);
                     }
                    `}
                </style>
            </>
        );

    }
}

export default Testimonials;