import * as React from "react";

function CheckOutline(props) {
    return (
        <svg width={28} height={28} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M14.0007 25.6654C7.55715 25.6654 2.33398 20.4422 2.33398 13.9987C2.33398 7.5552 7.55715 2.33203 14.0007 2.33203C20.4442 2.33203 25.6673 7.5552 25.6673 13.9987C25.6673 20.4422 20.4442 25.6654 14.0007 25.6654ZM14.0007 23.332C16.476 23.332 18.85 22.3487 20.6003 20.5984C22.3507 18.848 23.334 16.4741 23.334 13.9987C23.334 11.5233 22.3507 9.14937 20.6003 7.39903C18.85 5.6487 16.476 4.66536 14.0007 4.66536C11.5253 4.66536 9.15133 5.6487 7.40099 7.39903C5.65065 9.14937 4.66732 11.5233 4.66732 13.9987C4.66732 16.4741 5.65065 18.848 7.40099 20.5984C9.15133 22.3487 11.5253 23.332 14.0007 23.332ZM12.8375 18.6654L7.88732 13.7152L9.53698 12.0655L12.8375 15.366L19.4362 8.7662L21.087 10.4159L12.8375 18.6654Z"
                fill={props.color}
            />
        </svg>
    );
}

export default CheckOutline;
