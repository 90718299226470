import * as React from "react";
const SvgCourse = (props) => (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M4.667 8.667h4.667a.646.646 0 0 0 .475-.192.645.645 0 0 0 .192-.475.647.647 0 0 0-.192-.475.647.647 0 0 0-.475-.192H4.667a.647.647 0 0 0-.475.192.647.647 0 0 0-.191.475c0 .189.063.347.191.475a.646.646 0 0 0 .475.192Zm0-2h4.667a.646.646 0 0 0 .475-.192.645.645 0 0 0 .192-.475.647.647 0 0 0-.192-.475.647.647 0 0 0-.475-.192H4.667a.647.647 0 0 0-.475.192.647.647 0 0 0-.191.475c0 .189.063.347.191.475a.646.646 0 0 0 .475.192Zm-2 6.666c-.366 0-.68-.13-.941-.391A1.284 1.284 0 0 1 1.334 12V4c0-.367.13-.68.392-.941.26-.262.575-.392.941-.392h10.667c.367 0 .68.13.942.392.26.26.391.574.391.941v8c0 .367-.13.68-.391.942-.261.26-.575.391-.942.391H2.667Z"
            fill={props.color ? props.color : "#8C8C8C"}
        />
    </svg>
);
export default SvgCourse;
