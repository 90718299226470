import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import brand_logo from "../../assets/icons/svg_icons/logo_web.svg";
import { G_API_URL, G_URL } from "../../constants/constants";
import { check_login } from "../../utils/login.util";
import { Dropdown, Menu } from "antd";
import { __getDP, __getToken } from "../../utils/user-details.util";
import { logout_user } from "../../utils/login.util";
import MobileNav from "../MobileNav/MobileNav";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import usePlacementZone from "../Hooks/usePlacementZone";
import LiveClassroom from "../header/LiveClassroom";
import { useEffect } from "react";
import { useState } from "react";

//    {
//     title: "Ultimate Placement Prep",
//     link: "/ultimate-placement-prep",
// },
const nav_items = [
    {
        title: "Home",
        link: "/",
    },

    {
        title: "FACE Prep Pro",
        link: "/facepro",
        isSubMenu: true,
        childs: [
            { title: "Course", link: "/facepro/dashboard/courses" },
            { title: "Tests", link: "/facepro/dashboard/tests" },
            { title: "Favourites", link: "/facepro/dashboard/favorites" },
            {
                title: "Resources",
                isSubMenu: true,
                childs: [
                    {
                        title: "Videos",
                        link: "/videos",
                    },

                    {
                        title: "Articles",
                        link: "/articles",
                    },
                ],
            },
        ],
    },
];

const archiveMenu = (
    <>
        <div className="archive-menu-container">
            <div className="menu-item" onClick={() => (window.location.href = `${G_URL}company-corner/`)}>
                Company Corner
            </div>

            <div className="menu-item" onClick={() => (window.location.href = `${G_URL}self-paced-courses/`)}>
                Self-paced courses
            </div>

            <div className="menu-item" onClick={() => (window.location.href = `${G_URL}skillzone/`)}>
                Skill Zone
            </div>
        </div>
    </>
);

const resourcesMenu = (
    <>
        <div className="archive-menu-container">
            <div className="menu-item" onClick={() => (window.location.href = `${G_URL}articles/`)}>
                Articles
            </div>
            <div className="menu-item" onClick={() => (window.location.href = `${G_URL}tests/`)}>
                Tests
            </div>
            <div className="menu-item" onClick={() => (window.location.href = `${G_URL}videos/`)}>
                Videos
            </div>
        </div>
    </>
);

const menu = (
    <>
        <div className="profile-menu-container">
            <div className="menu-item" onClick={() => (window.location.href = `${G_URL}profile/`)}>
                Profile
            </div>

            <div className="menu-item" onClick={() => (window.location.href = `${G_URL}change-password/`)}>
                Change Password
            </div>
            <div className="menu-item logout-btn" onClick={logout_user}>
                Logout
            </div>
        </div>
    </>
);

const getActiveNavItem = (nav_link = "", pathname = "") => {
    let className = "";
    if (pathname === nav_link) className = "link-active";
    return className;
};

const Header = ({ from }) => {
    const login_status = check_login();
    const history = useHistory();
    const [showLivezone] = usePlacementZone();
    const [isProUser, setIsProUser] = useState(false);
    const [isNMLive, setIsNMLive] = useState(false);

    useEffect(() => {
        if (check_login())
            axios.get(`${G_API_URL}orders/faceprep-pro`, { headers: { Authorization: __getToken() } }).then((res) => {
                if (res.data && res.data.status === 1) {
                    setIsProUser(true);
                }
            });

        axios.get(`${G_API_URL}resource/nm-live-status`).then((res) => {
            if (res.data && res.data.status === 1) {
                setIsNMLive(true);
            }
        });
    }, []);

    const renderSubMenu = (data) => {
        return (
            <Menu.SubMenu title={<span>{data.title}</span>} style={{ paddingLeft: 0 }}>
                {renderMenuItems(data.childs)}
            </Menu.SubMenu>
        );
    };
    const renderMenuItems = (data) => {
        return data.map((child) => {
            let key = child.title.toLowerCase();
            if (child.isSubMenu) return renderSubMenu(child);
            return (
                <Menu.Item key={key} onClick={() => history.push(child.link)}>
                    {child.title}
                </Menu.Item>
            );
        });
    };

    const renderNavItems = () => {
        return (
            <>
                {nav_items.map((item, idx) => {
                    if (item.childs && check_login() && isMobile && isProUser) {
                        return (
                            <Menu mode="inline" inlineIndent={0} style={{ borderRight: "unset" }}>
                                {renderSubMenu(item)}
                            </Menu>
                        );
                    }

                    return (
                        <a
                            className={`font-poppins font-normal nav-link ${getActiveNavItem(
                                item.link,
                                history.location.pathname
                            )}`}
                            onClick={() => {
                                history.push(`${process.env.PUBLIC_URL}${item.link}`);
                            }}
                            key={`mobile-nav-items-${idx}`}
                        >
                            {item.title}
                        </a>
                    );
                })}
            </>
        );
    };

    return (
        <>
            <nav className="navbar-container lr-pad-d lr-pad-m f-d f-v-c f-h-sb">
                <MobileNav
                    renderNavItems={renderNavItems}
                    login_status={login_status}
                    from={from}
                    showLivezone={showLivezone}
                    logout_user={logout_user}
                    isNMLive={isNMLive}
                />
                <Link to={`${process.env.PUBLIC_URL}/`} className="f-d f-v-c f-h-c">
                    <div
                        className="brand-logo bg-image-full c-pointer"
                        style={{
                            backgroundImage: `url(${brand_logo})`,
                        }}
                    />
                </Link>
                <div className="nav-links-conatiner hide-m f-d">
                    {renderNavItems()}
                    <Dropdown overlay={archiveMenu} placement="bottomLeft">
                        <div className="font-poppins font-normal nav-link c-pointer">Archived courses</div>
                    </Dropdown>
                    <Dropdown overlay={resourcesMenu} placement="bottomLeft">
                        <div className="font-poppins font-normal nav-link c-pointer">Resources</div>
                    </Dropdown>
                    {isNMLive && (
                        <div
                            className="menu-item nav-link c-pointer"
                            onClick={() => (window.location.href = `${G_URL}nm/live/`)}
                        >
                            NM Live
                        </div>
                    )}
                </div>

                {!login_status ? (
                    <div className="right-nav-container f-d f-v-c hide-m">
                        <a className={`font-poppins font-normal login-text c-pointer `} href="/login">
                            Log In
                        </a>
                        <div
                            className={`nav-btn-orange nav-item c-pointer f-d f-v-c f-h-c `}
                            onClick={() => (window.location.href = `${G_URL}signup`)}
                        >
                            Sign Up
                        </div>
                    </div>
                ) : (
                    <div className="right-nav-container f-d f-v-c f-h-sb hide-m">
                        {showLivezone && <LiveClassroom />}
                        <Dropdown overlay={menu} placement="bottomRight">
                            <div className="profileImageViewContainer f-d f-v-c ">
                                <div className="body-normal-text font-nunito font-bold">My Account</div>
                                <div
                                    className="profileImageView bg-image rounded-image"
                                    style={{
                                        backgroundImage: "url(" + __getDP() + ")",
                                        border: "1.5px solid var(--snowfall)",
                                    }}
                                />
                            </div>
                        </Dropdown>
                    </div>
                )}
            </nav>
            <style jsx="true">{`
                .rounded-image {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    cursor: pointer;
                    margin-left: 14px;
                }
                .navbar-container {
                    height: 64px;
                    width: 100vw;
                    background: var(--dove);
                    box-shadow: var(--peaky-shadow-nav);
                    position: fixed;
                    top: 0;
                    left: 0;
                    z-index: 1000;
                }
                .navbar-container .brand-logo {
                    margin-right: 2.4rem;
                    height: 26px;
                    width: 196px;
                }
                .nav-item {
                    margin-left: 2rem;
                }
                .nav-btn-orange {
                    height: 40px;
                    padding: 0px 30px;
                    background: #f05136;
                    border: none;
                    border-radius: 14px;
                    font-family: "Poppins", sans-serif;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--dove);
                }

                .nav-links-conatiner {
                    flex: 1;
                    gap: 24px;
                }

                .nav-links-conatiner .nav-link {
                    color: var(--granite-shade-1);
                    font-size: 16px;
                }

                .nav-links-conatiner .link-active {
                    color: var(--carbon);
                    font-weight: 600;
                    position: relative;
                }

                .nav-links-conatiner .link-active::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    background-color: var(--carbon);
                    bottom: 0;
                    left: 0;
                    transform: scaleX(1);
                    transform-origin: bottom right;
                    transition: transform 0.25s linear;
                }

                .login-text {
                    color: var(--facered);
                }

                .login-text:hover {
                    color: var(--facered);
                }

                .profileImageViewContainer {
                    background-color: var(--smoke-3);
                    border-radius: 100px;
                    height: 46px;
                    padding: 0 12px;
                }

                .profile-menu-container,
                .archive-menu-container {
                    display: flex;
                    flex-direction: column;
                    background: #ffffff;
                    width: 200px;
                    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
                    z-index: 1010;
                }

                .profile-menu-container .menu-item,
                .archive-menu-container .menu-item {
                    text-decoration: none;
                    color: black;
                    height: 50px;
                    padding: 1rem;
                    transition: all 0.4s;
                    text-align: right;
                    cursor: pointer;
                }

                .archive-menu-container .menu-item {
                    text-align: left;
                }

                .profile-menu-container .menu-item:hover,
                .archive-menu-container .menu-item:hover {
                    background: #0000000d;
                }

                .profile-menu-container .logout-btn {
                    color: var(--facered);
                }

                @media only screen and (max-device-width: 760px) {
                    .navbar-container .brand-logo {
                        margin-right: unset;
                    }

                    .navbar-container {
                        justify-content: center;
                    }
                }
            `}</style>
        </>
    );
};

export default Header;
