import * as React from "react";

function SvgTwitter(props) {
    return (
        <svg width={14} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M14 1.438a5.71 5.71 0 01-1.652.471c.6-.377 1.05-.972 1.267-1.673a5.576 5.576 0 01-1.827.737 2.831 2.831 0 00-1.631-.93 2.745 2.745 0 00-1.831.314A2.966 2.966 0 007.06 1.785a3.17 3.17 0 00-.172 1.945 7.83 7.83 0 01-3.277-.919A8.243 8.243 0 01.973.568a3.192 3.192 0 00-.048 2.95c.223.44.544.813.937 1.089A2.727 2.727 0 01.56 4.231v.037a3.12 3.12 0 00.656 1.907c.421.538 1.005.905 1.654 1.041-.25.08-.509.122-.77.125-.18-.002-.36-.02-.539-.051.185.599.542 1.122 1.023 1.498.48.375 1.06.583 1.658.595A5.574 5.574 0 01.7 10.68a5.27 5.27 0 01-.7-.044A7.806 7.806 0 004.403 12a7.756 7.756 0 003.145-.631 8.094 8.094 0 002.669-1.863A8.586 8.586 0 0011.993 6.7a8.94 8.94 0 00.607-3.31V3A6.094 6.094 0 0014 1.437z"
                fill="#000"
                className="fill"
            />
        </svg>
    );
}

export default SvgTwitter;
