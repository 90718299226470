import React, { Component } from "react";
import Checkout from "../../pages/RazorPay/Checkout";
import { Button } from "antd";
import { __getToken, __getEmail, __getUID, __getDP } from "../../utils/user-details.util";
import { check_login } from "../../utils/login.util";
import { G_HOME_URL, G_FC_API_URL, G_F360_URL } from "../../constants/constants";
import axios from "axios";
import { initiateTrial } from "../../utils/Premium/premium_login";

class Package extends Component {
    state = {
        MobileVerified:0,
        CheckoutModalOpen:false
    };

    // initiateTrial = (courseId) => {
    //     axios
    //         .post(G_FC_API_URL + "subscription/init/trial/", {
    //             course_id: courseId,
    //             email: __getEmail(),
    //             uid: __getUID(),
    //         })
    //         .then((res) => {
    //             const { status } = res.data;
    //             if (status) document.getElementsByClassName("f360Login")[0].submit();
    //         });
    // };

    render() {
        const {
            name,
            price,
            description,
            subscribedCourses,
            courseId,
            isPremiumUser,
            isTrialAvailable,
            courseExpired,
            mVerified
        } = this.props;
        const isLogIn = check_login();

        let try_it_handler = () => {
            this.props.handleTrailBtn(name);
            this.setState({MobileVerified:1})
        }
        let btnText = this.props.btnText;

        //If mobile verification is success and trail is available auto redirect to premium course
        if ((this.props.mVerified) && (this.state.MobileVerified === 1) && (isTrialAvailable)) {
            initiateTrial(courseId);
            btnText = "Try for free";
        }

        if ((this.props.mVerified) && (this.state.MobileVerified === 1) && (!isTrialAvailable) && (!this.state.CheckoutModalOpen)){
            this.setState({CheckoutModalOpen : true});
        }

        //Init pay_click_jsx with btn
        let pay_click_jsx = (
            <Button type="primary" onClick={try_it_handler}>
                Subscribe
            </Button>
        );

        //If mobile verification is success reassign pay_click_jsx  with checkout component
        if (mVerified) {
            pay_click_jsx = (
                <Checkout
                    courseID={parseInt(courseId)}
                    courseName={name}
                    coursePrice={price}
                    courseExpired = {courseExpired}
                    calledBy="subscription"
                    cookie={__getToken()}
                />
            );
        }

        let renderModule = () =>{
            if(isLogIn){
                if(subscribedCourses.includes(parseInt(courseId))){
                    return (
                        <>
                            <Button className="subscribed-btn" type="primary" disabled={true}>
                                Subscribed
                            </Button>
                        </>
                    )
                }
                else if((isPremiumUser) && (!isTrialAvailable)){
                    return (
                        <>
                            {this.state.CheckoutModalOpen ? 
                                <>
                                    <Checkout
                                        courseID={parseInt(courseId)}
                                        courseName={name}
                                        coursePrice={price}
                                        courseExpired = {courseExpired}
                                        calledBy="subscription"
                                        checkoutModalOpen = {this.state.CheckoutModalOpen}
                                        cookie={__getToken()}
                                    />
                                </>
                                : 
                                <>{pay_click_jsx}</>
                            }

                        </>
                    )
                }
                else{
                    return (
                        <>
                            <Button
                                className="try-it not-logged-in"
                                type="primary"
                                onClick={try_it_handler}
                            >
                                {btnText}
                            </Button>
                        </>
                    )
                }
            }
            else{
                return(
                    <>
                        <Button
                            className="try-it not-logged-in"
                            type="primary"
                            onClick={() => {
                                window.location.href = G_HOME_URL + "login/?rurl=premium";
                            }}
                        >
                            Try for free
                        </Button>
                    </>
                )
            }
        }
        
        return (
            <>
                <div className="th-col f-d f-vt g-v-c package-wrapper">
                    <span className="package-title">{name}</span>
                    <span className="f-d f-vt f-v-c package-inr-container">
                        <span className="package-inr">INR {price}</span>
                        <span className="package-access-type">{description}</span>
                    </span>
                    {renderModule()}

                    {/* <form
                        className="f360Login hide"
                        method="post"
                        action={G_F360_URL + "f360-auth/"}
                    >
                        <input name="email" value={__getEmail()} />
                        <input name="fp_token" value={__getToken()} />
                        <input name="fp_upic" value={__getDP()} />
                        <input name="target" value="/dashboard/student/face360/" />
                    </form> */}
                </div>

                <style jsx={"true"}>{`
                    .package-wrapper .subscribed-btn {
                        color: white;
                        opacity: 0.7;
                    }
                    .not-logged-in {
                        border: none;
                    }
                    .package-wrapper .package-title {
                        color: var(--batman);
                        font-weight: 450;
                        font-size: 18px;
                    }

                    .package-wrapper .package-inr-container {
                        margin: auto 0;
                    }

                    .package-wrapper .package-inr {
                        color: var(--facered);
                        font-weight: 450;
                        font-size: 18px;
                        line-height: 1;
                    }

                    .package-wrapper .package-access-type {
                        font-size: 10px;
                        text-align: center;
                    }

                    .package-wrapper .package-subscribe-btn {
                        border: none;
                        background-color: var(--facered);
                        border-radius: 4px;
                        color: var(--dove);
                        padding: 0.5rem 0;
                        width: 50%;
                        cursor: pointer;
                        font-size: 12px;
                    }

                    .package-wrapper .please-wait-btn {
                        opacity: 0.8;
                        padding: 0.4rem;
                    }

                    .package-wrapper .try-it {
                        padding: 0 25px;
                    }
                `}</style>
            </>
        );
    }
}

export default Package;



// {isLogIn ? (
//     subscribedCourses.includes(parseInt(courseId)) ? (
//         <Button className="subscribed-btn" type="primary" disabled={true}>
//             Subscribed
//         </Button>
//     ) : isPremiumUser && !isTrialAvailable ? (
//         {pay_click_jsx}
//     ) : (
//         <Button
//             className="try-it not-logged-in"
//             type="primary"
//             onClick={try_it_handler}
//         >
//             {btnText}
//         </Button>
//     )
// ) : (
//     <Button
//         className="try-it not-logged-in"
//         type="primary"
//         onClick={() => {
//             window.location.href = G_HOME_URL + "login/?rurl=premium";
//         }}
//     >
//         Try for free
//     </Button>
// )}