import React from "react";
import { Skeleton } from "antd";

const SkeltonCodeSquare = ({ width, height }) => {
    return (
        <Skeleton
            active
            avatar={{
                shape: "square",
                size: "large",
                style: { width: width, height: height },
            }}
            title={false}
            paragraph={{ rows: 0 }}
        />
    );
};

const SkeltonRow = ({ title, rows }) => {
    return <Skeleton title={title} paragraph={{ rows }} active></Skeleton>;
};

export { SkeltonCodeSquare, SkeltonRow };
