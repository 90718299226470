import { SET_SKILLBOARD_CID, SET_SKILLZONE, UPDATE_SKILLZONE } from "../constants/constants";
const initialState = {
    subscription: {
        status: 0,
        message: "INIT",
        validity: 0,
        validityState: false,
    },
    skillboard_cid: "1",
};

const skillZoneReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SKILLZONE:
            return { ...state, ...action.payload };
        case UPDATE_SKILLZONE:
            return { ...state, ...action.payload };
        case SET_SKILLBOARD_CID:
            return { ...state, skillboard_cid: action.payload };
        default:
            return state;
    }
};

export default skillZoneReducer;
