import React from "react";
import {__getEmail, __getToken} from "../../utils/user-details.util";
import external_link from "../../assets/icons/svg_icons/external.svg";
import {G_LiveClass_URL, G_PRO_URL} from "../../constants/constants";

const LiveClassroom = () => (
    <>
        <div
            className="liveclassroom-btn f-d f-v-c c-pointer"
            onClick={() => document.getElementsByClassName("placementLogin")[0].submit()}
        >
            Placement Zone
            <div
                className="external-icon bg-image-full"
                style={{ backgroundImage: "url(" + external_link + ")" }}
            ></div>
        </div>
        <form className="placementLogin hide" method="post" action={G_LiveClass_URL + "live-classroom-auth/"}>
            <input name="u_t" value={__getToken()}/>
        </form>
        <style jsx>
            {`
                .liveclassroom-btn {
                    background: var(--pekachu);
                    color: var(--dove);
                    padding: 4px 8px;
                    border-radius: var(--peaky-br-4);
                    margin-right: 2rem;
                }

                .liveclassroom-btn .external-icon {
                    width: 12px;
                    height: 12px;
                    margin-left: 0.4rem;
                }

                @media only screen and (max-device-width: 760px) {
                    .liveclassroom-btn{
                        margin-right: unset;
                        width: 35%;
                        margin: 1rem auto;
                    }

                    .liveclassroom-btn .external-icon {
                        width: 12px;
                        height: 12px;
                        margin-left: 0.6rem;
                    }
                }
            `}
        </style>
    </>
);

export default LiveClassroom;