import React, { Component, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";

// Image imports
import SolutionEye from "../../assets/icons/svg_icons/solution-eye.svg";

const QuestionContainer = styled.div`
    margin-bottom: 64px;

    .question-no {
        font-size: 14px;
        text-transform: uppercase;
        font-family: "OpenSans", sans-serif;
        font-weight: 300;
        opacity: 0.54;
    }

    .question-text {
        font-size: 20px;
        font-family: "OpenSans", sans-serif;
        color: var(--carbon);
        margin: 4px 0px 24px 0px;

        p,
        div {
            all: unset;
        }
    }

    .option-block {
        min-height: 64px;
        padding: 1rem;
        background: var(--dove);
        border-radius: var(--peaky-br-6);
        margin-bottom: 24px;
        color: var(--carbon);
        font-size: 18px;
        font-family: "OpenSans", sans-serif;
        box-shadow: 0px 6px 8px rgb(0 0 0 / 8%);
        border: var(--peaky-border);
        cursor: pointer;
        transition: all 0.4s;

        :hover {
            box-shadow: 0px 0px 0px rgb(0 0 0 / 0%);
        }
    }

    .option-block p {
        margin-bottom: unset;
    }

    .ans-correct {
        background-color: var(--go);
        color: var(--dove);
        p,
        div {
            color: var(--dove);
        }
    }

    .ans-incorrect {
        background-color: var(--facered);
        color: var(--dove);
        p,
        div {
            color: var(--dove);
        }
    }

    .solution-report-container {
        margin-top: 2rem;
        margin-bottom: 4rem;
    }

    .solution-report-container .solution-btn {
        font-size: 18px;
        font-family: "OpenSans", sans-serif;
        color: var(--carbon);
        transition: all 0.4s;
    }

    .solution-report-container .solution-btn:hover {
        color: var(--facered);
    }

    .solution-btn .eye-icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }

    .solution-btn .icon {
        margin-left: 6px;
        padding-top: 2px;
        transition: all 0.4s;
    }

    .solution-btn.active .icon {
        transform: rotate(180deg);
    }

    .solution-report-container .solution-block {
        background-color: var(--dove);
        padding: 32px;
        border-radius: 6px;
        margin-top: 24px;
        box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.08);
        border: var(--peaky-border);
    }

    .explanation,
    .explanation > p,
    .explanation > div {
        font-size: 16px;
        margin: 0;
    }
`;

const OptionBlock = ({ value, solution, idx, ...rest }) => {
    const [isCorrect, setisCorrect] = useState(null);

    const handleOnClick = (ans) => {
        if (ans === solution) setisCorrect(true);
        else setisCorrect(false);

        if (rest && rest.handleAnswerStatus) {
            rest.handleAnswerStatus();
        }
    };

    let className = "";
    if (isCorrect === true) className = "ans-correct";
    if (isCorrect === false) className = "ans-incorrect";

    if (rest && rest.answer_status === 1) {
        if (idx === solution) className = "ans-correct";
    }

    return (
        <div className={`option-block ${className}`} onClick={() => handleOnClick(idx)}>
            {ReactHtmlParser(value)}
        </div>
    );
};

class PracticeQuestion extends Component {
    state = {
        options: [],
        question_id: null,
        solution: [],
        explanation_text: null,
        question_no: null,
        options_jsx: <></>,
        answer_status: 0,
        showExplaination: false,
    };

    componentDidMount = () => {
        const { explanation_text, options, question_id, solution, question_no, question_text } = this.props;

        this.setState({
            explanation_text,
            options,
            question_id,
            solution,
            question_no,
            question_text,
        });
    };

    handleAnswerStatus = () => {
        this.setState({ answer_status: 1 });
    };

    handleshowExplaination = () => {
        this.setState({ showExplaination: !this.state.showExplaination });
    };

    render() {
        const {
            question_no,
            question_text,
            options,
            answer_status,
            solution,
            explanation_text,
            showExplaination,
        } = this.state;

        return (
            <>
                <QuestionContainer className="practice-question-block">
                    <div className="question-no">Question {question_no}</div>
                    <div className="question-text">{ReactHtmlParser(question_text)}</div>
                    <div className="options-group">
                        {options.map((value, idx) => {
                            return (
                                <OptionBlock
                                    value={value}
                                    key={idx}
                                    solution={solution[0]}
                                    idx={idx}
                                    handleAnswerStatus={this.handleAnswerStatus}
                                    answer_status={answer_status}
                                />
                            );
                        })}
                    </div>

                    {explanation_text && explanation_text.length > 0 && (
                        <div className={`solution-report-container ${answer_status === 0 ? "hide" : ""} `}>
                            <div
                                className={`solution-btn ${showExplaination ? "active" : ""} f-d f-v-c c-pointer`}
                                onClick={this.handleshowExplaination}
                            >
                                <div
                                    className={`eye-icon bg-image-full`}
                                    style={{ backgroundImage: `url(${SolutionEye})` }}
                                ></div>
                                See Explanation
                                <i className="icon icon-chevron-down"></i>
                            </div>
                            {showExplaination ? (
                                <div className="solution-block">
                                    <div className="explanation">{ReactHtmlParser(explanation_text)}</div>
                                </div>
                            ) : null}
                        </div>
                    )}
                </QuestionContainer>
                <style jsx={"true"}>{``}</style>
            </>
        );
    }
}

export default PracticeQuestion;
