import axios from "axios";

const getProgramContent = async (fetch_url, landing_slug) => {
    try {
        let program_data = await axios.get(fetch_url, {
            params: {
                landing_slug,
            },
        });

        if (
            program_data !== undefined &&
            program_data.data !== undefined &&
            program_data.data.program !== undefined
        )
            return program_data.data.program[0];
        else return -1;
    } catch (error) {
        console.warn("FROM DEV :: Program Content API Failed");
    }
};

const getOrderStatus = async (api, uid, courseIds) => {
    try {
        const data = await axios.post(api, { uid, courseIds });
        return data;
    } catch (error) {
        console.warn("FROM DEV :: ORDER STATUS API FAILED");
    }
};

export { getProgramContent, getOrderStatus };
