import * as React from "react";
const SvgTest = (props) => (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M14 6.667V6l-4-4H3.333C2.593 2 2 2.593 2 3.333v9.334A1.333 1.333 0 0 0 3.333 14h4v-1.247l5.594-5.593A1.728 1.728 0 0 1 14 6.667ZM9.333 3 13 6.667H9.333V3Zm5.9 6.46-.653.653-1.36-1.36.653-.653a.338.338 0 0 1 .48 0l.88.88a.338.338 0 0 1 0 .48Zm-2.48-.24 1.36 1.36-4.086 4.087h-1.36v-1.36l4.086-4.087Z"
            fill={props.color ? props.color : "#8C8C8C"}
        />
    </svg>
);
export default SvgTest;
