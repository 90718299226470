import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { G_API_URL } from "../../../constants/constants";
import { __getToken } from "../../../utils/user-details.util";
import CoursesCard from "../../Cards/CoursesCard";

const StyledFavorites = styled.div`
    .favorites-container {
        margin-top: 32px;
        row-gap: 32px;
    }

    @media only screen and (max-device-width: 768px) {
        .favorites-container {
            grid-template-columns: unset;
            place-items: center;
        }
    }
`;

const Favorites = () => {
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        axios.get(`${G_API_URL}facepro/favorite/`, { headers: { Authorization: __getToken() } }).then((res) => {
            if (res.data && res.data.courses) {
                setCourses(res.data.courses);
            }
        });
    }, []);

    return (
        <StyledFavorites>
            <div className="header">
                <h2 className="h2-heading">Favourites</h2>
            </div>
            <div className="favorites-container g-d g-col-3">
                {courses.map((course) => (
                    <CoursesCard {...course} />
                ))}
            </div>
        </StyledFavorites>
    );
};

export default Favorites;
